import { FormWidgetError } from '~/common/form/error'
import { Validations } from '~/common/form/validation'

export interface AreaType {
  postal_code: string
}

export interface AreaTypeError {
  postal_code: FormWidgetError[]
}

export const areaValidation: Validations = {
  postal_code: []
}
