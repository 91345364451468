import { useLoggedIn } from '~/common/api/user'
import { useShowModal } from '~/common/app/store/modal'
import {
  areaTopPath,
  entryPath,
  helpAboutPath,
  myPageLoginPath,
  productListPath,
  usePathKey
} from '~/common/app/path'
import { useShop } from '~/common/api/shop'
import { Color } from '~/common/utils/color'
import AreaPcSide from '~/components/layouts/area/AreaPcSide'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import Text from '~/components/atoms/text/Text'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import { IconType } from '~/components/atoms/icon/Icon'
import MarginTop from '~/components/utils/margin/MarginTop'
import FirstTimeInform from '~/components/molecules/detail/FirstTimeInform'
import IconText from '~/components/atoms/icon/IconText'
import HiddenSp from '~/components/utils/hidden/HiddenSp'
import UserPointCampaign from '~/components/app/module/user/UserPointCampaign'
import { Modal } from '~/components/app/modal/AppModal'
import ShopList from '~/components/app/module/shop/ShopList'
import TitleList from '~/components/layouts/title/TitleList'
import LinkText from '~/components/atoms/text/LinkText'
import PcSideBanner from '~/components/atoms/image/PcSideBanner'
import CategoryNavList from '~/components/app/module/category/CategoryNavList'

const Introduction = () => {
  const showModal = useShowModal()
  return (
    <>
      <BlockTitle verticalBar>ご利用エリアを設定してください</BlockTitle>
      <Segments pcBetween pcGap={8}>
        <Text px={12}>
          郵便番号を設
          <br />
          定する
        </Text>
        <Button
          size={ButtonSize.LARGE}
          role={ButtonRole.HIGH_PRIORITY_YELLOW}
          iconType={IconType.ADDRESS}
          iconSize={14}
          px={14}
          onClick={() => showModal({ type: Modal.AREA_SETTING })}
          flex
        >
          エリア設定
        </Button>
      </Segments>
      <MarginTop marginPc={12}>
        <Segments pcBetween pcGap={8}>
          <Text px={12}>
            すでに会員の
            <br />
            方はこちら
          </Text>
          <Button
            size={ButtonSize.LARGE}
            role={ButtonRole.HIGH_PRIORITY_DEEP_GREEN}
            iconType={IconType.KEY}
            iconSize={14}
            px={14}
            onClick={() => showModal({ type: Modal.LOGIN })}
            flex
          >
            ログイン
          </Button>
        </Segments>
      </MarginTop>
      <MarginTop marginPc={12}>
        <FirstTimeInform
          title={'マルクトとは？'}
          textLink={helpAboutPath()}
          items={[
            <>
              <IconText
                iconColor={Color.SUPPORT}
                iconSize={10}
                textPx={12}
                iconType={IconType.CHECK}
              >
                近所のお店の商品をまとめて注文！
              </IconText>
            </>,
            <>
              <IconText
                iconColor={Color.SUPPORT}
                iconSize={10}
                textPx={12}
                iconType={IconType.CHECK}
              >
                13時までの注文で当日お届け！
              </IconText>
            </>,
            <>
              <IconText
                iconColor={Color.SUPPORT}
                iconSize={10}
                textPx={12}
                iconType={IconType.CHECK}
              >
                4000円以上で送料無料！
                <br />
                <Text px={12} color={Color.THIRD} bold>
                  初回は完全送料無料！
                </Text>
              </IconText>
            </>
          ]}
          buttonLink={entryPath()}
          buttonText={'新規会員登録'}
          iconType={IconType.ENTRY}
        />
      </MarginTop>
    </>
  )
}

const AppPcNavigation = () => {
  const loggedIn = useLoggedIn()
  const shop = useShop()
  const key = usePathKey()
  return (
    <HiddenSp>
      <AreaPcSide>
        {loggedIn ?
          <UserPointCampaign /> :
          <>
            <Introduction />
            <MarginTop marginPc={24}>
              <UserPointCampaign />
            </MarginTop>
          </>
        }
        <MarginTop marginPc={20}>
          <BlockTitle icon={IconType.SHOP} iconColor={Color.SUPPORT_SUB}>
            お店から探す
          </BlockTitle>
          <Segments pcGap={4} pcColumn={SegmentsType.COLUMN}>
            <Button
              size={ButtonSize.LARGE}
              role={shop ? ButtonRole.HIGH_PRIORITY_DEEP_GREEN : ButtonRole.DEACTIVE}
              link={shop ? areaTopPath(shop?.area) : undefined}
              px={12}
              expanded
              shadow
              disabled={!shop}
            >
              全店の商品を見る
            </Button>
            <ShopList vertical />
          </Segments>
        </MarginTop>
        <MarginTop marginPc={24}>
          <TitleList>
            <BlockTitle icon={IconType.CATEGORY} iconColor={Color.SUPPORT_SUB}>
              カテゴリから探す
            </BlockTitle>
            <LinkText link={productListPath({key: key})} arrow={Color.LINK} px={12}>
              すべて見る
            </LinkText>
          </TitleList>
          <CategoryNavList showChild />
        </MarginTop>
        <MarginTop marginPc={24}>
          <Segments pcGap={8} pcColumn={SegmentsType.COLUMN} pcAlignCenter>
            <PcSideBanner
              src={'/assets/images/hamburger-banner1.png'}
              alt={'送料無料条件'}
              link={helpAboutPath()}
            />
            <PcSideBanner
              src={'/assets/images/hamburger-banner2.png'}
              alt={'貯まる！使える！ポイント'}
              link={helpAboutPath()}
            />
            <PcSideBanner
              src={'/assets/images/hamburger-banner3.png'}
              alt={'加盟店募集'}
              link={'https://www.lufi.co.jp/markt/#markt01'}
              external
            />
          </Segments>
        </MarginTop>
      </AreaPcSide>
    </HiddenSp>
  )
}

export default AppPcNavigation
