import React, { ReactNode, useState } from 'react'

import { convertToPrice } from '~/common/utils/price'
import { Color } from '~/common/utils/color'
import {
  CartProductCardProps,
  PcCartProductCard
} from '~/components/molecules/card/CartProductCard'
import Text from '~/components/atoms/text/Text'
import CartIcon from '~/components/molecules/icon/CartIcon'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import CardList from '~/components/molecules/card/CardList'
import HeaderList from '~/components/layouts/header/HeaderList'
import OpacityToggle from '~/components/utils/animation/OpacityToggle'
import FloatCover from '~/components/layouts/float/FloatCover'
import FloatBottom from '~/components/layouts/float/FloatBottom'
import CouponIcon from '~/components/molecules/icon/CouponIcon'
import Section from '~/components/layouts/section/Section'

interface Props {
  total: number
  cartTotalQuantity: number
  message?: ReactNode
  cartLink: string
  modeLabel: string
  items: CartProductCardProps[]
  onDelete(productClassId: number): void
  couponCount: number
}

const CartHeader = ({
  total,
  cartTotalQuantity,
  cartLink,
  message,
  modeLabel,
  items,
  onDelete,
  couponCount
}: Props) => {
  const [showCartInfo, setShowCartInfo] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [deletableProductClassId, setDeletableProductClassId] = useState<number>()

  const openCartInfo = () => {
    setShowCartInfo(true)
  }
  const closeCartInfo = () => {
    setShowCartInfo(false)
  }
  const clickDeleteProduct = (productClassId: number) => {
    setDeletableProductClassId(productClassId)
    setShowDelete(true)
  }
  const clickDeleteProductMain = () => {
    if (deletableProductClassId) {
      onDelete(deletableProductClassId)
    }
    clickCloseDelete()
  }
  const clickCloseDelete = () => {
    setShowDelete(false)
  }

  return (
    <div className='p-cart-pc__wrap'>
      <HeaderList pcGap={16}>
        {couponCount > 0 && (
          <CouponIcon quantity={couponCount}></CouponIcon>
        )}
        <HeaderList pcGap={8}　onMouseEnter={openCartInfo} onMouseLeave={closeCartInfo}>
          <CartIcon quantity={cartTotalQuantity}></CartIcon>
          <div>
            <Text px={12} color={Color.PRIMARY}>
              ¥
            </Text>
            <Text px={24} color={Color.ASSERTION} bold en>
              {convertToPrice(total)}
            </Text>
            <Text px={12} color={Color.ASSERTION}>
              (税込)
            </Text>
          </div>
          <div className={'p-cart-pc__message'}>
            {message}
          </div>
          <Button
            size={ButtonSize.WIDE_MEDIUM}
            px={14}
            role={ButtonRole.HIGH_PRIORITY_YELLOW}
            link={cartLink}
            iconType={IconType.REGISTER}
          >
            レジに進む
          </Button>
        </HeaderList>
      </HeaderList>
      <OpacityToggle show={showCartInfo}>
        <div className='p-cart-pc' onMouseEnter={openCartInfo} onMouseLeave={closeCartInfo}>
          {cartTotalQuantity == 0 ? (
            <div className='p-cart-pc__no-item'>
              <div className='p-cart-pc__no-item__content'>
                <div className='p-cart-pc__no-item__in'>
                  <Icon type={IconType.CART} size={38} color={Color.LINE} />
                  <div className='p-cart-pc__no-item__text'>
                    現在カート内に
                    <br />
                    商品はございません。
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className='p-cart-pc__top'>
                <div className='p-cart-pc__in'>
                  <p className='p-cart-pc__left'>
                    カートの商品
                    <br />
                    {`（${modeLabel}）`}
                  </p>
                  <div className='p-cart-pc__right'>
                    <div className='p-cart-pc__right__price'>
                      <div className='p-cart-pc__right__price__quantity'>
                        <CartIcon quantity={cartTotalQuantity} />
                      </div>
                      <div>
                        <Text px={10} color={Color.PRIMARY} bold>
                          ¥
                        </Text>
                        <Text px={24} color={Color.EMPHASIS} bold en>
                          {convertToPrice(total)}
                        </Text>
                        <Text px={10}>(税込)</Text>
                      </div>
                    </div>
                  </div>
                </div>
                {message != undefined &&
                  <div className='p-cart-pc__right__text'>
                    {message}
                  </div>
                }
              </div>
              <div className='p-cart-pc__items'>
                <CardList vertical noScroll>
                  {items.map((item: CartProductCardProps, index: number) => (
                    <PcCartProductCard key={index} {...item} onClickDelete={clickDeleteProduct} />
                  ))}
                </CardList>
              </div>
              <FloatBottom>
                <Button
                  size={ButtonSize.LARGE}
                  role={ButtonRole.HIGH_PRIORITY_YELLOW}
                  iconType={IconType.REGISTER}
                  link={cartLink}
                  expanded
                >
                  レジに進む
                </Button>
              </FloatBottom>
              <OpacityToggle show={showDelete}>
                <FloatCover>
                  <div className='p-cart-product-delete'>
                    <Text px={16} tag={'p'} bold>
                      この商品を削除しますか？
                    </Text>
                    <div className='p-cart-product-delete__wrap'>
                      <div className='p-cart-product-delete__button'>
                        <Button
                          size={ButtonSize.WIDE_SMALL}
                          role={ButtonRole.HIGH_PRIORITY_YELLOW}
                          onClick={clickDeleteProductMain}
                          expanded
                        >
                          削除する
                        </Button>
                      </div>
                      <div className='p-cart-product-delete__button'>
                        <Button
                          size={ButtonSize.WIDE_SMALL}
                          role={ButtonRole.LOW_PRIORITY}
                          onClick={clickCloseDelete}
                          expanded
                        >
                          削除しない
                        </Button>
                      </div>
                    </div>
                  </div>
                </FloatCover>
              </OpacityToggle>
            </>
          )}
        </div>
      </OpacityToggle>
    </div>
  )
}
export default CartHeader
