import css from 'styled-jsx/css'
import { addClass } from '~/common/utils/html'
import InternalLink from '~/components/utils/link/InternalLink'
import ImageFitContain from '~/components/utils/image/ImageFitContain'
import ImageFitCover from '~/components/utils/image/ImageFitCover'

interface Props {
  logoSrc: string
  shopSrc: string
  shopAlt: string
  logoAlt: string
  link: string
  color: string
  itemText: string
}

const ShopIntroductionCard = ({
  logoSrc,
  shopSrc,
  shopAlt,
  logoAlt,
  link,
  color,
  itemText
}: Props) => {
  const { className, styles } = css.resolve`
    background: ${color};
  `
  return (
    <>
      <li className='p-shop-introduction-card'>
        <InternalLink
          link={link}
          className={'p-shop-introduction-card__link' + addClass(className)}
        >
          <div className='p-shop-introduction-card__image-wrap'>
            <ImageFitCover src={shopSrc} alt={shopAlt} />
            <div className='p-shop-introduction-card__image-wrap__logo'>
              <ImageFitContain src={logoSrc} alt={logoAlt} />
            </div>
          </div>
          <p className='p-shop-introduction-card__type'>
            <span className='p-shop-introduction-card__type__text'>
              {itemText}
              <span className='p-shop-introduction-card__type__arrow'></span>
            </span>
          </p>
        </InternalLink>
        {styles}
      </li>
    </>
  )
}

export default ShopIntroductionCard
