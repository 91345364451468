import { FormWidgetError } from '~/common/form/error'
import { Validations } from '~/common/form/validation'

export interface PostalCodeType {
  postal_code: string
}

export interface PostalCodeTypeError {
  postal_code: FormWidgetError[]
}

export const initialData: PostalCodeType = {
  postal_code: ''
}

export const postalCodeValidation: Validations = {
  postal_code: [FormWidgetError.IS_EMPTY, FormWidgetError.NOT_POSTAL_CODE]
}
