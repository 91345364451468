import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'

export const IconType = {
  STAR: 'star',
  CART: 'cart',
  KEY: 'key',
  SHOP: 'shop',
  TIMER: 'timer',
  CATEGORY: 'category',
  CLOCK: 'clock',
  RECEIPT: 'receipt',
  RANKING: 'ranking',
  MEGAPHONE: 'megaphone',
  ADDRESS: 'address',
  CARD: 'card',
  HELP: 'help',
  POINT: 'point',
  ALERT: 'alert',
  MAIL: 'mail',
  USER: 'user',
  REGISTER: 'register',
  ENTRY: 'entry',
  BAG: 'bag',
  REVIEW: 'review',
  PIN: 'pin',
  DETAIL: 'detail',
  MENU: 'menu',
  CLOSE: 'close',
  BACK: 'back',
  SEARCH: 'search',
  ADD: 'add',
  ARROW: 'arrow',
  TOKUBAI: 'tokubai',
  SEASONAL: 'seasonal',
  NEW: 'new',
  CLOSE_MODAL: 'close_modal',
  CHECK: 'check',
  SORT: 'sort',
  COUPON: 'coupon'
} as const
export type IconType = (typeof IconType)[keyof typeof IconType]

export const IconInversion = {
  HORIZON: 'HORIZON'
} as const
export type IconInversion = (typeof IconInversion)[keyof typeof IconInversion]

export const IconRotate = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT'
} as const
export type IconRotate = (typeof IconRotate)[keyof typeof IconRotate]

export interface IconProps {
  color: Color | string
  size: number
  type: IconType
  inversion?: IconInversion
  rotate?: IconRotate
}

const Icon = ({ color, size, type, inversion, rotate }: IconProps) => {
  return (
    <>
      <svg
        className={
          'c-icon' +
          addClass(`c-icon-${type}`) +
          addClass('c-icon--inversion-horizon', inversion == IconInversion.HORIZON) +
          addClass('c-icon--rotate-right', rotate == IconRotate.RIGHT) +
          addClass('c-icon--rotate-left', rotate == IconRotate.LEFT)
        }
      >
        <use xlinkHref={`/icons.svg#${type}`}></use>
      </svg>
      <style jsx>{`
        svg {
          display: inline-block;
          width: ${size}px;
          height: ${size}px;
          min-width: ${size}px;
          min-height: ${size}px;
          fill: ${color};
        }
      `}</style>
    </>
  )
}
export default Icon
