import { ReactNode } from 'react'

export enum RequestError {
  SUCCESS = 200,
  UNAUTHORIZED = 401,
  VALIDATION_FAILED = 422,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 505,
  CART_EXIST = 580,
  HAS_DIFFERENT_AREA_CART = 581,
  HAS_ANOTHER_SHOP_PICKUP_CART = 582,
  HAS_ANOTHER_USER_AREA = 583,
  ALREADY_VERIFIED = 1001,
  EXPIRED = 1002,
}

export const errorMainTitle = (error: RequestError): string | ReactNode => {
  switch (Number(error)) {
    case RequestError.UNAUTHORIZED:
      return '認証エラーです'
    case RequestError.VALIDATION_FAILED:
      return '入力項目をご確認ください'
    case RequestError.NOT_FOUND:
      return <>お探しのページが見つかりません。<br />URLに間違いがないかご確認ください。</>
    case RequestError.INTERNAL_SERVER_ERROR:
      return 'エラーが発生しました'
    case RequestError.ALREADY_VERIFIED:
      return '認証済みです'
    case RequestError.EXPIRED:
      return '有効期限が切れています'
    default:
      return 'エラーが発生しました'
  }
}

export const errorMessage = (error: RequestError): string => {
  switch (Number(error)) {
    case RequestError.UNAUTHORIZED:
      return '認証エラーです。アクセス権限があるか確認してください。'
    case RequestError.VALIDATION_FAILED:
      return '入力項目をご確認ください'
    case RequestError.NOT_FOUND:
      return 'お探しのページは、一時的にアクセス出来ないか、削除された可能性があります。\n' + 'アドレスをご確認ください。'
    case RequestError.INTERNAL_SERVER_ERROR:
      return '何か意図しないエラーが起きたようです。お手数おかけしますが、時間をおいてアクセスしてください。'
    case RequestError.ALREADY_VERIFIED:
      return '既に認証済みです。\n引き続き、VISLITEをよろしくお願いします。'
    case RequestError.EXPIRED:
      return '有効期限切れの申請情報です。再度申請するようお願いいたします。'
    default:
      return '何か意図しないエラーが起きたようです。お手数おかけしますが、時間をおいてアクセスしてください。'
  }
}

export const isRequestError = (error: unknown) => {
  let isMatch = false
  Object.entries(RequestError).forEach(([key, value]) => {
    if (value == error) {
      isMatch = true
    }
  })

  return isMatch
}
