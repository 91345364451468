import React from 'react'
import FormTextBoxCommon, { FormTextBoxProps } from '~/components/app/form/widgets/FormTextBoxCommon'

const FormPassword = (props: FormTextBoxProps) => {
  return (
    <FormTextBoxCommon
      {
        ...{
          type: 'password',
          placeholder: 'パスワード',
          ...props
        }
      }
    />
  )
}

export default FormPassword