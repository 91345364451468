import LinkText from '~/components/atoms/text/LinkText'
import { Color } from '~/common/utils/color'

interface LinkItem {
  link: string
  text: string
  external: boolean
  noInternal?: boolean
}

interface LinkItemSet {
  linkItems: LinkItem[]
}

interface Props {
  linkItemSets: LinkItemSet[]
}

const LinkList = ({ linkItemSets }: Props) => {
  return (
    <>
      {linkItemSets.map((set: LinkItemSet, index) => (
        <ul className={'p-link-list'} key={index}>
          {set.linkItems.map((item: { link: string; text: string; external: boolean; noInternal?: boolean }, index) => (
            <li className={'p-link-list__item'} key={index}>
              <LinkText link={item.link} external={item.external} color={Color.PRIMARY} listArrow noInternal={item.noInternal}>
                {item.text}
              </LinkText>
            </li>
          ))}
        </ul>
      ))}
    </>
  )
}

export default LinkList
