import React, { ReactNode, useState } from 'react'
import { useRouter } from 'next/router'

import Cart, { CartItem } from '~/model/cart'
import Coupon from '~/model/coupon'

import { Operation, useCart, useCartBulkAddApi, useCartOperationApi } from '~/common/api/cart'
import { useAreaList } from '~/common/api/area'
import {
  Mode,
  useBasePointRate,
  useLoggedIn,
  useUserApi,
  useUserArea,
  useUserCustomer,
  useUserMode
} from '~/common/api/user'
import { useShowModal } from '~/common/app/store/modal'
import { useWriteNotice } from '~/common/app/store/notice'
import { cartPath, savedImagePath } from '~/common/app/path'
import { currentPrice, currentPriceIncTax } from '~/common/app/product'
import { convertToPrice } from '~/common/utils/price'
import { Color } from '~/common/utils/color'
import { useCouponListApi } from '~/common/api/coupon'
import { extractCouponTagInfos, attachPointAndCouponToCartItem } from '~/common/app/coupon'
import { Modal } from '~/components/app/modal/AppModal'
import { CartProductCardProps } from '~/components/molecules/card/CartProductCard'
import Text from '~/components/atoms/text/Text'

export const useCurrentArea = () => {
  const userArea = useUserArea()
  return useAreaList().find((item) => {
    return item.area_key == userArea?.area_key
  })
}

export const useBuyable = (): boolean | undefined => {
  const area = useCurrentArea()
  const cart = useCart()

  return (area == undefined || cart == undefined) ? undefined :
    Number(area.buyable_total_price) <= Number(cart.total_price)
}

export const useBuyableTotal = (): number | undefined => {
  const area = useCurrentArea()

  return area?.buyable_total_price
}

export const useCartTotalQuantity = (): number => {
  const cart = useCart()
  return (
    cart?.cartItems.reduce((a, x) => {
      return a + Number(x.quantity)
    }, 0) ?? 0
  )
}

export const useCartSubTotal = (): number => {
  const cart = useCart()
  return (
    cart?.cartItems.reduce((a, x) => {
      return a + Number(x.price * x.quantity)
    }, 0) ?? 0
  )
}

export const useCartTax = (): number => {
  const cart = useCart()
  const subTotalExTax =
    cart?.cartItems.reduce((a, x) => {
      return a + Number(currentPrice(x.productBaseInfo) * x.quantity)
    }, 0) ?? 0

  return useCartSubTotal() - subTotalExTax
}

export const useProductCartQuantity = (productClassId: number | undefined): number => {
  const cart = useCart()
  return (
    cart?.cartItems.find((cartItem) => cartItem.product_class_id == productClassId)?.quantity ?? 0
  )
}

export const useCartOperation = (callback?: () => void): ((
  productClassId: number,
  afterQuantity: number,
  currentQuantity?: number
) => void) => {
  const { pathname } = useRouter()
  const { data: user } = useUserApi()
  const showModal = useShowModal()
  const cartOperationApi = useCartOperationApi(pathname == cartPath())

  return (productClassId: number, afterQuantity: number, currentQuantity?: number) => {
    if (!user || isNaN(afterQuantity)) {
      return
    }
    if (!user.postal_code) {
      showModal({
        type: Modal.AREA_SETTING
      })
      return
    }

    if (afterQuantity == 0) {
      cartOperationApi({ operation: Operation.DELETE, productClassId: productClassId })
    } else {
      const diff = afterQuantity - (currentQuantity ?? 0)

      if (diff > 0) {
        cartOperationApi({
          operation: Operation.ADD,
          productClassId: productClassId,
          quantity: Math.abs(diff),
          callback
        })
      } else if (diff < 0) {
        cartOperationApi({
          operation: Operation.REDUCE,
          productClassId: productClassId,
          quantity: Math.abs(diff)
        })
      }
    }
  }
}

export const useConfirmMedicineCartIn = (message?: string) => {
  const showModal = useShowModal()

  return (cartIn: () => void) => {
    !!message ? showModal({
      type: Modal.CONFIRM,
      childProps: {
        title: '以下の注意事項をあらかじめご了承のうえご注文ください。',
        message: message,
        onClickYes: cartIn,
        forAgree: true
      }
    }) : cartIn()
  }
}

export const useConfirmAbuseAbilityCartIn = (message?: string) => {
  const showModal = useShowModal()

  return (cartIn: () => void) => {
    showModal({
      type: Modal.MEDICINE,
      childProps: {
        cartIn: cartIn,
      }
    })
  }
}

export const useCartInBulk = () => {
  const writeNotice = useWriteNotice()
  const cartBulkAddApi = useCartBulkAddApi()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const onClickSelect = (productClassId: number, selected: boolean) => {
    let productClassIds = [...selectedIds]
    const hasId =
      productClassIds.find((id) => {
        return id == productClassId
      }) != undefined

    if (selected == true && !hasId) {
      productClassIds.push(productClassId)
    } else if (selected == false && hasId) {
      productClassIds.splice(productClassIds.indexOf(productClassId), 1)
    }

    setSelectedIds(productClassIds)
  }

  const onClickCartIn = () => {
    if (selectedIds.length == 0) {
      writeNotice('一つ以上の商品を指定してください。')
    } else {
      cartBulkAddApi(selectedIds)
    }
  }

  return { onClickCartIn, onClickSelect }
}

export const useMakeCartProductCardPropsList = (cart?: Cart): CartProductCardProps[] => {
  const cartOperation = useCartOperation()

  return cart ? cart.cartItems.map((item) => {
    return {
      productClassId: item.product_class_id,
      image: savedImagePath(item.productBaseInfo.product_image),
      name: item.productBaseInfo.name,
      price: currentPrice(item.productBaseInfo),
      tax: currentPriceIncTax(item.productBaseInfo),
      shopName: item.productBaseInfo.shop.name,
      cartQuantity: item.quantity,
      onClickChangeQuantity: (quantity) => {
        cartOperation(item.product_class_id, quantity, item.quantity)
      }
    }
  }) : []
}

export const useCartDeliveryFeeDiff = (cart: Cart | undefined): number | undefined => {
  const customer = useUserCustomer()
  const mode = useUserMode()
  const area = useCurrentArea()
  const cartTotalPrice = cart?.total_price

  const deliveryFee = cart ? cart.area_delivery_fee : area?.delivery_fee
  const deliveryFreeAmount = cart ? cart.delivery_free_amount : area?.delivery_free_amount

  return (!customer || customer.is_first_use || mode == Mode.PICKUP || deliveryFee == 0 ||
    deliveryFreeAmount == undefined || deliveryFreeAmount == 0 || cartTotalPrice == undefined) ? undefined :
    deliveryFreeAmount - cartTotalPrice > 0 ? deliveryFreeAmount - cartTotalPrice : 0
}

export const useDeliveryFeeDiff = (): number | undefined => {
  const cart = useCart()
  return useCartDeliveryFeeDiff(cart)
}

export const useCartMessage = (): ReactNode | undefined => {
  const loggedIn = useLoggedIn()
  const user = useUserCustomer()
  const mode = useUserMode()
  const area = useCurrentArea()
  const cart = useCart()
  const buyable = useBuyable()
  const buyableTotal = useBuyableTotal()
  const deliveryFreeDiff = useDeliveryFeeDiff()

  const deliveryFee = cart ? cart.area_delivery_fee : area?.delivery_fee
  const deliveryFreeAmount = cart ? cart.delivery_free_amount : area?.delivery_free_amount

  return (!buyable && buyableTotal) ?
    <Text color={Color.SECONDARY} px={10} tag={'p'} bold>
      最低注文金額{convertToPrice(buyableTotal)}円に達していません。
    </Text> :
    (!loggedIn || user?.is_first_use) ?
      <>
        <Text color={Color.SECONDARY} px={10} bold>初回利用は</Text>
        <Text color={Color.EMPHASIS} px={10} bold>送料無料！</Text>
      </> :
      (mode == Mode.PICKUP || deliveryFreeAmount == 0 || deliveryFee == 0) ?
        <Text color={Color.EMPHASIS} px={10} bold>送料無料！</Text> :
        (deliveryFreeDiff != undefined && deliveryFreeDiff > 0) ?
          <>
            <Text color={Color.EMPHASIS} px={10} bold>
              送料無料
            </Text>
            <Text color={Color.SECONDARY} px={10} bold>
              まであと
            </Text>
            <Text color={Color.SECONDARY} px={10} bold>
              ¥{convertToPrice(deliveryFreeDiff)}
            </Text>
          </> :
          <>
            <Text color={Color.EMPHASIS} px={10} bold>
              送料
            </Text>
            <Text color={Color.SECONDARY} px={10} bold>
              が
            </Text>
            <Text color={Color.EMPHASIS} px={10} bold>
              無料
            </Text>
            <Text color={Color.SECONDARY} px={10} bold>
              になりました！
            </Text>
          </>
}

export const useCartWithCoupon = (cart: Cart): { cart: Cart; coupons: Coupon[] | undefined; formatTotalAddPoint: string; } => {

  const { data: couponsData } = useCouponListApi(false, true)
  const couponTagInfos = extractCouponTagInfos(couponsData)
  const baseRate = useBasePointRate()

  const updatedCartItems: CartItem[] = []
  let maxAddPoint = 0
  let hasPointCouponInCart = false

  cart?.cartItems?.forEach((cartItem) => {
    const { cartItem: updatedCartItem, maxPoint, hasPointCoupon } = attachPointAndCouponToCartItem(cartItem, baseRate, couponTagInfos)
    updatedCartItems.push(updatedCartItem)
    maxAddPoint += maxPoint
    if (hasPointCoupon) {
      hasPointCouponInCart = true
    }
  })

  if (cart) {
    cart.cartItems = updatedCartItems
  }

  const formatTotalAddPoint = hasPointCouponInCart ?  '最大' + maxAddPoint.toString() : maxAddPoint.toString()

  return { cart, coupons: couponsData?.coupons, formatTotalAddPoint }
}