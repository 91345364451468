import { ChildProps } from '~/common/utils/props'
import { addClass } from '~/common/utils/html'

interface Props extends ChildProps {
  scroll?: boolean
  low?: boolean
  center?: boolean
  twoColumn?: boolean
}

const Content = ({
  children,
  scroll = false,
  low = false,
  center = false,
  twoColumn = false
}: Props) => {
  return (
    <div
      className={'l-content' +
        addClass(scroll ? 'l-content--scroll' : 'l-content--normal') +
        addClass('l-content--low', low) +
        addClass('l-content--center', center) +
        addClass('l-content--two-column', twoColumn)}>
      {children}
    </div>
  )
}

export default Content
