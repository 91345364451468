import { ChildProps } from '~/common/utils/props'

const HiddenSp = ({ children }: ChildProps) => {
  return (
    <div className={'u-hidden-sp'}>
      {children}
    </div>
  )
}

export default HiddenSp
