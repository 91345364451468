import { useShop, useShopList } from '~/common/api/shop'
import { WidgetChoice } from '~/common/form/choice'
import { useIsAreaPage } from '~/common/api/area'

export const useOpinionChoices = () => {
  let choices: WidgetChoice<string | number>[] = [
    { name: '選択してください', value: '' },
    { name: 'マルクトカスタマーサポート', value: 1 }
  ]
  const shop = useShop()
  if (shop) {
    choices.push({ name: shop.baseInfo.name, value: shop.baseInfo.id })
  }
  const isAreaPage = useIsAreaPage()
  const shops = useShopList(isAreaPage)
  shops.forEach(shop =>
    choices.push({ name: shop.baseInfo.name, value: shop.baseInfo.id })
  )

  return choices
}