import { ChildProps } from '~/common/utils/props'

const DrawerScroll = ({ children }: ChildProps) => {
  return (
    <div className={'l-drawer__scroll'}>
      {children}
    </div>
  )
}

export default DrawerScroll
