import useSWR from 'swr'

import Area from '~/model/area'
import { ApiPath, fetchApi } from '~/common/api/base'
import { usePathKey } from '~/common/app/path'

export interface AreaListResponse {
  areas: Area[]
}

export const fetchArea = async (key: string) => {
  const { data } = await fetchApi<Area>(ApiPath.AREA, { key })
  return data
}

export const useArea = () => {
  const key = usePathKey()
  const { data } = useSWR<Area>(key ? [ApiPath.AREA, { key }] : null)
  return data
}

export const fetchAreaList = async () => {
  const { data } = await fetchApi<AreaListResponse>(ApiPath.AREA_LIST)
  return data?.areas ?? []
}

export const useAreaList = () => {
  const { data } = useSWR<AreaListResponse>(ApiPath.AREA_LIST)
  return data?.areas ?? []
}

export const useIsAreaPage = () => {
  const key = usePathKey()
  const area = useAreaList().find((item) => {
    return item.area_key === key
  })

  return Boolean(area)
}