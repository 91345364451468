import { MouseEvent } from 'react'
import { ChildProps } from '~/common/utils/props'
import InternalLink from '~/components/utils/link/InternalLink'

interface Props extends ChildProps {
  category?: string
  link: string
  onClick(e: MouseEvent<HTMLElement>): void
}

const SuggestRow = ({children, category, link, onClick}: Props) => {
  return (
    <li className='c-suggest-row' onClick={onClick}>
      <InternalLink link={link}>
        <p className='c-suggest-row__key'>
          {children}
        </p>
        {category &&
        <p className='c-suggest-row__category'>{category}</p>
        }
      </InternalLink>
    </li>
  )
}

export default SuggestRow