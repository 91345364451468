import React from 'react'

import { ShopDetail } from '~/model/shop'
import { savedImagePath } from '~/common/app/path'
import { SHOP_INFO_TAG_ID } from '~/common/app/shop'
import { STYLE_MEDIA_PC, STYLE_MEDIA_SP } from '~/common/app/style'
import FooterCenter from '~/components/layouts/footer/FooterCenter'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import AreaFooter from '~/components/layouts/area/AreaFooter'
import InfoRow from '~/components/molecules/info/InfoRow'
import ImageFitContain from '~/components/utils/image/ImageFitContain'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'

interface Props {
  shop: ShopDetail
}

const ShopInfo = ({ shop }: Props) => {

  const address = shop.pref + shop.addr01 + shop.addr02

  return (
    <FooterCenter>
      <BlockTitle icon={IconType.SHOP} iconColor={'#' + shop.theme_color} margin={8}>
        {shop.baseInfo.name}のご案内
      </BlockTitle>
      <AreaFooter>
        <BlockTitle verticalBar iconColor={'#' + shop.theme_color} margin={8}>店舗情報</BlockTitle>
        <Segments spGap={8} spColumn={SegmentsType.COLUMN_REVERSE} pcColumn={SegmentsType.ROW}
                  pcBetween>
          <div className={'l-footer-info-left'}>
            <InfoRow title={'住所'} indentionData={address}>
              {'〒' + shop.baseInfo.postal_code}
            </InfoRow>
            {shop.phone_number &&
              <InfoRow title={'電話番号'}>
                {shop.phone_number}
              </InfoRow>
            }
            {shop.liquor_manager_name &&
              <InfoRow title={'酒類販売管理者'}>
                {shop.liquor_manager_name}
              </InfoRow>
            }
            {shop.liquor_licensed_date &&
              <InfoRow title={'酒類販売管理研修受講年月日'}>
                {shop.liquor_licensed_date}
              </InfoRow>
            }
            {shop.liquor_valid_date &&
              <InfoRow title={'次回研修の受講期限'}>
                {shop.liquor_valid_date}
              </InfoRow>
            }
            {shop.liquor_training_organization &&
              <InfoRow title={'研修実施団体名'}>
                {shop.liquor_training_organization}
              </InfoRow>
            }
            {shop.message &&
              <InfoRow title={'お店からのコメント'} longText>
                {shop.message}
              </InfoRow>
            }
          </div>
          <div className={'l-footer-info-right'}>
            <ImageFitContain src={savedImagePath(shop.shop_image_file)}
                             alt={shop.baseInfo.name} />
          </div>
          <style jsx>{`
            .l-footer-info-right {
              height: 180px;
            }

            @media ${STYLE_MEDIA_SP} {
              .l-footer-info-right {
                width: 100%;
              }
            }

            @media ${STYLE_MEDIA_PC} {
              .l-footer-info-left {
              }

              .l-footer-info-right {
                width: 264px;
                flex-shrink: 0;
              }
            }
          `}</style>
        </Segments>
      </AreaFooter>
    </FooterCenter>
  )
}

export default ShopInfo
