import { useCallback, useEffect, useRef } from 'react'

import { SwitchAreaResponse, useUserArea, useUserAddressList } from '~/common/api/user'
import { useCloseModal } from '~/common/app/store/modal'
import { useWriteNotice } from '~/common/app/store/notice'
import { useFormErrorState, useFormState } from '~/common/form/handle'
import { useMoveKeyPath } from '~/common/app/path'
import { requestErrorMessage } from '~/common/form/error'
import { ApiPath } from '~/common/api/base'
import { STYLE_MEDIA_PC } from '~/common/app/style'
import { convertAddressToAreaChoices, filterAreaAddress } from '~/common/app/user'

import { AreaType, AreaTypeError } from '~/components/app/form/area/areaType'
import Form from '~/components/app/form/base/Form'
import SecondaryTitle from '~/components/atoms/title/SecondaryTitle'
import ErrorText from '~/components/atoms/text/ErrorText'
import FormRadioButton from '~/components/app/form/widgets/FormRadioButton'
import { areaValidation } from '~/components/app/form/area/areaType'
import Loading from '~/components/atoms/loading/Loading'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import MarginTop from '~/components/utils/margin/MarginTop'

const AreaSettingForm = () => {
  const currentArea = useUserArea()
  const moveKeyPath = useMoveKeyPath()
  const closeModal = useCloseModal()
  const writeNotice = useWriteNotice()
  const { data, isLoading } = useUserAddressList()

  const addresses = filterAreaAddress(data)
  const choices = convertAddressToAreaChoices(addresses)

  const currentAddress = addresses.find((address) => {
    return address.area.area_key == currentArea?.area_key
  })

  const [formData, setData] = useFormState<AreaType>({
    postal_code: currentAddress ? currentAddress.postal_code : ''
  })
  const [formError, setError] = useFormErrorState<AreaTypeError>()

  useEffect(() => {
    setData({ postal_code: currentAddress ? currentAddress.postal_code : '' })
  }, [currentAddress])

  const onCompleted = useCallback(({ area }: SwitchAreaResponse) => {
    writeNotice('お住まいの地域を設定しました。')
    closeModal()
    moveKeyPath(area.area_key)
  }, [])

  const formRef = useRef<any>(null)
  useEffect(() => {
    if (formData.postal_code && formData.postal_code != currentAddress?.postal_code) {
      formRef.current?.submit()
    }
  }, [formData.postal_code])

  return (
    <Form
      apiPath={ApiPath.AREA_SWITCH}
      complete={onCompleted}
      formData={formData}
      formError={formError}
      setData={setData}
      setError={setError}
      validations={areaValidation}
      refetchApiPath={[ApiPath.USER]}
      formRef={formRef}
      method={'PUT'}
    >
      <SecondaryTitle inversion>登録先のお届け先のエリア</SecondaryTitle>
      {isLoading && <Loading />}
      <FormRadioButton
        choices={choices}
        name={'postal_code'}
        value={formData.postal_code}
        onChange={setData}
      />
      {formError.requestError && (
        <MarginTop marginSp={8}>
          <ErrorText>{requestErrorMessage(formError)}</ErrorText>
        </MarginTop>
      )}

      <div>
        <Button role={ButtonRole.LOW_PRIORITY} size={ButtonSize.LARGE} onClick={closeModal}>
          閉じる
        </Button>
        <style jsx>{`
          div {
            display: flex;
            justify-content: center;
            margin-top: 8px;
          }

          @media ${STYLE_MEDIA_PC} {
            div {
              display: none;
            }
          }
        `}</style>
      </div>
    </Form>
  )
}

export default AreaSettingForm
