import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  active?: boolean
  isShopPickUp?: boolean
}

const TableHeaderItem = ({ children, active = false, isShopPickUp = false }: Props) => {
  const className = 'c-table-header-item ' +
    addClass('c-table-header-item--active-delivery', active && !isShopPickUp) +
    addClass('c-table-header-item--active-pickup', active && isShopPickUp)

  return <th className={className}>{children}</th>
}

export default TableHeaderItem
