import { PointItem } from '~/model/product'
import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import LinkText from '~/components/atoms/text/LinkText'
import Text from '~/components/atoms/text/Text'

interface Props {
  point: number
  items: PointItem[]
  inversion?: boolean
  canUseCoupon?: boolean
}

const ProductPoint = ({ point, items, inversion = false, canUseCoupon = false }: Props) => {

  return (
    <div
      className={
        'p-product-detail-point' + addClass('p-product-detail-point--inversion', inversion)
      }
    >
      <div className='p-product-detail-point__in'>
        <div>
          {canUseCoupon && (
            <Text color={Color.SECONDARY} px={10} tag={'p'}>
              クーポン利用で
            </Text>
          )}
          <Text px={16} color={Color.SUPPORT_SUB} bold en>
            {canUseCoupon && '最大'}{point}P
          </Text>
          <Text color={Color.SUPPORT_SUB} bold>
            貯まる
          </Text>
        </div>
        <div className='p-product-detail-point__right'>
          {items.map((item, index) => {
            return (
              <dl key={index} className='p-product-detail-point__list'>
                <dt>
                  {item.link ? (
                    <LinkText link={item.link} px={10} underline external>
                      {item.name}
                    </LinkText>
                  ) : (
                    <>
                      <Text color={Color.SECONDARY} px={10}>
                        {item.name}
                      </Text>
                    </>
                  )}
                </dt>
                <dd>
                  ：{item.point}P({item.ratio}%)
                </dd>
              </dl>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default ProductPoint
