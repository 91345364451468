import { MouseEvent } from 'react'
import ImageFitContain from '~/components/utils/image/ImageFitContain'
import InternalLink from '~/components/utils/link/InternalLink'

interface Props {
  src: string
  alt?: string
  link: string
  type: 'small' | 'low' | 'high'
  tag?: 'h1' | 'div'

  onClick?(e: MouseEvent<HTMLElement>): void
}

const LogoImage = ({ src, alt = '', link, type, tag = 'div' }: Props) => {
  const Tag = tag
  const className = `c-header-logo c-header-logo--type-${type}`
  return (
    <Tag className={className}>
      <InternalLink link={link} className='c-header-logo__link'>
        <ImageFitContain src={src} alt={alt} />
      </InternalLink>
    </Tag>
  )
}

export default LogoImage
