import { ChildProps } from '~/common/utils/props'
import { addClass } from '~/common/utils/html'

interface Props extends ChildProps {
  scroll?: boolean
}

const NavList = ({ children, scroll = false }: Props) => {
  return (
    <ul className={'p-nav-list' + addClass('p-nav-list--scroll', scroll)}>
      {children}
    </ul>
  )
}

export default NavList
