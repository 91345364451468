import { Store, useStoreSWR } from '~/common/app/store/store'

export const useNaviOpening = (): boolean => {
  const { data } = useStoreSWR<boolean, Error>(Store.NAVI_OPEN, false)
  return data ?? false
}

export const useShowNavi = (): () => void => {
  const { mutate } = useStoreSWR<boolean, Error>(Store.NAVI_OPEN)
  return () => mutate(true)
}

export const useHideNavi = (): () => void => {
  const { mutate } = useStoreSWR<boolean, Error>(Store.NAVI_OPEN)
  return () => mutate(false)
}
