import InternalLink from '~/components/utils/link/InternalLink'

interface Props {
  link: string
  text: string
}

const NavRowChild = ({ link, text }: Props) => {
  return (
    <li>
      <InternalLink link={link} className='p-nav-row-child'>
        {text}
      </InternalLink>
    </li>
  )
}

export default NavRowChild
