import { FormWidgetError, FormWidgetErrors } from '~/common/form/error'
import { Validations } from '~/common/form/validation'

export interface LoginType {
  email: string
  password: string
}

export interface LoginTypeError {
  email: FormWidgetError[]
  password: FormWidgetError[]
}

export const initialData: LoginType = {
  email: '',
  password: ''
}

export const loginValidation: Validations = {
  email: [FormWidgetError.IS_EMPTY],
  password: [FormWidgetError.IS_EMPTY]
}
