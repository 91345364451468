import useSWR, { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import Cart, { CartItem } from '~/model/cart'
import { ApiPath, apiPutFetcher } from '~/common/api/base'
import { useWriteNotice } from '~/common/app/store/notice'
import { useHideLoading, useShowLoading } from '~/common/app/store/loading'
import { RequestError } from '~/common/app/requestError'
import { convertToFormError, useRequestErrored } from '~/common/form/error'
import { useCloseModal, useShowModal } from '~/common/app/store/modal'
import { Modal } from '~/components/app/modal/AppModal'
import { ConfirmModalProps } from '~/components/app/modal/ConfirmModal'

export interface CartOperationResponse {
  cartItem: CartItem
  message: string
}

export interface CartBulkAddResponse {
  messages: string[]
}

export const Operation = {
  ADD: 'add',
  REDUCE: 'reduce',
  DELETE: 'delete'
} as const
export type Operation = (typeof Operation)[keyof typeof Operation]

export interface CartOperationProps {
  operation: Operation
  productClassId: number
  quantity?: number
  callback?(): void
}

export const useCart = (should_validate?: boolean): Cart | undefined => {
  const { data } = useCartApi(should_validate)
  return data
}

export const useCartApi = (should_validate?: boolean) => {
  return useSWR<Cart>([
    ApiPath.CART,
    {
      should_validate: should_validate ?? false
    }
  ], null, should_validate ? { revalidateOnMount: true, revalidateIfStale: true } : undefined)
}

export const useCartOperationApi = (inCartPage: boolean = false) => {
  const { mutate } = useSWRConfig()
  const writeNotice = useWriteNotice()
  const showModal = useShowModal()
  const closeModal = useCloseModal()
  const showLoading = useShowLoading()
  const hideLoading = useHideLoading()
  const errored = useRequestErrored()

  const onSuccess = (data: CartOperationResponse) => {
    mutate([ApiPath.CART, { should_validate: false }])
    inCartPage && mutate([ApiPath.CART, { should_validate: true }])

    hideLoading()
    closeModal()
    writeNotice(data.message)
  }

  const { trigger } = useSWRMutation(ApiPath.CART_OPERATION, apiPutFetcher)

  return (props: CartOperationProps) => {
    const showConfirm = (message: string) => showModal({
      type: Modal.CONFIRM,
      childProps: {
        onClickYes: () => {
          showLoading()
          trigger({
            operation: props.operation,
            productClassId: props.productClassId,
            quantity: props.quantity,
            clear: true
          }, {
            onSuccess: (data) => {
              onSuccess(data)
              mutate(ApiPath.USER)
            },
            onError: (error: Response) => {
              errored(error)
            }
          })
        },
        title: 'カートの商品が削除されます',
        message
      } as ConfirmModalProps
    })

    const options = {
      onSuccess: (data: CartOperationResponse) => {
        props.callback && props.callback()
        onSuccess(data)
      },
      onError: (error: Response) => {
        hideLoading()
        const formError = convertToFormError(error)
        if (formError.requestError == RequestError.HAS_ANOTHER_USER_AREA) {
          writeNotice('配達エリア外です')
        } else if (formError.requestError == RequestError.HAS_DIFFERENT_AREA_CART) {
          showConfirm('異なるエリアのカートは、作成できません、カートの商品を削除してよろしいですか？')
        } else if (formError.requestError == RequestError.HAS_ANOTHER_SHOP_PICKUP_CART) {
          showConfirm('店舗受け取りは店舗ごとの個別精算となります。（同じカートに入れることは出来ません）カートの商品を削除してよろしいですか？')
        } else {
          errored(error)
        }
      }
    }

    showLoading()
    trigger({
      operation: props.operation,
      productClassId: props.productClassId,
      quantity: props.quantity
    }, options)
  }
}

export const useCartBulkAddApi = () => {
  const { mutate } = useSWRConfig()
  const writeNotice = useWriteNotice()
  const showLoading = useShowLoading()
  const hideLoading = useHideLoading()

  const options = {
    onSuccess: (data: CartBulkAddResponse) => {
      mutate([ApiPath.CART, { should_validate: false }])

      hideLoading()
      if (data.messages.length > 0) {
        writeNotice(data.messages.join('、'))
      } else {
        writeNotice('カートに追加しました')
      }
    },
    onError: (error: Response) => {
      hideLoading()
    }
  }
  const { trigger } = useSWRMutation(ApiPath.CART_BULK_ADD, apiPutFetcher, options)
  return (productClassIds: number[]) => {
    showLoading()
    trigger({
      product_class_ids: productClassIds
    })
  }
}
