import React from 'react'
import BaseProductCard, { ProductCardType } from './BaseProductCard'

interface Props {
  image: string
  onClick?(): void
  link?: string
  soldout?: boolean
  outOfSale?: boolean
  favorite?: boolean
}

const MicroProductCard = ({
  image,
  onClick,
  link,
  soldout = false,
  outOfSale = false,
  favorite = false
}: Props) => {
  return (
    <>
      <BaseProductCard
        type={ProductCardType.MICRO}
        image={image}
        onClick={onClick}
        link={link}
        soldout={soldout}
        outOfSale={outOfSale}
        favorite={favorite}
      />
    </>
  )
}

export default MicroProductCard
