import { ChildProps } from '~/common/utils/props'

const TagLinkList = ({ children }: ChildProps) => {
  return (
    <ul className={'c-link-tag-list'}>
      {children}
    </ul>
  )
}

export default TagLinkList
