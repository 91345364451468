import useSWR from 'swr'

import { ApiPath, fetchApi } from '~/common/api/base'
import Category from '~/model/category'
import { usePathKey } from '~/common/app/path'
import { definedFilter } from '~/common/utils/array'

export const fetchCategoryList = async (key?: string) => {
  const { data } = await fetchApi<Category[]>(ApiPath.CATEGORY_LIST, { key })
  return data
}

export const useCategoryList = ({
  parentCategoryId,
  searchWord,
  request
}: { parentCategoryId?: number, searchWord?: string, request?: boolean } = { request: true }) => {
  const key = usePathKey()
  const { data } = useSWR<Category[]>(request ? [ApiPath.CATEGORY_LIST, definedFilter({
    key: key,
    parent_id: parentCategoryId,
    search_word: searchWord
  })] : null)

  return data
}
