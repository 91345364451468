import React from 'react'
import { useRouter } from 'next/router'

import Coupon from '~/model/coupon'

import {
  calculateAddPointRate,
  CouponCardType,
  extractCouponUsageLimitItems,
  isUsageLimitNoteRequired, makeCouponProductListPathQuery
} from '~/common/app/coupon'
import { useCloseModal } from '~/common/app/store/modal'
import { isDate } from '~/common/utils/string'
import { useShowCouponModal } from '~/common/api/coupon'
import { formatToMonthDay } from '~/common/utils/date'
import SmallCouponCard from '~/components/molecules/card/SmallCouponCard'
import MediumCouponCard from '~/components/molecules/card/MediumCouponCard'
import LargeCouponCard from '~/components/molecules/card/LargeCouponCard'

export interface AppCouponCardProps {
  coupon: Coupon,
  type: CouponCardType,
  showModal?: boolean
}

const AppCouponCard = ({
  coupon,
  type,
  showModal = false,
}: AppCouponCardProps) => {

  const showCouponModal = useShowCouponModal()

  // LargeCouponCardのクーポンモーダルからの遷移の場合、モーダルを閉じる必要があるためクリックイベントを使用
  const closeModal = useCloseModal()
  const router = useRouter()
  const onClickTargetProductList = () => {
    router.push(coupon.link_url ?? makeCouponProductListPathQuery(coupon))
    closeModal()
  }

  return (
    <>
      {type == CouponCardType.LARGE && (
        <LargeCouponCard
          key={coupon.id}
          name={coupon.name}
          addPointRate={calculateAddPointRate(coupon)}
          discountPrice={coupon.discount_price}
          usageLimitItems={extractCouponUsageLimitItems(coupon)}
          isUsageLimitNoteRequired ={isUsageLimitNoteRequired(coupon)}
          expireDate={coupon.available_to}
          themeColor={'#' + coupon.theme_color}
          only={coupon.is_target_customer}
          used={coupon.is_used}
          onClick={() => onClickTargetProductList()}
        />
      )}
      {type == CouponCardType.MEDIUM && (
        <MediumCouponCard
          key={coupon.id}
          name={coupon.name}
          addPointRate={calculateAddPointRate(coupon)}
          discountPrice={coupon.discount_price}
          usageLimitItems={extractCouponUsageLimitItems(coupon, false)}
          expireDate={isDate(coupon.available_to) ? `${coupon.available_to}まで` : coupon.available_to}
          themeColor={'#' + coupon.theme_color}
          onClick={() => showCouponModal(coupon)}
        />
      )}
      {type == CouponCardType.SMALL && (
        <SmallCouponCard
          key={coupon.id}
          name={coupon.name}
          addPointRate={calculateAddPointRate(coupon)}
          discountPrice={coupon.discount_price}
          expireDate={formatToMonthDay(coupon.available_to)}
          themeColor={'#' + coupon.theme_color}
          onClick={showModal ? () => showCouponModal(coupon) : undefined}
        />
      )}
    </>
  )
}

export default AppCouponCard
