import { ChildProps } from '~/common/utils/props'
import { STYLE_MEDIA_PC, STYLE_MEDIA_SP } from '~/common/app/style'
import { addClass } from '~/common/utils/html'
import { textColorClass } from '~/common/utils/color'

interface Props extends ChildProps {
  spPadding?: 4 | 8 | 12 | 16 | 24 | 32
  pcPadding?: 4 | 8 | 12 | 16 | 24 | 32
  right?: boolean
}

const AreaFooter = ({ children, spPadding = 12, pcPadding = 24}: Props) => {

  return (
    <>
      <div className={'l-area-footer'}>{children}</div>
      <style jsx>{`
        .l-area-footer {
          padding: ${spPadding}px;
        }
        @media ${STYLE_MEDIA_PC} {
          .l-area-footer {
            padding: ${pcPadding ?? spPadding}px;
          }
        }
      `}</style>
    </>
  )
}

export default AreaFooter
