import { ChildProps } from '~/common/utils/props'

const FooterCopyRight = ({ children }: ChildProps) => {
  return (
    <div className='l-footer-copyright'>
      <small>{children}</small>
    </div>
  )
}

export default FooterCopyRight
