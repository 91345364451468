import React, { useEffect } from 'react'
import Script from 'next/script'

import User from '~/model/user'

export type GoogleTagManagerId = `GTM-${string}`

enum Event {
  UID = 'uid-push'
}

type DataLayerType = {
  uid?: number | string
  area?: string
  login_status?: string
  event?: string
}

declare global {
  interface Window {
    dataLayer: Array<Object>
  }
}

const pushDataLayer = (data: DataLayerType): void => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

interface Props {
  user?: User
  googleTagManagerId: GoogleTagManagerId
}

const GoogleTagManager = ({ user, googleTagManagerId }: Props) => {
  useEffect(() => {
    if (user) {
      pushDataLayer({
        uid: user.customer?.id ?? "not_set",
        area: user.access_area_key ?? "not_set",
        login_status: user.customer ? "login" : "not_login"
      })
      pushDataLayer({ event: Event.UID })
    }
  }, [user])

  return (
    <>
      <Script
        id='gtag-base'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${googleTagManagerId}');
    `
        }}
      />
    </>
  )
}

export default GoogleTagManager
