import { AcceptableNextTimeResponse } from '~/common/api/shop'
import { Mode } from '~/common/api/user'

export const AcceptableMessageType = {
  SHORT: 'SHORT',
  MIDDLE: 'MIDDLE',
  CLOSE: 'CLOSE'
}

export type AcceptableMessageType = (typeof AcceptableMessageType)[keyof typeof AcceptableMessageType]

export const nextAcceptableMessage = (acceptableNextTime?: AcceptableNextTimeResponse | undefined, mode?: Mode, type: AcceptableMessageType = AcceptableMessageType.SHORT) => {
  return !!(acceptableNextTime && mode != undefined)
    ? !!acceptableNextTime?.acceptable_day_prefix
      ? (type == AcceptableMessageType.CLOSE ? acceptableNextTime.close_time + ' までの注文で ' : '') + acceptableNextTime.acceptable_day_prefix +
      (type == AcceptableMessageType.MIDDLE || mode == Mode.PICKUP ? acceptableNextTime.acceptable_from + '〜' : '') +
      acceptableNextTime.acceptable_to +
      (type != AcceptableMessageType.MIDDLE ? ((mode == Mode.DELIVERY ? 'までにお届け' : 'に受け取り')) : '')
      : mode == Mode.DELIVERY ? '現在お届けができません' : '現在受け取りができません' : ''
}

export const isNextDeliverable = (acceptableNextTime: AcceptableNextTimeResponse | undefined) => {
  return !!acceptableNextTime && !!acceptableNextTime?.acceptable_day_prefix
}

export const modeLabel = (mode?: Mode) => {
  return mode == Mode.PICKUP ? '店舗受取' : '配達'
}
