import { useState } from 'react'
import { Color } from '~/common/utils/color'
import { entryPath, helpAboutPath, myPageLoginPath } from '~/common/app/path'
import { useShowModal } from '~/common/app/store/modal'
import ModalFooter from '~/components/layouts/modal/ModalFooter'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import Text from '~/components/atoms/text/Text'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import { IconType } from '~/components/atoms/icon/Icon'
import FirstTimeInform from '~/components/molecules/detail/FirstTimeInform'
import IconText from '~/components/atoms/icon/IconText'
import HiddenPc from '~/components/utils/hidden/HiddenPc'
import { Modal } from '~/components/app/modal/AppModal'

const UserSettingModal = () => {
  const [closed, setClosed] = useState<boolean>(false)
  const showModal = useShowModal()

  return (
    <>
      {!closed && (
        <HiddenPc>
          <ModalFooter onClickClose={() => setClosed(true)}>
            <BlockTitle verticalBar>ご利用エリアを設定してください</BlockTitle>
            <Segments spGap={8}>
              <Segments spColumn={SegmentsType.COLUMN} spGap={8} fitWidth>
                <Segments spColumn={SegmentsType.COLUMN} spGap={4} fitWidth>
                  <Text px={12}>郵便番号を設定する</Text>
                  <Button
                    size={ButtonSize.LARGE}
                    role={ButtonRole.HIGH_PRIORITY_YELLOW}
                    iconType={IconType.ADDRESS}
                    iconSize={14}
                    px={14}
                    onClick={() => showModal({ type: Modal.AREA_SETTING })}
                    expanded
                  >
                    エリア設定
                  </Button>
                </Segments>
                <Segments spColumn={SegmentsType.COLUMN} spGap={4} fitWidth>
                  <Text px={12}>すでに会員の方はこちら</Text>
                  <Button
                    size={ButtonSize.LARGE}
                    role={ButtonRole.HIGH_PRIORITY_DEEP_GREEN}
                    iconType={IconType.KEY}
                    onClick={() => showModal({ type: Modal.LOGIN })}
                    iconSize={14}
                    px={14}
                    expanded
                  >
                    ログイン
                  </Button>
                </Segments>
              </Segments>

              <FirstTimeInform
                title={'マルクトとは？'}
                textLink={helpAboutPath()}
                items={[
                  <>
                    <IconText
                      iconColor={Color.SUPPORT}
                      iconSize={10}
                      textPx={12}
                      iconType={IconType.CHECK}
                    >
                      近所のお店の商品をまとめて注文！
                    </IconText>
                  </>,
                  <>
                    <IconText
                      iconColor={Color.SUPPORT}
                      iconSize={10}
                      textPx={12}
                      iconType={IconType.CHECK}
                    >
                      最短当日受け取り！
                    </IconText>
                  </>,
                  <>
                    <IconText
                      iconColor={Color.SUPPORT}
                      iconSize={10}
                      textPx={12}
                      iconType={IconType.CHECK}
                    >
                      4000円以上で送料無料！
                      <br />
                      <Text px={12} color={Color.THIRD} bold>
                        初回は完全送料無料！
                      </Text>
                    </IconText>
                  </>
                ]}
                buttonLink={entryPath()}
                buttonText={'新規会員登録'}
                iconType={IconType.ENTRY}
              />
            </Segments>
          </ModalFooter>
        </HiddenPc>
      )}
    </>
  )
}

export default UserSettingModal
