import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react'
import TextBox, { TextBoxSize, TextBoxVisual } from '~/components/atoms/textbox/TextBox'
import { FormPartsProps, useInputHandleChange } from '~/common/form/handle'
import { TEXT_STRING_MAX_LENGTH } from '~/common/form/parameter'
import FormWidgetList from '~/components/layouts/form/FormWidgetList'
import FormWidgetErrorMessages from '~/components/app/form/widgets/FormWidgetErrorMessages'
import FormInput from '~/components/layouts/form/FormInput'

export interface FormTextBoxProps extends FormPartsProps<string> {
  px?: 14 | 16
  type?: HTMLInputTypeAttribute
  placeholder?: string
  maxLength?: number
  visual?: TextBoxVisual
  size?: TextBoxSize
  high?: boolean
  shrink?: boolean
  autoComplete?: string
  noErrorHighlight?: boolean

  onBlur?(event: ChangeEvent<HTMLInputElement>): void

  onFocus?(event: ChangeEvent<HTMLInputElement>): void
}

const FormTextBoxCommon = ({
  px,
  type,
  name,
  value,
  onChange,
  maxLength = TEXT_STRING_MAX_LENGTH,
  label = '',
  placeholder = '',
  errors = [],
  visual = TextBoxVisual.NORMAL,
  size = TextBoxSize.NORMAL,
  high = false,
  shrink = false,
  autoComplete = 'off',
  noErrorHighlight = false,
  onBlur,
  onFocus
}: FormTextBoxProps) => {
  const handleChange = useInputHandleChange<HTMLInputElement>(name, onChange)

  return (
    <FormInput wide={size == TextBoxSize.WIDE} shrink={shrink}>
      <TextBox
        px={px}
        type={type}
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        placeholder={placeholder.length > 0 ? placeholder : label}
        autoComplete={autoComplete}
        error={!noErrorHighlight && errors.length > 0}
        visual={visual}
        size={size}
        high={high}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <FormWidgetErrorMessages label={label} errors={errors} />
    </FormInput>
  )
}

export default FormTextBoxCommon
