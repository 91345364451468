import React from 'react'

import Product from '~/model/product'
import { ShopDetail } from '~/model/shop'
import { Color } from '~/common/utils/color'
import { useFavoriteProductListApi } from '~/common/api/product'
import { productListPath, savedImagePath } from '~/common/app/path'
import CardList from '~/components/molecules/card/CardList'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'
import AppProductCard from '~/components/app/module/product/AppProductCard'
import CardButton from '~/components/atoms/button/CardButton'
import LinkText from '~/components/atoms/text/LinkText'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import ShopIntroductionCard from '~/components/molecules/card/ShopIntroductionCard'

interface Props {
  products: Product[]
  type: ProductCardType
  ranking?: boolean
  shop?: ShopDetail
  link?: string
}

const pickupProductListPath = (shop: ShopDetail) => {
  return productListPath({
    key: shop?.baseInfo.shop_key ?? undefined,
    pickup_product: true
  })
}

const ShopIntroCard = ({ shop }: { shop: ShopDetail }) => {
  return (
    <ShopIntroductionCard link={pickupProductListPath(shop)}
      logoSrc={savedImagePath(shop.logo_image_file)}
      shopSrc={savedImagePath(shop.product_image_file)}
      logoAlt={shop.baseInfo.name + 'ロゴ'}
      shopAlt={shop.baseInfo.name}
      color={'#' + shop.theme_color}
      itemText={shop.product_genre}
    />
  )
}

const ProductScrollList = ({ products, type, ranking = false, shop, link}: Props) => {
  const { data: favoriteProductList } = useFavoriteProductListApi()
  const favoriteIdList = favoriteProductList?.map((product) => product.id) ?? []

  return (
    <CardList>
      {shop && (
          <ShopIntroCard shop={shop} />
      )}
      {products.map((product, index) => (
        <AppProductCard
          key={product.id}
          addPoint={product.add_point}
          product={product}
          isFavorite={favoriteIdList?.includes(product.id) ?? false}
          type={type}
          rank={ranking ? index + 1 : undefined}
        />
      ))}
      {link && (
        <CardButton type={type} link={link}>
          <LinkText>
            <Icon color={Color.LINK} type={IconType.ARROW} size={20} />
          </LinkText>
          <LinkText>
            {type === ProductCardType.MICRO ? (
              <>すべてみる</>
            ) : type === ProductCardType.SMALL ? (
              <>
                すべての商品
                <br />
                を見る
              </>
            ) : (
              <>すべての商品をみる</>
            )}
          </LinkText>
        </CardButton>
      )}
    </CardList>
  )
}

export default ProductScrollList
