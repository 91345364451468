import { useState } from 'react'

import { addClass } from '~/common/utils/html'
import Image from 'next/image'
import ImageFitContain from '~/components/utils/image/ImageFitContain'

interface Props {
  images: string[]
  alt: string
  searchProductId?: string
  onSelect?(index: number): void
  onLoad?(): void
}

interface ListProps extends Props {
  selectedIndex: number

  onSelect(index: number): void
}

const ImageList = ({ images, alt, selectedIndex, onSelect }: ListProps) => {
  return (
    <div className={'u-image-watcher__list'}>
      {images.map((image: string, index: number) => (
        <span key={index}
              className={'u-image-watcher__item' + addClass('u-image-watcher__item--selected', index == selectedIndex)}
              onClick={() => onSelect(index)}
        >
          <ImageFitContain
            src={image}
            alt={alt}
          />
        </span>
      ))}
    </div>
  )
}

const ImageWatcher = ({ images, alt, searchProductId, onSelect, onLoad }: Props) => {
  const [index, setIndex] = useState<number>(0)
  const mainImage = images[index]

  return (
    <>
      <div className={'u-image-watcher' + addClass('u-image-watcher__select', !!onSelect)}
           onClick={onSelect ? () => onSelect(index) : undefined} onLoad={onLoad}
      >
        <ImageFitContain
          src={mainImage}
          alt={alt}
        />
      </div>
      <ImageList images={images} alt={alt} onSelect={setIndex} selectedIndex={index} />
    </>
  )
}

export default ImageWatcher
