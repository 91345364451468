import useSWR from 'swr'

import { ApiPath, fetchApi } from '~/common/api/base'
import Review from '~/model/review'

export interface ReviewResponse {
  reviews: Review[]
}

export const fetchReviewList = async (id?: number) => {
  const { data } = await fetchApi<Review[]>(
    ApiPath.REVIEW_LIST, { id }
  )
  return data
}

export const useReviewList = (id?: number) => {
  const { data } = useSWR<Review[]>(id ? [ApiPath.REVIEW_LIST, { id }] : null)
  return data
}
