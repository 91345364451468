import SecondaryTitle from '~/components/atoms/title/SecondaryTitle'
import FormRow from '~/components/layouts/form/FormRow'
import Text from '~/components/atoms/text/Text'
import { TextAreaVisual } from '~/components/atoms/textarea/TextArea'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import { useShop, useShopList } from '~/common/api/shop'
import { useArea } from '~/common/api/area'
import { WidgetChoice } from '~/common/form/choice'
import { useFormErrorState, useFormState } from '~/common/form/handle'
import {
  OpinionType,
  OpinionTypeError,
  initialData,
  opinionValidation
} from '~/components/app/form/opinion/OpinionType'
import { ApiPath } from '~/common/api/base'
import FormTextArea from '~/components/app/form/widgets/FormTextArea'
import Form from '~/components/app/form/base/Form'
import { useWriteNotice } from '~/common/app/store/notice'
import FormPullDown from '~/components/app/form/widgets/FormPullDown'
import MarginBottom from '~/components/utils/margin/MarginBottom'
import Segments from '~/components/layouts/area/Segments'
import { useOpinionChoices } from '~/common/api/opinion'

const OpinionForm = () => {

  const [formData, setData] = useFormState<OpinionType>(initialData)
  const [formError, setError] = useFormErrorState<OpinionTypeError>()
  const writeNotice = useWriteNotice()
  const choices = useOpinionChoices()
  const onComplete = () => {
    writeNotice('意見を承りました。ありがとうございました。')
    setData(initialData)
  }

  return (
    <Form
      apiPath={ApiPath.OPINION_CREATE}
      complete={onComplete}
      formData={formData}
      formError={formError}
      setData={setData}
      setError={setError}
      validations={opinionValidation}
      method={'POST'}
    >
      <SecondaryTitle>マルクトへのご意見（こちらは送信専用です）</SecondaryTitle>
      <FormRow>
        <Text px={12} noShrink>投稿先</Text>
        <FormPullDown
          name={'shop_id'}
          onChange={setData}
          value={formData.shop_id}
          choices={choices}
          errors={formError.formWidgetErrors.shop_id}
          label={'投稿先'}
          noErrorHighlight
        />
      </FormRow>
      <MarginBottom marginSp={8}>
        <FormTextArea px={13} onChange={setData} name={'text'} value={formData.text}
                      visual={TextAreaVisual.INVERSION}
                      label={'ご意見'}
                      placeholder={'こちらへいただいたご意見には、回答は行っておりません。※回答をご希望のお客さまは「お問い合わせ」より、ご登録ください。'}
                      errors={formError.formWidgetErrors.text}
                      noErrorHighlight
        />
      </MarginBottom>
      <Segments>
        <Text px={10} tag={'p'}>
          ※皆様のご意見は、すべての内容を確認させていただきまして、今後の改善の参考にさせていただきます。
        </Text>
        <Button role={ButtonRole.HIGH_PRIORITY_YELLOW}
                size={ButtonSize.WIDE_MEDIUM} px={13} noShrink>意見を送る</Button>
      </Segments>
    </Form>
  )
}

export default OpinionForm
