import { ChildProps } from '~/common/utils/props'
import { Color } from '~/common/utils/color'

interface Props extends ChildProps {
  color?: Color | string
}

const HeaderWrap = ({ children, color = Color.SUPPORT_SUB }: Props) => {
  return (
    <>
      <div className='l-header-wrap'>
        {children}
      </div>
      <style jsx>{`
        .l-header-wrap {
          background: ${color};
        }
      `}</style>
    </>
  )
}

export default HeaderWrap
