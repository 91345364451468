import useSWR from 'swr'

import { ShopDetail } from '~/model/shop'
import Product from '~/model/product'
import SearchedProduct from '~/model/searched_product'
import Category from '~/model/category'
import { apiJsonpSearchFetcher, ApiPath, ApiSearchPath, fetchApi } from '~/common/api/base'
import {
  convertProductSearchSortToApiValue,
  useProductSearchParams,
  useProductSearchSimilarParams
} from '~/common/app/productSearch'
import { usePathKey } from '~/common/app/path'
import { definedFilter } from '~/common/utils/array'

export interface CollectionProductsListResponse {
  collection_name: string
  collection_code: string
  shop_key: string
  products: Product[]
}

export interface CollectionProductsResponse {
  collection_name: string
  collection_code: string
  description: string
  image_file: string
  products: Product[]
}

export interface ShopPickupProductsListResponse {
  shop: ShopDetail
  products: Product[]
  total: number
}

export interface CalculateAddProductsPointResponse {
  add_point: { [key: number]: number }
}

export interface ProductSearchListResponse {
  result?: {
    items: SearchedProduct[]
    facet_fields: {
      c2: string[],
      c3: string[]
    }
    total: number
  }
}

export interface TokubaiProductListResponse {
  products: Product[]
  total: number
}

export const fetchProduct = async (id: number) => {
  const { data } = await fetchApi<Product>(ApiPath.PRODUCT, { id: id })
  return data
}

export const useProductApi = (id?: number, revalidate = false) => {
  return useSWR<Product>(id ? [ApiPath.PRODUCT, { id }] : null, null,
    revalidate ? { revalidateIfStale: true } : undefined)
}

export const useProduct = (id?: number) => {
  const { data } = useProductApi(id)
  return data
}

export const useFavoriteProductListApi = ({ revalidate = false, isFilterLoggedInShop = false }: { revalidate?: boolean, isFilterLoggedInShop?: boolean } = {}) => {
  const pathKey = usePathKey()
  const { data: favoriteProductList, isLoading } = useSWR<Product[]>(
      ApiPath.PRODUCT_FAVORITE_LIST,
      revalidate ? { revalidateOnMount: true, revalidateIfStale: true } : undefined
  )

  const filteredList = isFilterLoggedInShop
    ? favoriteProductList?.filter((product) => product.id && product.shop?.shop_key === pathKey)
    : favoriteProductList

  return { data: filteredList, isLoading }
}

// Use in product favorite list and page
export const useFavoriteProductList = ({ revalidate = false, isFilterLoggedInShop = false }: { revalidate?: boolean, isFilterLoggedInShop?: boolean } = {}) => {
  const { data, isLoading } = useFavoriteProductListApi({ revalidate, isFilterLoggedInShop })

  // For local updating with only id before refetch
  return { products: data?.filter((product) => product.name), isLoading }
}

export const useRecentPurchasedProductListApi = () => {
  const key = usePathKey()
  return useSWR<Product[]>([
    ApiPath.PRODUCT_RECENT_PURCHASED_LIST,
    definedFilter({
      key
    })
  ])
}

export const fetchCollectionProductsList = async (key?: string) => {
  const { data } = await fetchApi<CollectionProductsListResponse[]>(
    ApiPath.PRODUCT_COLLECTION_LIST, { key }
  )
  return data
}

export const useCollectionProductsList = () => {
  const key = usePathKey()
  const { data } = useSWR<CollectionProductsListResponse[]>([
    ApiPath.PRODUCT_COLLECTION_LIST,
    definedFilter({
      key
    })
  ])
  return data
}

export const useCollectionApi = (code?: string, shop_key?: string, area_key?: string, order_by?: number) => {
  return useSWR<CollectionProductsResponse>(
    code ? [ApiPath.PRODUCT_COLLECTION, { code, shop_key, area_key, order_by }] : null
  )
}

export const fetchSaleProductList = async (key?: string) => {
  const { data } = await fetchApi<Product[]>(
    ApiPath.PRODUCT_SALE_LIST, { key }
  )
  return data
}

export const useSaleProductListApi = () => {
  const key = usePathKey()
  return useSWR<Product[]>([
    ApiPath.PRODUCT_SALE_LIST,
    definedFilter({
      key
    })
  ])
}

export const fetchTokubaiProductList = async (key?: string) => {
  const { data } = await fetchApi<Product[]>(
    ApiPath.PRODUCT_TOKUBAI_LIST, { key }
  )
  return data
}

export const useTokubaiProductListApi = (order_by?: number, request = true, offset?: number) => {
  const key = usePathKey()

  return useSWR<TokubaiProductListResponse>(
    request
      ? [
        ApiPath.PRODUCT_TOKUBAI_LIST,
        definedFilter({
          key,
          order_by: order_by,
          offset: offset
        })
      ]
      : null
  )
}

export const fetchPickupProductsList = async (key?: string) => {
  const { data } = await fetchApi<ShopPickupProductsListResponse[]>(
    ApiPath.PRODUCT_PICKUP_LIST, { key }
  )
  return data
}

export const usePickupProductListApi = (order_by?: number, request = true, offset?: number) => {
  const key = usePathKey()

  return useSWR<ShopPickupProductsListResponse[]>(
    request
      ? [
        ApiPath.PRODUCT_PICKUP_LIST,
        definedFilter({
          key,
          order_by: order_by,
          offset: offset
        })
      ]
      : null
  )
}

export const fetchRankingProductList = async (key?: string) => {
  const { data } = await fetchApi<Product[]>(ApiPath.PRODUCT_RANKING_LIST, { key })

  return data
}

export const useRankingProductList = () => {
  const key = usePathKey()
  const { data } = useSWR<Product[]>([
    ApiPath.PRODUCT_RANKING_LIST,
    definedFilter({
      key
    })
  ])
  return data
}

export const fetchRelatedPvProductsList = async (product_id: number) => {
  const { data } = await fetchApi<Product[]>(
    ApiPath.PRODUCT_RELATED_PV_LIST, { product_id }
  )
  return data
}

export const useRelatedPvProductsListApi = (product_id: number) => {
  return useSWR<Product[]>([
    ApiPath.PRODUCT_RELATED_PV_LIST,
    definedFilter({
      product_id
    })
  ])
}

export const useProductListByIdsAPi = (product_ids: number[] | undefined) => {
  const key = usePathKey()
  return useSWR<Product[] | null>(
    product_ids
      ? [
        ApiPath.PRODUCT_LIST,
        definedFilter({
          product_ids,
          key
        })
      ]
      : null
  )
}

export const useSearchedProductList = (sortValue?: number, pageNo?: number, word?: string, category?: Category, categoryIds?: number[], queryShopIds?: string[], request = true) => {
  const sortValueString = convertProductSearchSortToApiValue(sortValue)
  const params = useProductSearchParams(category, categoryIds, queryShopIds, pageNo, word, sortValueString)

  return useSWR<ProductSearchListResponse>(
    request ? [ApiSearchPath.SEARCH, params] : null,
    apiJsonpSearchFetcher
  )
}

export const useSimilarSearchedProductList = (category?: Category) => {
  const params = useProductSearchSimilarParams(category)

  return useSWR<ProductSearchListResponse>([ApiSearchPath.SEARCH, params], apiJsonpSearchFetcher)
}
