import { entryPath, helpAboutPath } from '~/common/app/path'

import ModalFrame from '~/components/layouts/modal/ModalFrame'
import LoginForm from '~/components/app/form/login/LoginForm'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import Section from '~/components/layouts/section/Section'
import SecondaryTitle from '~/components/atoms/title/SecondaryTitle'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import HiddenPc from '~/components/utils/hidden/HiddenPc'
import { Color } from '~/common/utils/color'
import { IconType } from '~/components/atoms/icon/Icon'
import IconText from '~/components/atoms/icon/IconText'
import MarginTop from '~/components/utils/margin/MarginTop'

interface Props {
  onClickClose(): void
}

const EntrySection = ({ onClickClose }: Props) => {
  return (
    <>
      <SecondaryTitle inversion>新規会員登録</SecondaryTitle>
      <Segments spGap={12} spColumn={SegmentsType.COLUMN} pcColumn={SegmentsType.COLUMN}>
        <IconText iconColor={Color.SUPPORT} iconSize={12} textPx={16} iconType={IconType.CHECK}>
          会費無料で会員登録
        </IconText>
        <IconText iconColor={Color.SUPPORT} iconSize={12} textPx={16} iconType={IconType.CHECK}>
          次回お買い物に使えるマルクトポイントを獲得
        </IconText>
        <IconText iconColor={Color.SUPPORT} iconSize={12} textPx={16} iconType={IconType.CHECK}>
          オトクな情報をメールでお知らせ
        </IconText>
        <IconText iconColor={Color.SUPPORT} iconSize={12} textPx={16} iconType={IconType.CHECK}>
          次回お買い物時の入力が簡単に
        </IconText>
      </Segments>
      <MarginTop marginSp={16}>
        <Segments spGap={8} spColumn={SegmentsType.COLUMN} spAlignCenter>
          <Segments spGap={8} spCenter pcCenter>
            <Button role={ButtonRole.LOW_PRIORITY} size={ButtonSize.LARGE} link={helpAboutPath()}>
              マルクトとは？
            </Button>
            <Button role={ButtonRole.HIGH_PRIORITY_DEEP_GREEN} size={ButtonSize.LARGE}
                    link={entryPath()} noInternal>
              新規会員登録
            </Button>
          </Segments>
          <HiddenPc>
            <Button role={ButtonRole.LOW_PRIORITY} size={ButtonSize.LARGE} onClick={onClickClose}>
              閉じる
            </Button>
          </HiddenPc>
        </Segments>
      </MarginTop>
    </>
  )
}

const LoginModal = ({ onClickClose }: Props) => {
  return (
    <ModalFrame onClickClose={onClickClose}>
      <BlockTitle verticalBar tag='h2'>
        ご利用エリアを設定
      </BlockTitle>
      <Segments spGap={24} pcGap={32} spColumn={SegmentsType.COLUMN}>
        <Section>
          <LoginForm complete={onClickClose} />
        </Section>
        <Section>
          <EntrySection onClickClose={onClickClose} />
        </Section>
      </Segments>
    </ModalFrame>
  )
}

export default LoginModal
