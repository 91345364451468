import { useCallback } from 'react'

import { ApiPath } from '~/common/api/base'
import { forgotPath, usePathKey } from '~/common/app/path'
import { useWriteNotice } from '~/common/app/store/notice'
import { useFormErrorState, useFormState } from '~/common/form/handle'
import { requestErrorMessage } from '~/common/form/error'
import { RequestError } from '~/common/app/requestError'
import { Color } from '~/common/utils/color'
import { definedFilter } from '~/common/utils/array'
import {
  initialData,
  LoginType,
  LoginTypeError,
  loginValidation
} from '~/components/app/form/login/loginType'
import Form from '~/components/app/form/base/Form'
import FormRow from '~/components/layouts/form/FormRow'
import { TextBoxSize } from '~/components/atoms/textbox/TextBox'
import SecondaryTitle from '~/components/atoms/title/SecondaryTitle'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import FormEmailBox from '~/components/app/form/widgets/FormEmailBox'
import FormPassword from '~/components/app/form/widgets/FormPassword'
import LinkText from '~/components/atoms/text/LinkText'
import HiddenPc from '~/components/utils/hidden/HiddenPc'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import Text from '~/components/atoms/text/Text'
import AreaMessage from '~/components/layouts/area/AreaMessage'

interface Props {
  complete?(): void
}

const LoginForm = ({ complete }: Props) => {
  const writeNotice = useWriteNotice()
  const [formData, setData] = useFormState<LoginType>(initialData)
  const [formError, setError] = useFormErrorState<LoginTypeError>()
  const key = usePathKey()

  const onLoginCompleted = useCallback(() => {
    writeNotice('ログインしました')
    complete && complete()
  }, [])

  return (
    <Form
      apiPath={ApiPath.LOGIN}
      complete={onLoginCompleted}
      formData={formData}
      formError={formError}
      setData={setData}
      setError={setError}
      validations={loginValidation}
      refetchApiPath={[ApiPath.USER, [ApiPath.CART, { should_validate: false }],
        ApiPath.PRODUCT_FAVORITE_LIST,
        [
          ApiPath.PRODUCT_RECENT_PURCHASED_LIST,
          definedFilter({
            key
          })
        ],
        [
          ApiPath.COUPON_LIST,
          definedFilter({
            key,
            with_used_invalid: false,
            has_other_shop_coupon: true
          })
        ],
      ]}
    >
      <SecondaryTitle inversion>ログイン</SecondaryTitle>
      {formError.requestError &&
        <>
          <div>
            <AreaMessage color={Color.BG_ALERT}>
              <Text color={Color.ASSERTION} bold tag='p' px={12}>
                {formError.requestError == RequestError.UNAUTHORIZED ?
                  <>
                    ログインできませんでした。
                    <br />
                    入力内容に誤りがないかご確認ください。
                  </> :
                  <>{requestErrorMessage(formError)}</>
                }
              </Text>
            </AreaMessage>
          </div>
          <style jsx>{`
            div {
              margin: 8px 0 16px;
            }
          `}</style>
        </>
      }
      <FormRow spMargin={8}>
        <FormEmailBox
          label='メールアドレス'
          name={'email'}
          value={formData.email}
          onChange={setData}
          size={TextBoxSize.WIDE}
          errors={formError.formWidgetErrors.email}
          high
        />
      </FormRow>
      <FormRow>
        <FormPassword
          label='パスワード'
          name={'password'}
          value={formData.password}
          onChange={setData}
          size={TextBoxSize.WIDE}
          errors={formError.formWidgetErrors.password}
          high
        />
      </FormRow>
      <FormRow center>
        <LinkText arrow={Color.LINK} link={forgotPath()} noInternal>
          パスワードを忘れた方はこちら
        </LinkText>
      </FormRow>
      <Segments spGap={12} spColumn={SegmentsType.COLUMN} spAlignCenter pcCenter>
        <Button role={ButtonRole.HIGH_PRIORITY_YELLOW} size={ButtonSize.LARGE}>
          ログインする
        </Button>
        <HiddenPc>
          <Button role={ButtonRole.LOW_PRIORITY} size={ButtonSize.LARGE} onClick={complete}>
            閉じる
          </Button>
        </HiddenPc>
      </Segments>
    </Form>
  )
}

export default LoginForm
