import React from 'react'

import { AcceptableTime, ShopDetail } from '~/model/shop'
import { useShopInfo } from '~/common/api/shop'
import { Color } from '~/common/utils/color'
import FooterCenter from '~/components/layouts/footer/FooterCenter'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import AreaFooter from '~/components/layouts/area/AreaFooter'
import Text from '~/components/atoms/text/Text'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import TableFrame from '~/components/atoms/table/TableFrame'
import TableRow from '~/components/atoms/table/TableRow'
import TableHeaderItem from '~/components/atoms/table/TableHeaderItem'
import TableRowItem from '~/components/atoms/table/TableRowItem'
import Tag, { TagType } from '~/components/atoms/tag/Tag'
import MarginTop from '~/components/utils/margin/MarginTop'
import StatusDescription, { StatusType } from '~/components/atoms/status/StatusDescription'
import NoteText from '~/components/atoms/text/NoteText'

interface Props {
  shop: ShopDetail
}

interface ConvertAcceptableTimes {
  [key: string]: {
    id?: number
    close_time?: string
    attention?: string
    days: {
      [key: string]: {
        deliverable?: boolean
        ng_reason?: string
      }
    }
  }
}

interface ConvertAcceptableDayAndTimes {
  convertAcceptableTimes: ConvertAcceptableTimes
  convertAcceptableDays: string[]
}

const ShopDeliveryInfo = ({ shop }: Props) => {
  const shopInfo = useShopInfo()
  const deliveryTimes = shopInfo?.deliveryTimes
  const shopPickupTimes = shopInfo?.shopPickupTimes

  const convertAcceptableDayAndTimes = (AcceptableTimes: AcceptableTime[]) => {
    let convertAcceptableTimes: ConvertAcceptableTimes = {}
    acceptableTimes(AcceptableTimes).forEach(function (acceptableTime) {
      convertAcceptableTimes[acceptableTime] = { days: {} }
    })
    let days: string[] = []
    AcceptableTimes.forEach(function (time) {
      convertAcceptableTimes[time.acceptable_time]['id'] = time.acceptable_time_id
      convertAcceptableTimes[time.acceptable_time]['close_time'] = time.close_time
      convertAcceptableTimes[time.acceptable_time]['attention'] = time.attention
      convertAcceptableTimes[time.acceptable_time]['days'][time.acceptable_date] = {}
      convertAcceptableTimes[time.acceptable_time]['days'][time.acceptable_date]['deliverable'] =
        time.deliverable
      convertAcceptableTimes[time.acceptable_time]['days'][time.acceptable_date]['ng_reason'] =
        time.ng_reason
      if (!days.includes(time.acceptable_date)) {
        days.push(time.acceptable_date)
      }
    })

    return {
      convertAcceptableTimes: convertAcceptableTimes,
      convertAcceptableDays: days
    }
  }

  const acceptableTimes = (AcceptableTimes: AcceptableTime[]) => {
    let times: string[] = []
    let firstDate: string | undefined = undefined
    AcceptableTimes.some(function (time) {
      // acceptable_dateがループの中で変わるタイミングで全てのacceptable_timeが揃う
      if (firstDate && firstDate !== time.acceptable_date) {
        return true
      }
      times.push(time.acceptable_time)
      firstDate = time.acceptable_date
    })
    return times
  }

  const convertDeliveryTimes = deliveryTimes ? convertAcceptableDayAndTimes(deliveryTimes) : null
  const convertShopPickupTimes = shopPickupTimes
    ? convertAcceptableDayAndTimes(shopPickupTimes)
    : null

  const convertAcceptableTimesForView = (
    convertAcceptableDayAndTimes: ConvertAcceptableDayAndTimes,
    isShopPickup = false
  ) => {
    const { convertAcceptableTimes, convertAcceptableDays } = convertAcceptableDayAndTimes

    return (
      <>
        <TableFrame>
          <thead>
            <TableRow>
              <TableHeaderItem>{isShopPickup ? 'お届け希望枠' : '受取希望枠'}</TableHeaderItem>
              <TableHeaderItem>〆時間</TableHeaderItem>
              {convertAcceptableDays?.map((day, index) => (
                <TableHeaderItem key={index} active={index === 0} isShopPickUp={isShopPickup}>
                  {index === 0
                    ? '本日(' + day + '日)'
                    : index === 1
                    ? '明日(' + day + '日)'
                    : day + '日'}
                </TableHeaderItem>
              ))}
            </TableRow>
          </thead>
          <tbody>
            {Object.keys(convertAcceptableTimes).map((time, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <Text px={12} color={Color.PRIMARY} bold tag={'p'}>
                    {time}
                  </Text>
                  <Text px={10} color={Color.SECONDARY}>
                    {convertAcceptableTimes[time].attention}
                  </Text>
                </TableRowItem>
                <TableRowItem>
                  <Text px={12} color={Color.PRIMARY} bold>
                    {convertAcceptableTimes[time].close_time}
                  </Text>
                </TableRowItem>
                {convertAcceptableDays.map((day) => (
                  <TableRowItem key={day}>
                    {convertAcceptableTimes[time].days[day].deliverable ? (
                      <Text px={20} color={Color.SUPPORT} bold>
                        ○
                      </Text>
                    ) : convertAcceptableTimes[time].days[day].ng_reason === StatusType.HYPHEN ? (
                      <Text px={28} color={Color.PRIMARY}>
                        -
                      </Text>
                    ) : (
                      <Text px={28} color={Color.PRIMARY}>
                        ×
                      </Text>
                    )}
                  </TableRowItem>
                ))}
              </TableRow>
            ))}
          </tbody>
        </TableFrame>
        <Segments pcAlignCenter spAlignCenter pcEnd spEnd>
          <StatusDescription type={StatusType.ROUND} />
          <StatusDescription type={StatusType.CROSS} />
          <StatusDescription type={StatusType.HYPHEN} />
        </Segments>
      </>
    )
  }

  return (
    <FooterCenter>
      <Segments spColumn={SegmentsType.COLUMN} spGap={8} pcGap={16}>
        <AreaFooter>
          <BlockTitle verticalBar iconColor={'#' + shop.theme_color} margin={8}>
            配達サービス
          </BlockTitle>
          {convertDeliveryTimes && <>{convertAcceptableTimesForView(convertDeliveryTimes)}</>}
          <NoteText>日付が全て表示されていない時は、表を横にスクロールしてご覧ください</NoteText>
          <NoteText>
            天候や交通事情によりご希望いただきますお時間にお届けできない場合がございます。
          </NoteText>
          <div className='l-footer-frame__block'>
            <div className='l-footer-frame__block__left'>
              <Text px={12} bold>
                非対面受取
              </Text>
            </div>
            <Text px={12}>対応可</Text>
            <Text px={12} color={Color.THIRD}>
              （クレジットカード支払いのみ）
            </Text>
          </div>
          <style jsx>{`
            .l-footer-frame__block {
              background: #eee;
              padding: 12px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin: 8px 0;
            }

            .l-footer-frame__block__left {
              margin-right: 16px;
            }
          `}</style>
          <NoteText>
            置き配は承っておりませんので、お届け時間は必ずご在宅されるようにお願いします。
          </NoteText>
          <NoteText>ご不在の場合は再配達量が発生しますのでご了承ください。</NoteText>
        </AreaFooter>
        {convertShopPickupTimes && (
          <AreaFooter>
            <BlockTitle verticalBar iconColor={'#' + shop.theme_color} margin={8}>
              店舗受け取りサービス
            </BlockTitle>
            <Tag tagType={TagType.RIBBON}>受取方法</Tag>
            <MarginTop marginPc={2}>
              <Text px={12} tag={'p'}>
                ①指定した受取時間内に店舗にお越しください。
                <br />
                ②スタッフに注文番号（下４桁）とお名前をお知らせください。
                <br />
                ※注文番号は注文受付メールをご確認ください。
                <br />
                ③スタッフがご注文のお品物をご用意し、直接お渡しします。
                <br />
                ④受領のサインを頂戴いたします。
                <br />
                <br />
                【キャンセルやお受取り時の注意事項について】
                <br />
                ※詳細は「店舗受取サービス利用規約」をご覧ください。
                <br />
              </Text>
            </MarginTop>
            {convertAcceptableTimesForView(convertShopPickupTimes, true)}
            <NoteText>※日付が全て表示されていない時は、表を横にスクロールしてご覧ください</NoteText>
            <NoteText>
              ※選択されたお時間にお越しいただけない場合には、衛生上の理由で、商品を廃棄させていただく場合がございます。
            </NoteText>
          </AreaFooter>
        )}
      </Segments>
    </FooterCenter>
  )
}

export default ShopDeliveryInfo
