import { ChildProps } from '~/common/utils/props'
import { STYLE_MEDIA_PC } from '~/common/app/style'

interface Props extends ChildProps {
  spMarginTop?: number
  pcMarginTop?: number
  spMarginLeft?: number
  pcMarginLeft?: number
  spMarginRight?: number
  pcMarginRight?: number
}

const Section = ({
  children,
  spMarginTop = 0,
  pcMarginTop,
  spMarginLeft = 0,
  pcMarginLeft,
  spMarginRight = 0,
  pcMarginRight
}: Props) => {
  return (
    <>
      <section className='l-section-section'>{children}</section>
      <style jsx>{`
        .l-section-section {
          width: 100%;
          margin-top: ${spMarginTop}px;
          margin-left: ${spMarginLeft}px;
          margin-right: ${spMarginTop}px;
        }

        @media ${STYLE_MEDIA_PC} {
          .l-section-section {
            margin-top: ${pcMarginTop ?? spMarginTop}px;
            margin-left: ${pcMarginLeft ?? spMarginLeft}px;
            margin-right: ${pcMarginRight ?? spMarginRight}px;
          }
        }
      `}</style>
    </>
  )
}

export default Section
