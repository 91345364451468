import { UsageLimitItem } from '~/common/app/coupon'
import { Color } from '~/common/utils/color'
import Text from '~/components/atoms/text/Text'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'

interface Props {
  name: string
  addPointRate?: number
  discountPrice?: number
  usageLimitItems: UsageLimitItem[]
  expireDate: string
  themeColor: string
  onClick(): void,
}

const MediumCouponCard = ({
  name,
  addPointRate,
  discountPrice,
  usageLimitItems,
  expireDate,
  themeColor,
  onClick
}: Props) => {

  return (
    <>
      <div className={'p-coupon-card-medium'} onClick={onClick}>
        <div className='p-coupon-card-medium__in'>
          <Segments
            spColumn={SegmentsType.COLUMN}
            pcColumn={SegmentsType.COLUMN}
            spAlignCenter
            pcAlignCenter
          >
            <Segments
              spColumn={SegmentsType.COLUMN}
              pcColumn={SegmentsType.COLUMN}
              spAlignCenter
              pcAlignCenter
              fitWidth
            >
              <Text color={Color.INVERSION} px={10} tag={'p'} bold omit wide center>
                {name}
              </Text>
              {addPointRate && (
                <Text color={Color.INVERSION} px={12} bold>
                  ポイント
                  <Text color={Color.INVERSION} px={20} bold>
                    {addPointRate}
                  </Text>
                  倍
                </Text>
              )}
              {discountPrice && (
                <Text color={Color.INVERSION} px={12} bold>
                  <Text color={Color.INVERSION} px={20} bold>
                    {discountPrice}
                  </Text>
                  円OFF
                </Text>
              )}
            </Segments>
            <div className='p-coupon-card-medium__conditions'>
              {usageLimitItems.map((usageLimitItem, index) => {
                return (
                  <Text key={index} color={Color.INVERSION} px={10} omit tag={'p'}>
                    {usageLimitItem.name}：{usageLimitItem.contents}
                  </Text>
                )
              })}
            </div>
          </Segments>
        </div>
        <div className='p-coupon-card-medium__expiry'>
          <span className='p-coupon-card-medium__expiry__text'>
            <Text color={Color.INVERSION} px={10}>
              {expireDate}
            </Text>
          </span>
        </div>
      </div>
      <style jsx>{`
        .p-coupon-card-medium,
        .p-coupon-card-medium__expiry:before {
          background-color: ${themeColor};
        }
      `}</style>
    </>
  )
}

export default MediumCouponCard
