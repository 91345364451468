import React, { ReactNode } from 'react'
import { addClass } from '~/common/utils/html'
import OpacityToggle from '~/components/utils/animation/OpacityToggle'

export const BackgroundType = {
  NORMAL: 'NORMAL',
  INVERSION: 'INVERSION'
} as const

export type BackgroundType = (typeof BackgroundType)[keyof typeof BackgroundType]

interface Props {
  children?: ReactNode
  type?: BackgroundType
  show: boolean
  onClick?(e: React.MouseEvent<HTMLElement>): void
  noCenter?: boolean
}

const Background = ({ children, type = BackgroundType.NORMAL, onClick, show, noCenter }: Props) => {
  return (
    <OpacityToggle show={show} className={'l-background__wrap'}>
      <div
        className={
          'l-background' +
          addClass(
            type == BackgroundType.NORMAL
              ? 'l-background--type-normal'
              : 'l-background--type-inversion'
          )
        }
        onClick={onClick}
      />
      {children &&
        <div className={noCenter ? '' : 'l-background__in'}>
          {children}
        </div>
      }
    </OpacityToggle>
  )
}

export default Background
