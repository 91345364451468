import { Color } from '~/common/utils/color'
import { ChildProps } from '~/common/utils/props'
import Text from '~/components/atoms/text/Text'

const ErrorText = ({ children }: ChildProps) => {
  return (
    <Text color={Color.ASSERTION} px={8}>{children}</Text>
  )
}

export default ErrorText
