import { MouseEvent } from 'react'
import { ChildProps } from '~/common/utils/props'
import Icon, { IconType } from '~/components/atoms/icon/Icon'

interface Props extends ChildProps {
  onClickClose(e: MouseEvent<HTMLElement>): void
}

const ModalFooter = ({ children, onClickClose }: Props) => {
  return (
    <div className='l-modal-footer'>
      <button className='l-modal-close' onClick={onClickClose}>
        <Icon color={'inherit'} size={40} type={IconType.CLOSE_MODAL} />
      </button>
      {children}
    </div>
  )
}

export default ModalFooter
