import Image from 'next/image'
import { addClass } from '~/common/utils/html'

interface Props {
  src: string
  alt?: string
  background?: boolean
}

const ImageFitContain = ({ src, alt = '', background = false }: Props) => {
  return (
    <span className={'u-image-fit-contain' + addClass('u-image-fit-contain--background', background)}>
      <Image src={src} alt={alt} fill className='u-image-fit-contain__item' />
    </span>
  )
}

export default ImageFitContain
