import React from 'react'
import { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'

import { Validations } from '~/common/form/validation'
import { Type } from '~/common/form/type'
import { ChildProps } from '~/common/utils/props'
import { ApiPath, apiPostFetcher, apiPutFetcher, Params } from '~/common/api/base'
import { convertToFormError, FormError } from '~/common/form/error'
import { useHideLoading, useShowLoading } from '~/common/app/store/loading'
import { useUserApi } from '~/common/api/user'
import FormBase from '~/components/app/form/base/FormBase'

export interface FormProps extends ChildProps {
  apiPath: ApiPath
  pathParams?: Params

  complete(data: any): void

  convertToRequestValues?(type: Type): Type

  formData: Type
  formError: FormError<Type>

  setData(param: Type): void

  setError(formError: FormError<Type>): void

  validations: Validations

  showLoading?(): void

  hideLoading?(): void

  refetchApiPath?: (ApiPath | [ApiPath, Params])[]
  formRef?: React.MutableRefObject<any>
  method?: 'POST' | 'PUT'
}

const Form = ({
  children,
  complete,
  apiPath,
  pathParams = {},
  convertToRequestValues,
  formData,
  setError,
  validations,
  refetchApiPath = [],
  formRef,
  method = 'POST'
}: FormProps) => {
  const { mutate } = useSWRConfig()
  const { data: user } = useUserApi()
  const showLoading = useShowLoading()
  const hideLoading = useHideLoading()

  const options = {
    onSuccess: (data: Response) => {
      complete && complete(data)
      hideLoading()
      refetchApiPath?.forEach((path) => {
        mutate(path)
      })
    },
    onError: (error: Response) => {
      setError(convertToFormError(error))
      hideLoading()
    }
  }

  const { trigger } = useSWRMutation(
    apiPath,
    method == 'PUT' ? apiPutFetcher : apiPostFetcher,
    options
  )

  const call = (type: Type) => {
    showLoading()

    const variables = convertToRequestValues ? convertToRequestValues(type) : type
    trigger(
      method == 'PUT'
        ? { ...variables, ...pathParams }
        : { _csrf_token: user?._csrf_token, ...variables, ...pathParams }
    )
  }

  return (
    <FormBase
      formData={formData}
      call={call}
      validations={validations}
      setError={setError}
      formRef={formRef}
    >
      {children}
    </FormBase>
  )
}

export default Form
