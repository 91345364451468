import React from 'react'
import { FormPartsProps, useInputHandleChange } from '~/common/form/handle'
import { WidgetChoice } from '~/common/form/choice'
import RadioButtonElement from '~/components/atoms/radio/RadioButtonElement'
import FormWidgetList from '~/components/layouts/form/FormWidgetList'

interface Props extends FormPartsProps<number | string> {
  choices: WidgetChoice<number | string>[]
  horizon?: boolean
  pcFitContent?: boolean
}

const FormRadioButton = ({
  name,
  onChange,
  errors = [],
  choices = [],
  value,
  horizon = false,
  pcFitContent = false
}: Props) => {
  const handleChange = useInputHandleChange<HTMLInputElement>(name, onChange)

  return (
    <FormWidgetList horizon={horizon} pcFitContent={pcFitContent}>
      {choices.map((choice: WidgetChoice<number | string>, index: number) => (
        <RadioButtonElement
          key={'radio-' + name + index}
          label={choice.name}
          name={name}
          onChange={handleChange}
          value={choice.value}
          checked={value == choice.value}
          description={choice.description}
          pcFitContent={pcFitContent}
        />
      ))}
    </FormWidgetList>
  )
}

export default FormRadioButton
