import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  title: string
  indentionData?: string
  longText?: boolean
  wide?: boolean
}

const InfoRow = ({
  title,
  children,
  indentionData = undefined,
  wide = false,
  longText = false
}: Props) => {
  return (
    <>
      <dl
        className={'p-info-row' + addClass('p-info-row--wide', wide) + addClass('p-info-row--long-text', longText)}>
        <dt
          className={'p-info-row__terms' + addClass(longText ? '' : wide ? 'p-info-row__terms--wide' : 'p-info-row__terms--normal')}>{title}</dt>
        <dd className={'p-info-row__data'}>
          {children}
          {indentionData &&
            <>
              <br className={'u-hidden-pc'} />
              {indentionData}
            </>
          }
        </dd>
      </dl>
    </>
  )
}

export default InfoRow
