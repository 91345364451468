import React from 'react'
import { Color } from '~/common/utils/color'
import CategoryNavList from '~/components/app/module/category/CategoryNavList'
import ModalFrame, { ModalFrameSize } from '~/components/layouts/modal/ModalFrame'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'

interface Props {
  onClickClose(): void
}

const CategoryModal = ({ onClickClose }: Props) => {

  return (
    <ModalFrame onClickClose={onClickClose} size={ModalFrameSize.NO}>
      <div className={'l-modal-category__title'}>
        <BlockTitle icon={IconType.CATEGORY} iconColor={Color.SUPPORT_SUB}>カテゴリから探す</BlockTitle>
      </div>
      <div className={'l-modal-category__list'}>
        <CategoryNavList showChild scroll />
      </div>
      <style jsx>{`
        .l-modal-category__title {
          margin: 0 12px;
        }

        .l-modal-category__list {
          height: calc(90vh - 88px);
          margin: 0 0 8px;
        }
      `}</style>
    </ModalFrame>
  )
}

export default CategoryModal
