import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Params } from '~/common/api/base'
import { definedFilter } from '~/common/utils/array'

export const useRouteChanged = (onRouteChanged: () => void, checkIgnorePath?: string) => {
  const router = useRouter()
  const { pathname, query } = router

  useEffect(() => {
    if (!checkIgnorePath || (checkIgnorePath && !pathname.includes(checkIgnorePath))) {
      onRouteChanged()
    }
  }, [pathname, query])
}

export const usePushRouterWithParams = () => {
  const router = useRouter()
  const { pathname, query } = router

  return (params: Params) => {
    router.push({
      pathname: pathname,
      query: definedFilter({ ...query, ...params })
    }, undefined, { scroll: false })
  }
}
