import InternalLink from '~/components/utils/link/InternalLink'
import ExternalLink from '~/components/utils/link/ExternalLink'

interface Props {
  link: string
  text: string
  external?: boolean
  // TODO: temporally for markt_web page
  noInternal?: boolean
}

const SimpleLinkItem = ({ link, text, external = false, noInternal = false }: Props) => {
  const Tag = external ? ExternalLink : InternalLink
  return (
    <li className={'c-link-simple-item'}>
      {noInternal ?
        <a href={link} className={'c-link-simple-item__link'}>
          {text}
        </a> :
        <Tag link={link} className={'c-link-simple-item__link'}>
          {text}
        </Tag>
      }
    </li>
  )
}

export default SimpleLinkItem
