import Image from 'next/image'

import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import { convertToPrice } from '~/common/utils/price'
import { UsageLimitItem } from '~/common/app/coupon'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import Text from '~/components/atoms/text/Text'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'

interface Props {
  name: string
  addPointRate?: number
  discountPrice?: number
  usageLimitItems: UsageLimitItem[]
  expireDate: string
  themeColor: string
  only?: boolean
  used?: boolean
  isUsageLimitNoteRequired: boolean
  onClick(): void,
}

const LargeCouponCard = ({
  name,
  addPointRate,
  discountPrice,
  usageLimitItems,
  expireDate,
  themeColor,
  only = false,
  used = false,
  isUsageLimitNoteRequired = false,
  onClick
}: Props) => {

  return (
    <>
      <li className={'p-coupon-card-large' + addClass('p-coupon-card-large--only', only)}>
        {used && (
          <div className='p-coupon-card-large__used'>
            <Text color={Color.INVERSION} px={12} bold>
              利用済み
            </Text>
          </div>
        )}
        <div className='p-coupon-card-large__in'>
          {only && (
            <div className='p-coupon-card-large__only'>
              <span>
                <Image src={'/assets/images/ribbon_yellow.png'} alt='' width='10' height='20' />
              </span>
              <Text color={Color.PRIMARY} px={12} bold tag={'p'}>
                本クーポンが表示されたお客様限定！
              </Text>
              <span>
                <Image src={'/assets/images/ribbon_yellow.png'} alt='' width='10' height='20' />
              </span>
            </div>
          )}
          <Segments
            spColumn={SegmentsType.COLUMN}
            pcColumn={SegmentsType.COLUMN}
            spAlignCenter
            pcAlignCenter
          >
            <Text color={Color.INVERSION} px={16} tag={'p'} bold center>
              {name}
            </Text>
            <div className='p-coupon-card-large__privilege'>
              {addPointRate && (
                <Text color={Color.INVERSION} px={20} tag={'p'} bold>
                  ポイント
                  <Text color={Color.INVERSION} px={40} bold>
                    {convertToPrice(addPointRate)}
                  </Text>
                  倍
                </Text>
              )}
              {discountPrice && (
                <Text color={Color.INVERSION} px={20} tag={'p'} bold>
                  <Text color={Color.INVERSION} px={40} bold>
                    {convertToPrice(discountPrice)}
                  </Text>
                  円OFF
                </Text>
              )}
            </div>
            {!used && (
              <div className='p-coupon-card-large__button'>
                <Button
                  size={ButtonSize.LARGE}
                  role={ButtonRole.LOW_PRIORITY}
                  lineClose
                  onClick={onClick}
                >
                  対象商品を見る
                </Button>
              </div>
            )}
            <div
              className={
                'p-coupon-card-large__conditions' +
                addClass('p-coupon-card-large__conditions--used', used)
              }
            >
              <Segments spColumn={SegmentsType.COLUMN} pcColumn={SegmentsType.COLUMN} spGap={8}>
                {usageLimitItems.map((usageLimitItem, index) => {
                  return (
                    <Segments key={index}>
                      <Text color={Color.PRIMARY} px={10} bold>
                        {usageLimitItem.name}
                      </Text>
                      <Text color={Color.PRIMARY} px={10}>
                        {usageLimitItem.contents}
                      </Text>
                    </Segments>
                  )
                })}
                {isUsageLimitNoteRequired && (
                  <Text color={Color.PRIMARY} px={10} tag={'p'}>
                    ※対象店舗・対象カテゴリの商品が一つ以上注文に含まれていればご利用できます。
                  </Text>
                )}
              </Segments>
            </div>
          </Segments>
        </div>
        <div
          className={
            'p-coupon-card-large__expiry' + addClass('p-coupon-card-large__expiry--used', used)
          }
        >
          <span className='p-coupon-card-large__expiry__text'>
            <Text color={Color.PRIMARY} px={10}>
              有効期限：
            </Text>
            <Text color={Color.PRIMARY} px={12} bold>
              {expireDate}
            </Text>
          </span>
        </div>
      </li>
      <style jsx>{`
        .p-coupon-card-large__in,
        .p-coupon-card-large__expiry:before {
          background-color: ${used ? '#aaa' : themeColor};
        }
      `}</style>
    </>
  )
}

export default LargeCouponCard
