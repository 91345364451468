import React, { useRef, useEffect } from 'react'
import { animated, useSpring } from '@react-spring/web'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  show: boolean
}

const SlideToggle = ({ show, children }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [style, animate] = useSpring(() => ({ height: '0px' }))

  useEffect(() => {
    animate({
      height: (show ? ref.current?.offsetHeight : 0) + 'px'
    })
  }, [animate, ref, show])

  return (
    <>
      <animated.div style={{ overflow: 'hidden', ...style }}>
        <div ref={ref}>{children}</div>
      </animated.div>
    </>
  )
}

export default SlideToggle
