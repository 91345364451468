import { FormWidgetError, FormWidgetErrors } from '~/common/form/error'
import { Validations } from '~/common/form/validation'

export interface MedicineType {
  age: number
  other_shop: number
  continuation: number
  understand: number
}

export interface MedicineTypeError {
  age: FormWidgetError[]
  other_shop: FormWidgetError[]
  continuation: FormWidgetError[]
  understand: FormWidgetError[]
}

export const initialData: MedicineType = {
  age: 1,
  other_shop: 1,
  continuation: 1,
  understand: 1
}

export const medicineValidation: Validations = {
  age: [FormWidgetError.IS_NOT_TRUE],
  other_shop: [FormWidgetError.IS_NOT_FALSE],
  continuation: [FormWidgetError.IS_NOT_FALSE],
  understand: [FormWidgetError.IS_NOT_TRUE]
}

export const medicineChoice = [
  { name: 'はい', value: 1 },
  { name: 'いいえ', value: 0 }
];