import React from 'react'

import { CouponTagContents } from '~/common/app/coupon'
import { myPageCouponPath } from '~/common/app/path'
import { Color } from '~/common/utils/color'
import Tag, { TagType } from '~/components/atoms/tag/Tag'
import LinkText from '~/components/atoms/text/LinkText'

interface Props {
  couponTagContents?: CouponTagContents
}

const CouponTagList = ({ couponTagContents }: Props) => {

  return (
    <div className='coupon-tag-list'>
      <div className='tag-list'>
        {couponTagContents?.coupon_tags?.map((couponTag, index) => (
          <Tag key={index} tagType={TagType.NORMAL} color={'#' + couponTag.color}>
            {couponTag.content}
          </Tag>
        ))}
      </div>
      {couponTagContents && couponTagContents.other_coupon_contents && (
        <div className='other-coupon-contents'>
          <LinkText px={10} link={myPageCouponPath()} arrow={Color.LINK} noInternal>
            {couponTagContents.other_coupon_contents}
          </LinkText>
        </div>
      )}
      <style jsx>{`
        .coupon-tag-list {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
        }

        .tag-list {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 4px;
        }

        .tag-list > :global(*) {
          margin-right: 4px;
          margin-top: 4px;
        }

        .other-coupon-contents {
          margin-left: auto;
        }
      `}</style>
    </div>
  )
}

export default CouponTagList