import React from 'react'

import { ShopDetail } from '~/model/shop'
import { useShowModal } from '~/common/app/store/modal'
import { lineToBreak } from '~/common/utils/string'
import { convertMedicineRiskIdsToStr } from '~/common/app/shop'
import { savedImagePath } from '~/common/app/path'
import { STYLE_MEDIA_PC } from '~/common/app/style'
import FooterCenter from '~/components/layouts/footer/FooterCenter'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import AreaFooter from '~/components/layouts/area/AreaFooter'
import InfoRow from '~/components/molecules/info/InfoRow'
import ImageFitContain from '~/components/utils/image/ImageFitContain'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import StripeArea from '~/components/layouts/area/StripeArea'
import { Modal } from '~/components/app/modal/AppModal'
import { ModalImageProps } from '~/components/app/modal/ImageModal'
import MarginTop from '~/components/utils/margin/MarginTop'

interface Props {
  shop: ShopDetail
}

const ShopMedicineInfo = ({ shop }: Props) => {
  const showModal = useShowModal()

  const onClickShiftImage = () => {
    shop.medicine_seller_info && showModal({
      type: Modal.IMAGE,
      childProps: {
        images: [savedImagePath(shop.medicine_seller_info.display_register_info_image_file)],
        firstIndex: 0,
        alt: ''
      } as ModalImageProps
    })
  }

  return (
    <>
      {shop.medicine_seller_info &&
        <FooterCenter>
          <AreaFooter>
            <div>
              <BlockTitle verticalBar iconColor={'#' + shop.theme_color} margin={8}>
                医薬品の管理及び販売に関する事項
              </BlockTitle>
              <StripeArea>
                <InfoRow title={'（１）実店舗の写真'} wide>
                  <Segments spColumn={SegmentsType.COLUMN} pcColumn={SegmentsType.ROW} spGap={8}
                            fitWidth>
                    <div>
                      <ImageFitContain src={savedImagePath(shop.shop_image_file)}
                                       alt={shop.baseInfo.name + '実店舗写真'} />
                    </div>
                    <div>
                      <ImageFitContain
                        src={savedImagePath(shop.medicine_seller_info.display_rack_image_file)}
                        alt={shop.baseInfo.name + 'ラック写真'} />
                    </div>
                    <style jsx>{`
                      div {
                        height: 160px;
                        width: 100%;
                      }

                      @media ${STYLE_MEDIA_PC} {
                        div {
                          width: 50%;
                        }
                      }
                    `}</style>
                  </Segments>
                </InfoRow>
                <InfoRow title={'（２）許可区分'} wide>
                  {shop.medicine_seller_info.permission}
                </InfoRow>
                <InfoRow title={'（３）薬局又は店舗販売業の許可証の記載事項'} wide>
                  {lineToBreak(shop.medicine_seller_info.permission_detail)}
                </InfoRow>
                <InfoRow title={'（４）管理者氏名'} wide>
                  {shop.medicine_seller_info.shop_manager_name}
                </InfoRow>
                <InfoRow title={'（５）勤務する薬剤師/登録販売者の氏名、担当業務、シフト等'} wide>
                  {shop.medicine_seller_info.display_register_info_image_file ?
                    <div onClick={onClickShiftImage}>
                      <ImageFitContain
                        src={savedImagePath(shop.medicine_seller_info.display_register_info_image_file)}
                        alt={'薬剤師/登録販売者シフト'} />
                    </div> :
                    <>{lineToBreak(shop.medicine_seller_info.registered_seller_info)}</>
                  }
                  <style jsx>{`
                    div {
                      height: 200px;
                      width: 100%;
                      transition: opacity 0.3s;
                    }

                    div:hover {
                      opacity: .6;
                    }
                  `}</style>
                </InfoRow>
                <InfoRow title={'（６）勤務者の名札等による区別に関する説明'} wide>
                  ＜店舗での販売＞<br />
                  {lineToBreak(shop.medicine_seller_info.seller_name_tag_info)}
                  <br /><br />
                  ＜本サイトでの販売＞<br />
                  {convertMedicineRiskIdsToStr(shop.medicine_seller_info.medicine_risk_ids)}
                </InfoRow>
                <InfoRow title={'（７）取り扱う一般医薬品の区分'} wide>
                  {shop.medicine_seller_info.real_shop_medicine_risks}
                </InfoRow>
                <InfoRow title={'（８）営業時間'} wide>
                  <li>店舗営業時間：{shop.medicine_seller_info.contact_hour}</li>
                  <br />
                  <li>ネット販売時間：{shop.medicine_seller_info.online_business_hour}</li>
                  <br />
                  <li>サイト注文受付時間：24時間</li>
                </InfoRow>
                <InfoRow title={'（９）相談先/緊急連絡先'} wide>
                  <li>ご相談・問い合わせ先：{lineToBreak(shop.medicine_seller_info.shop_manager_name)}</li>
                  <br />
                  <li>時間外の問い合わせ先：{lineToBreak(shop.medicine_seller_info.after_hours_inquiries)}</li>
                </InfoRow>
              </StripeArea>
            </div>
            <MarginTop marginSp={16}>
              <BlockTitle verticalBar iconColor={'#' + shop.theme_color} margin={8}>
                要指導医薬品および一般用医薬品の販売に関する制度に関する事項
              </BlockTitle>
              <StripeArea>
                <InfoRow title={'（１）要指導・一般医薬品の定義及び解説'} wide>
                  <ul>
                    <li>１）要指導医薬品<br />
                      ・副作用、相互作用などの項目で安全性上、特に注意を要するものとして特に薬剤師の対面による情報提供及び指導が必要なもの<br />
                      一般用医薬品はリスク別に第1類から第3類に分類されています。
                    </li>
                    <li>２）第１類医薬品<br />
                      ・一般用医薬品としての使用経験が少ないものや副作用、相互作用などの項目で安全性上、特に注意を要するもの
                    </li>
                    <li>３）第2類医薬品<br />
                      ・副作用、相互作用などの項目で安全性上、注意を要するもの<br />
                    </li>
                    <li>指定第2類医薬品 <br />
                      ・第2類医薬品のうち、特に注意を要するもの<br />
                    </li>
                    <li>４）第3類医薬品<br />
                      ・副作用、相互作用などの項目で安全性上、多少注意を要するもの<br />
                    </li>
                  </ul>
                </InfoRow>
                <InfoRow title={'（２）要指導・一般医薬品のパッケージおよび添付文書の表記に関して'} wide>
                  <ul>
                    <li>直接の容器又は直接の被包に次のとおり表⽰されています。この表⽰が⾒えない場合は、外部の容器又は外部の被包にも併せて表示されています</li>
                    <li>要指導医薬品は『要指導医薬品』の⽂字を記載し、枠で囲みます</li>
                    <li>第1類医薬品は『第1類医薬品』の⽂字を記載し、枠で囲みます</li>
                    <li>第2類医薬品は『第2類医薬品』の⽂字を記載し、枠で囲みます</li>
                    <li>指定第2類医薬品は『第2類医薬品』の⽂字を記載し、枠で囲み、さらに『2』の⽂字を〇又は□で囲みます</li>
                    <li>第3類医薬品は『第3類医薬品』の⽂字を記載し、枠で囲みます</li>
                  </ul>
                </InfoRow>
                <InfoRow title={'（３）一般用医薬品の販売サイト上の表示の解説'} wide>
                  <ul>
                    <li>第１類医薬品は商品名に【第１類医薬品】と表示します ※当店では取扱いません。</li>
                    <li>指定第2類医薬品は商品名に【指定第2類医薬品】と表示します</li>
                    <li>第2類医薬品は商品名に【第2類医薬品】と表示します</li>
                    <li>第3類医薬品は商品名に【第3類医薬品】と表示します</li>
                  </ul>
                </InfoRow>
                <InfoRow title={'（４）医薬品の情報提供及び指導に関する解説 '} wide>
                  <ul>
                    <li>要指導医薬品・第１類医薬品 - 薬剤師が情報提供文書を用いて情報提供します（義務）</li>
                    <li>指定第2類医薬品・第2類医薬品 - 薬剤師・登録販売者が情報提供に努めます（努力義務）
                    </li>
                    <li>特に指定第2類医薬品は、外箱等に表示されている「次の人は服用しないでください」を確認し、薬剤師又は登録販売者に相談の上、ご購入ください</li>
                    <li>第3類医薬品 - 求めに応じて医薬品の専門家の情報提供を受けられます</li>
                  </ul>
                </InfoRow>
                <InfoRow
                  title={'（５）指定第２類の販売サイト上の表示等の解説及び禁忌の確認・専門家への相談を促す表示'}
                  wide>
                  <ul>
                    <li>商品名に【指定第2類医薬品】と記載します</li>
                    <li>医薬品購入にあたっての確認事項に、禁忌（してはいけないこと）の確認・
                      薬剤師又は登録販売者への相談を促す表示を記載します
                    </li>
                  </ul>
                </InfoRow>
                <InfoRow title={'（６）要指導医薬品・一般用医薬品の陳列の解説'} wide>
                  <ul>
                    <li>＜店舗の陳列＞<br />
                      {lineToBreak(shop.medicine_seller_info.display_rack_info)}
                    </li>
                    <br />
                    <li>＜本サイトの陳列＞<br />
                      ・一般用医薬品のリスク分類別に指定第２類医薬品、第２類医薬品、第３類医薬品の順に陳列しています<br />
                      ※第1類医薬品、要指導医薬品は取り扱っておりません。
                    </li>
                  </ul>
                </InfoRow>
                <InfoRow title={'（７）医薬品による健康被害の救済（副作用被害救済制度）'} wide>
                  <ul>
                    <li>万一、医薬品による健康被害の受けた方は、『医薬品副作用救済制度』が受けられます<br />
                      ・窓口 ：独立行政法人 医薬品医療機器総合機構<br />
                      ・連絡先電話 ：0120－149－931（フリーダイヤル）<br />
                      ・受付時間 ：月～金曜日（祝日・年末年始を除く）午前9時から午後5時まで<br />
                      ・ホームページ：http://www.pmda.go.jp/kenkouhigai.html
                    </li>
                  </ul>
                </InfoRow>
                <InfoRow title={'（８）販売記録作成に当たっての個人情報利用目的'} wide>
                  <ul>
                    <li>
                      当店では販売記録作成にあたり、お客様からお預かりいたしました個人情報は、ご本人の同意を得ることなく、その目的の範囲を超えての個人情報の取り扱いを行う（「目的外利用」）ことはありません。<br />
                      利用目的は以下のとおりです。<br />
                      ・お買い上げいただいた商品（医薬品）に使用上不都合（副作用等）が生じるような場合の連絡<br />
                      ・警察署・保健所から個人情報の開示指示があった場合
                    </li>
                  </ul>
                </InfoRow>
                <InfoRow title={'（９）その他'} wide>
                  <ul>
                    <li>その他<br />{lineToBreak(shop.medicine_seller_info.other_require_info)}</li>
                  </ul>
                </InfoRow>
              </StripeArea>
            </MarginTop>
          </AreaFooter>
        </FooterCenter>
      }
    </>
  )
}

export default ShopMedicineInfo
