import React, { useCallback, useImperativeHandle, useRef } from 'react'

import { Validations } from '~/common/form/validation'
import { Type } from '~/common/form/type'
import { FormError, FormWidgetErrors } from '~/common/form/error'
import { ChildProps } from '~/common/utils/props'
import { handleFormSubmit } from '~/common/form/handle'

interface Props extends ChildProps {
  formData: Type

  call(type: Type): void

  validations: Validations

  setError(formError: FormError<FormWidgetErrors>): void

  formRef?: React.MutableRefObject<any>
}

const FormBase = ({ children, formData, call, validations, setError, formRef }: Props) => {
  const handleSubmit = useCallback(
    (e: React.MouseEvent<HTMLFormElement>) => {
      handleFormSubmit(formData, validations, call, setError, e)
    },
    [call, formData, setError, validations]
  )

  useImperativeHandle(formRef, () => ({
    submit: (e: React.MouseEvent<HTMLFormElement>) => {
      handleSubmit(e)
    }
  }))

  return (
    <form onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

export default FormBase
