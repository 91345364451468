import Coupon, { CouponTag, CouponTagApplicationInfo } from '~/model/coupon'
import Product from '~/model/product'
import { CartItem } from '~/model/cart'

import { CouponListResponse } from '~/common/api/coupon'
import { BASIC_POINT_RATE, } from '~/common/api/user'
import { productListPath } from '~/common/app/path'
import { currentPriceIncTax } from '~/common/app/product'
import { convertToPrice } from '~/common/utils/price'

const COUPON_TAGS_MAX = 2

export type CouponTagContents = {
  coupon_tags?: CouponTag[]
  other_coupon_contents?: string
}

export type UsageLimitItem = {
  name: string
  contents: string
}

export const CouponCardType = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
} as const
export type CouponCardType = (typeof CouponCardType)[keyof typeof CouponCardType]

export const extractCouponUsageLimitItems = (coupon: Coupon, withDetailUsageLimits: boolean = true) => {
  const shopContents =
    coupon.shops && coupon.shops.length > 0 ? coupon.shops.map((shop) => shop.baseInfo.name).join(', ') : '指定なし'

  const categoryContents =
    coupon.categories && coupon.categories.length > 0
      ? coupon.categories.map((category) => category.name).join(', ') + 'の商品'
      : '指定なし'

  const items = [
    {
      name: '対象店舗',
      contents: shopContents
    },
    {
      name: '対象\nカテゴリ',
      contents: categoryContents
    }
  ]

  if (withDetailUsageLimits) {
    const usageConditions = []

    coupon.is_first_use && usageConditions.push('初回のご注文限定')

    coupon.issued_unlimited === false &&
    usageConditions.push(`先着${coupon.issued}名様`)

    coupon.min_price && usageConditions.push(`${coupon.min_price}円以上のご注文`)

    coupon.customer_available
      ? usageConditions.push(`おひとり様${coupon.customer_available}回まで`)
      : usageConditions.push(`利用回数制限なし`)

    coupon.is_purchased_product_only && usageConditions.push('過去購入した商品限定')

    const usageConditionsContents = usageConditions.join(' / ')

    items.push({
      name: 'ご利用\n条件',
      contents: usageConditionsContents
    })
  }

  return items
}

export const isUsageLimitNoteRequired = (coupon: Coupon): boolean => {
  return coupon.shops !== undefined && coupon.shops.length > 0 &&
    coupon.categories !== undefined && coupon.categories.length > 0
}

export const makeCouponProductListPathQuery = (coupon: Coupon): string => {
  return productListPath({coupon_id: coupon.id, categories: coupon.categories, shops: coupon.shops})
}

export const extractPointAndCouponForProductCard = (product: Product, baseRate: number, couponTagInfos: CouponTagApplicationInfo[] | undefined):
  { maxPoint: string | undefined, couponTagContents: CouponTagContents } => {
  const basePoint = getBasePoint(product)

  const { couponTags, maxCouponPointRate, enableCouponCount } = extractCouponTagsToProductCard(couponTagInfos || [], product, baseRate)

  const otherCouponContents =
    (couponTags.length == 0 && enableCouponCount > 0) ? `${enableCouponCount}枚`
      : couponTags.length < enableCouponCount ? `その他${enableCouponCount- couponTags.length}枚`
        : undefined

  const couponTagContents: CouponTagContents = {
    coupon_tags: couponTags,
    other_coupon_contents: otherCouponContents
  }

  const maxPoint = basePoint * (baseRate + maxCouponPointRate)
  const formattedPoint = formatPoint(maxPoint, maxCouponPointRate > 0)

  return {
    maxPoint: formattedPoint,
    couponTagContents: couponTagContents
  }
}

const extractCouponTagsToProductCard = (couponTagInfos: CouponTagApplicationInfo[], product: Product, baseRate: number) => {
  let maxCouponPointRate = 0
  let enableCouponCount = 0
  const couponTags: CouponTag[] = []

  couponTagInfos?.forEach((couponTagInfo) => {
    if (isCouponApplicableToProduct(couponTagInfo, product)) {
      if (couponTagInfo['add_point_rate'] !== undefined) {
        if (couponTags.length < COUPON_TAGS_MAX) {
          couponTags.push({
            content: `P${Number(couponTagInfo['add_point_rate']) + baseRate}倍`,
            color: couponTagInfo.theme_color,
          })
        }
        maxCouponPointRate = Math.max(maxCouponPointRate, Number(couponTagInfo['add_point_rate']))
      }
      enableCouponCount++
    }
  })

  return { couponTags, maxCouponPointRate, enableCouponCount }
}

export const attachPointAndCouponToCartItem = (cartItem: CartItem, baseRate: number, couponTagInfos: CouponTagApplicationInfo[] | undefined):
  { cartItem: CartItem; maxPoint: number; hasPointCoupon: boolean } => {
  const product = cartItem.productBaseInfo
  const basePoint = getBasePoint(product)

  const { couponTags, maxCouponPointRate } = extractCouponTagsToCartItem(couponTagInfos || [], product, baseRate)

  const couponTagContents: CouponTagContents = {
    coupon_tags: couponTags,
  }

  const maxPoint = basePoint * (baseRate + maxCouponPointRate) * cartItem.quantity
  const hasPointCoupon = maxCouponPointRate > 0
  const formattedPoint = formatPoint(maxPoint, hasPointCoupon)

  cartItem.productBaseInfo = {
    ...product,
    add_point: formattedPoint,
    coupon_tag_contents: couponTagContents,
  }

  return {cartItem, maxPoint, hasPointCoupon}
}

const extractCouponTagsToCartItem = (couponTagInfos: CouponTagApplicationInfo[], product: Product, baseRate: number) => {
  let maxCouponPointRate = 0
  const couponTags: CouponTag[] = []

  couponTagInfos?.forEach((couponTagInfo) => {
    if (isCouponApplicableToProduct(couponTagInfo, product)) {
      if (couponTagInfo['add_point_rate'] !== undefined) {
        couponTags.push({
          content: `P${Number(couponTagInfo['add_point_rate']) + baseRate}倍`,
          color: couponTagInfo.theme_color,
        })
        maxCouponPointRate = Math.max(maxCouponPointRate, Number(couponTagInfo['add_point_rate']))
      } else if (couponTagInfo['discount_price'] !== undefined) {
        couponTags.push({
          content: `${couponTagInfo['discount_price']}円引`,
          color: couponTagInfo.theme_color,
        })
      }
    }
  })

  return { couponTags, maxCouponPointRate }
}

// 商品に適用できるか判定するためのパラメーターのハッシュマップと、タグ表示内容に必要な情報を取得
export const extractCouponTagInfos = (couponsData: CouponListResponse | undefined): CouponTagApplicationInfo[] | undefined => {

  const coupons = couponsData?.coupons
  const purchaseProductIds = couponsData?.purchase_product_ids

  if (!coupons) {
    return undefined
  }

  return coupons.map((coupon) => {
    const categoryIdsMap = coupon.categories?.reduce<{ [key: number]: boolean }>((result, category) => {
      result[category.id] = true
      return result
    }, {})

    const shopIdsMap = coupon.shops?.reduce<{ [key: number]: boolean }>((result, shop) => {
      result[shop.baseInfo.id] = true
      return result
    }, {})

    const purchaseProductIdsMap = coupon.is_purchased_product_only ? purchaseProductIds?.reduce<{ [key: number]: boolean }>((result, productId) => {
      result[productId] = true
      return result
    }, {}) : undefined

    const couponTagInfo: CouponTagApplicationInfo = {
      add_point_rate: coupon.add_point_rate,
      discount_price: coupon.discount_price,
      theme_color: coupon.theme_color,
    }

    if (categoryIdsMap !== undefined && Object.keys(categoryIdsMap).length > 0) {
      couponTagInfo.category_ids = categoryIdsMap
    }

    if (shopIdsMap !== undefined && Object.keys(shopIdsMap).length > 0) {
      couponTagInfo.shop_ids = shopIdsMap
    }

    if (purchaseProductIdsMap !== undefined && Object.keys(purchaseProductIdsMap).length > 0) {
      couponTagInfo.purchase_product_ids = purchaseProductIdsMap
    }

    return couponTagInfo
  })
}

// クーポンの適用条件をチェック
const isCouponApplicableToProduct = (couponTagInfo: CouponTagApplicationInfo, product: Product): boolean => {
  if (couponTagInfo.purchase_product_ids !== undefined && couponTagInfo.purchase_product_ids?.[product.id] === undefined) {
    return false
  }

  if (couponTagInfo.shop_ids !== undefined && couponTagInfo.shop_ids?.[product.shop.id] === undefined) {
    return false
  }

  if (couponTagInfo.category_ids !== undefined) {
    const categoryIds = product.category_ids ?? []
    const categoryIdsMap = couponTagInfo.category_ids
    const hasMatchingCategory = categoryIds.some((categoryId) => categoryId in categoryIdsMap)

    if (!hasMatchingCategory) {
      return false
    }
  }

  return true
}

const formatPoint = (point: number, hasPointCoupon: boolean): string | undefined => {
  return point > 0 && hasPointCoupon ? `最大${convertToPrice(point)}P` :
    point > 0 ? `${convertToPrice(point)}P` :
      undefined
}

const getBasePoint = (product: Product): number => {
  const price = currentPriceIncTax(product)
  return Math.round(price / 100)
}

export const calculateAddPointRate = (coupon: Coupon) => {

  return coupon.add_point_rate ? Number(coupon.add_point_rate) + BASIC_POINT_RATE : undefined
}


