import React, { useEffect, useRef } from 'react'

export const useOutsideClick = (outSideClick: (e: MouseEvent) =>  void, active = true) => {
  const ref = useRef(null)

  const handleClickOutside = (e: MouseEvent) => {
      const current: any = ref.current
      if (active && current && !current.contains(e.target)) {
          outSideClick(e)
      }
  }

  useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside)
      return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [ref])

  return ref
}