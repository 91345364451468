import useSWR from 'swr'

import Shop, { AcceptableTime, ShopDetail } from '~/model/shop'
import { ApiPath, fetchApi } from '~/common/api/base'
import { usePathKey } from '~/common/app/path'
import { definedFilter } from '~/common/utils/array'
import { useArea } from '~/common/api/area'
import { useUserMode } from '~/common/api/user'
import {
  AcceptableMessageType,
  nextAcceptableMessage
} from '~/common/app/delivery'

export interface ShopListResponse {
  shops: ShopDetail[]
}

export interface ShopDetailResponse {
  shopDetailInfo: ShopDetail
  deliveryTimes: AcceptableTime[]
  shopPickupTimes: AcceptableTime[]
}

export interface AcceptableNextTimeResponse {
  shop_id: number
  close_time: string
  acceptable_day_prefix: string
  acceptable_from: string
  acceptable_to: string
}

export const fetchShop = async (key: string) => {
  const { data } = await fetchApi<ShopDetailResponse>(ApiPath.SHOP, { key })
  return data
}

export const useShop = () => {
  const key = usePathKey()
  const { data } = useSWR<ShopDetailResponse>(key ? [ApiPath.SHOP, { key }] : null)
  return data?.shopDetailInfo
}

export const useShopInfo = () => {
  const key = usePathKey()
  const { data } = useSWR<ShopDetailResponse>(key ? [ApiPath.SHOP, { key }] : null)
  return data
}

export const fetchShopList = async (key: string | undefined = undefined) => {
  const { data } = await fetchApi<ShopListResponse>(ApiPath.SHOP_LIST, { key: key })
  return data?.shops ?? []
}

export const useShopList = (request: boolean = true, areaKey?: string) => {
  const pathKey = usePathKey()
  const key = areaKey || pathKey

  const { data } = useSWR<ShopListResponse>(request ? [ApiPath.SHOP_LIST, definedFilter({ key: key })] : null)
  return data?.shops ?? []
}

export const useAcceptableNextTimeApi = (key: string, pickupMode?: boolean) => {
  return useSWR<AcceptableNextTimeResponse[]>(pickupMode != undefined ? [
    ApiPath.ACCEPTABLE_NEXT_TIME,
    definedFilter({
      key,
      pickup_mode: pickupMode
    })
  ] : null)
}

export const useAcceptableNextTime = () => {
  const key = usePathKey()
  const mode = useUserMode()
  const { data } = useAcceptableNextTimeApi(key, mode)

  return data
}

export const useMakeShopNextAcceptableMessage = (type: AcceptableMessageType = AcceptableMessageType.SHORT) => {
  const mode = useUserMode()
  const acceptableNextTime = useAcceptableNextTime()

  return (shop?: Shop) => (shop && acceptableNextTime) ?
    nextAcceptableMessage(acceptableNextTime?.find((item) => item.shop_id == shop.id), mode, type) : undefined
}

export const useAreaShopListDeliverableNextTimeApi = () => {
  const area = useArea()

  return useSWR<AcceptableNextTimeResponse[]>([
    ApiPath.ACCEPTABLE_NEXT_TIME,
    definedFilter({
      key: area ? area.area_key : null,
      pickup_mode: false
    })
  ])
}
