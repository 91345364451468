import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { useState } from 'react'
import ModalFrame from '~/components/layouts/modal/ModalFrame'
import SliderModal from '~/components/molecules/modal/SliderModal'

export interface ModalImageProps {
  images: string[]
  firstIndex: number
  alt: string
}

interface Props extends ModalImageProps {
  onClickClose(): void
}

const ImageModal = ({ images, firstIndex, alt, onClickClose }: Props) => {
  const [index, setIndex] = useState<number>(firstIndex)
  const currentImage = images[index]

  return (
    <SliderModal index={index} total={images.length} setIndex={setIndex}>
      <ModalFrame onClickClose={onClickClose} center>
        <TransformWrapper>
          <TransformComponent>
            <img src={currentImage} alt={alt} />
          </TransformComponent>
        </TransformWrapper>
      </ModalFrame>
    </SliderModal>
  )
}

export default ImageModal
