import { useEffect, useState } from 'react'
import { useGetNotice, useWriteNotice } from '~/common/app/store/notice'
import ScreenMessage from '~/components/atoms/message/ScreenMessage'
import OpacityToggle from '~/components/utils/animation/OpacityToggle'
import { onceTimer, INTERVAL_MILI_NORMAL } from '~/common/utils/timer'

const AppNotice = () => {
  const [show, setShow] = useState<boolean>(false)
  const notice = useGetNotice()
  const write = useWriteNotice()

  useEffect(() => {
    if (notice && notice.length > 0) {
      setShow(true)
      onceTimer(() => {
        setShow(false)
        onceTimer(() => {
          !show && write('')
        }, INTERVAL_MILI_NORMAL / 2)
      }, INTERVAL_MILI_NORMAL)
    }
  }, [notice])

  return (
    <OpacityToggle show={show} onlyLeave>
      <ScreenMessage>{notice}</ScreenMessage>
    </OpacityToggle>
  )
}

export default AppNotice
