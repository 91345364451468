import React, { useEffect } from 'react'
import { animated, useSpringRef, useTransition } from '@react-spring/web'
import { ChildProps, ClassProps } from '~/common/utils/props'

interface Props extends ChildProps, ClassProps {
  show: boolean
  onlyLeave?: boolean
}

const OpacityToggle = ({ className = '', show, onlyLeave = false, children }: Props) => {
  const transRef = useSpringRef()
  const [transitions] = useTransition(show, () => ({
    ref: transRef,
    from: { opacity: onlyLeave ? 1 : 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  }))

  useEffect(() => {
    transRef.start()
  }, [show])

  return (
    <>
      {transitions((style, item) => {
        if (item) {
          return (
            <animated.div className={className} style={style}>
              {children}
            </animated.div>
          )
        }
        return null
      })}
    </>
  )
}

export default OpacityToggle
