import { Store, useStoreSWR } from '~/common/app/store/store'

export const useGetNotice = (): string => {
  const { data } = useStoreSWR<string, Error>(Store.NOTICE, '')
  return data ?? ''
}

export const useWriteNotice = () => {
  const { mutate } = useStoreSWR<string, Error>(Store.NOTICE)
  return mutate
}
