import { useRouter } from 'next/router'
import Area from '~/model/area'
import Shop, { ShopDetail } from '~/model/shop'
import News from '~/model/news'
import Product from '~/model/product'
import Category from '~/model/category'
import { isMatchedPrefix } from '~/common/utils/string'
import { useUserArea, useUserShop } from '~/common/api/user'
import { makeQueryShopIdValue } from '~/common/app/productSearch'

const SAVED_IMAGE_PATH = '/html/upload/save_image/'
const SAVED_MEDICINE_IMAGE_PATH = '/html/upload/save_medicine_file/'
const THUMB_IMAGE_PATH = '/html/upload/thumb_image/'
const NO_IMAGE_FILE = 'no_image_product.png'

export const usePathKey = () => {
  const { query } = useRouter()
  return (query.key as string) ?? null
}

export const usePathId = () => {
  const { query } = useRouter()
  return query.id ? Number(query.id) : undefined
}

export const useMoveKeyPath = () => {
  const router = useRouter()
  return (newKey: string) => router.push('/' + newKey)
}

const generateImagePath = (fileName: string | undefined, imagePath: string) => {
  return !fileName
    ? process.env.SSG_API_URL + imagePath + NO_IMAGE_FILE
    : isMatchedPrefix(fileName, 'https://')
    ? fileName
    : process.env.SSG_API_URL + imagePath + fileName
}

export const savedImagePath = (fileName: string | undefined) => {
  return generateImagePath(fileName, SAVED_IMAGE_PATH)
}

export const thumbImagePath = (fileName: string | undefined) => {
  return generateImagePath(fileName, THUMB_IMAGE_PATH)
}

export const savedMedicineImagePath = (fileName: string) => {
  return process.env.SSG_API_URL + SAVED_MEDICINE_IMAGE_PATH + fileName
}

export const useUserTopPath = () => {
  const userArea = useUserArea()
  const userShop = useUserShop()

  if (userShop) {
    return shopTopPath(userShop as Shop)
  }

  if (userArea) {
    return areaTopPath(userArea as Area)
  }

  return topPath()
}

export const topPath = () => {
  return '/'
}

export const areaTopPath = (area: Area) => {
  return '/' + area.area_key
}

export const shopTopPath = (shop: Shop) => {
  return '/' + shop.shop_key
}

/**
 * product page
 */
export const productListPath = ({
  category_id,
  key,
  word,
  collection_code,
  sale_product,
  pickup_product,
  coupon_id,
  categories,
  shops
}: {
  category_id?: number
  key?: string
  word?: string
  collection_code?: string
  sale_product?: boolean
  pickup_product?: boolean
  coupon_id?: number
  categories?: Category[]
  shops?: ShopDetail[]
} = {}) => {
  let path = '/products/list'
  let params = ''
  if (category_id) {
    params += 'category_id=' + category_id + '&'
  }
  if (word) {
    word = word.replace(/&/g, escape('&'))
    params += 'name=' + word + '&'
  }
  if (collection_code) {
    params += 'collection=' + collection_code + '&'
  }
  if (sale_product) {
    params += 'sale_product=1' + '&'
  }
  if (pickup_product) {
    params += 'pickup_product=1' + '&'
  }
  if (coupon_id) {
    params += 'coupon_id=' + coupon_id + '&'
  }
  if (categories) {
    categories.forEach((category) => {
      params += 'category_ids[]=' + category.id + '&'
    })
  }
  if (shops) {
    shops.forEach((shop) => {
      params += 'shop_ids[]=' + makeQueryShopIdValue(shop) + '&'
    })
  }
  if (key) {
    path = '/' + key + path
  }
  if (params.length > 0) {
    path += '?' + params.slice(0, -1)
  }
  return path
}

export const productDetailPath = (product: Product) => {
  return '/' + product.shop.shop_key + '/products/detail' + '/' + product.id
}

/**
 * other page
 */
export const cartPath = () => {
  return '/cart'
}

export const buyStepPath = (cartKey: string) => {
  return '/cart/buystep/' + cartKey
}

export const contactPath = (shop: Shop | undefined = undefined) => {
  return (shop ? '/' + shop.shop_key : '') + '/contact'
}

export const guidePath = () => {
  return '/guide'
}

export const entryPath = () => {
  return '/entry'
}

export const forgotPath = () => {
  return '/forgot'
}

export const logoutPath = () => {
  return '/logout'
}

export const newsPath = (news: News) => {
  return '/notification/notification-detail?id=' + news.id
}

/*
 * my page
 */
export const myPagePath = () => {
  return '/mypage/'
}

export const myPageLoginPath = () => {
  return '/mypage/login'
}

export const myPageHistoryPath = () => {
  return '/mypage/history'
}

export const myPageFavoritePath = () => {
  return '/mypage/favorite'
}

export const myPageSortFavoritePath = () => {
  return '/mypage/favorite/sort'
}

export const myPagePurchasedProductPath = () => {
  return '/mypage/purchased_product'
}

export const myPagePointLogPath = () => {
  return '/mypage/point_log'
}

export const myPageCouponPath = () => {
  return '/mypage/coupon'
}

/**
 * help page
 */
export const helpAboutPath = () => {
  return '/help/about'
}

export const helpAgreementPath = () => {
  return '/help/agreement'
}

export const helpTradeLawPath = (shop: Shop | undefined = undefined) => {
  return (shop ? '/' + shop.shop_key : '') + '/help/tradelaw'
}

export const helpDeliveryAreaPath = () => {
  return '/help/shop_delivery_area'
}

export const helpCouponPath = () => {
  return '/help/coupon'
}

/**
 * static page
 */
export const pointCampaignPath = () => {
  return '/user_data/weeklyuse_2202'
}
