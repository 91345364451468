import { STYLE_MEDIA_PC } from '~/common/app/style'
import { addClass } from '~/common/utils/html'
import { ChildProps, MouseProps } from '~/common/utils/props'

export const HeaderLineType = {
  SECONDARY: 'SECONDARY',
  INVERSION: 'INVERSION'
} as const

export type HeaderLineType = (typeof HeaderLineType)[keyof typeof HeaderLineType]

export const HeaderLineTypeClass = (lineColor: HeaderLineType): string => {
  switch (lineColor) {
    case HeaderLineType.INVERSION:
      return 'l-header-list--line-inversion'
    case HeaderLineType.SECONDARY:
      return 'l-header-list--line-secondary'
  }
}

interface Props extends ChildProps, MouseProps {
  spGap?: 4 | 8 | 16 | 24
  pcGap?: 4 | 8 | 16 | 24
  line?: boolean
  lineColor?: HeaderLineType
  positioning?: boolean
  flex?: boolean
}

const HeaderList = ({
  children,
  spGap = 8,
  pcGap,
  line = false,
  lineColor = HeaderLineType.INVERSION,
  positioning = false,
  flex = false,
  onMouseEnter,
  onMouseLeave
}: Props) => {
  const className =
    'l-header-list' +
    addClass('l-header-list--line', line) +
    addClass(HeaderLineTypeClass(lineColor)) +
    addClass('l-header-list--positioning', positioning) +
    addClass('l-header-list--flex', flex)
  return (
    <>
      <div className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
      </div>
      <style jsx>{`
        .l-header-list {
          column-gap: ${spGap}px;
        }
        @media ${STYLE_MEDIA_PC} {
          .l-header-list {
            column-gap: ${pcGap ?? spGap}px;
          }
        }
      `}</style>
    </>
  )
}

export default HeaderList
