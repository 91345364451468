import {
  TEXT_STRING_MAX_LENGTH,
  TEXTAREA_STRING_MAX_LENGTH
} from '~/common/form/parameter'
import {
  RequestError,
  errorMessage as reqErrorMessage,
  errorMessage
} from '~/common/app/requestError'
import { Type } from '~/common/form/type'
import { useHideLoading } from '~/common/app/store/loading'
import { useWriteNotice } from '~/common/app/store/notice'

export interface FormError<T extends FormWidgetErrors> {
  formWidgetErrors: T
  requestError?: RequestError
  message?: string
}

export enum FormWidgetError {
  IS_EMPTY = 1,
  NOT_UNIQUE,
  NOT_INTEGER,
  NOT_ALPHA_NUMERIC,
  NOT_ALPHA_NUMERIC_SYMBOL,
  NOT_INCLUDE_ALPHA_NUMERIC,
  NOT_EMAIL,
  NOT_POSTAL_CODE,
  NOT_DATE,
  OVER_TEXT_STRING_MAX,
  OVER_TEXTAREA_STRING_MAX,
  BELOW_PASSWORD_MIN_LENGTH,
  NOT_CHECKED,
  NOT_RE,
  WRONG_PASSWORD,
  IS_NOT_TRUE,
  IS_NOT_FALSE,
}

enum FormRequestError {
  UNIQUE = 'UNIQUE',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
}

export interface FormWidgetErrors {
  [index: string]: FormWidgetError[]
}

export interface FormRequestErrors {
  [index: string]: FormRequestError[]
}

export const formErrorExists = <T extends FormWidgetErrors>(error: FormError<T>): boolean => {
  if (error.requestError) {
    return true
  }
  for (let errorKey in error.formWidgetErrors) {
    if (error.formWidgetErrors[errorKey].length > 0) {
      return true
    }
  }
  return false
}

export const convertToFormError = <T extends FormWidgetErrors>(response: Response): FormError<T> => {
  let requestError = undefined
  if (response.status) {
    requestError = response.status
  }
  // TODO: reflect formWidgetError
  return { formWidgetErrors: {} as T, requestError: requestError }
}

const convertRequestErrorToFormError = (error: FormRequestError) => {
  switch (error) {
    case FormRequestError.UNIQUE:
      return FormWidgetError.NOT_UNIQUE
    case FormRequestError.WRONG_PASSWORD:
      return FormWidgetError.WRONG_PASSWORD
  }
}

const formWidgetErrorMessage = (label: string, error: FormWidgetError | string): string => {
  switch (error) {
    case FormWidgetError.IS_EMPTY:
      return label + 'を入力してください'
    case FormWidgetError.NOT_UNIQUE:
      return 'この' + label + 'はすでに登録されています。他の' + label + 'を入力してください。'
    case FormWidgetError.NOT_INTEGER:
      return '数値を入力してください'
    case FormWidgetError.NOT_ALPHA_NUMERIC:
      return '英数字を入力してください'
    case FormWidgetError.NOT_ALPHA_NUMERIC_SYMBOL:
      return '英数字記号のみ許可されています'
    case FormWidgetError.NOT_INCLUDE_ALPHA_NUMERIC:
      return '英数字が必須です'
    case FormWidgetError.NOT_EMAIL:
      return 'Eメールアドレスを入力してください'
    case FormWidgetError.NOT_DATE:
      return '年月日を入力してください'
    case FormWidgetError.OVER_TEXT_STRING_MAX:
      return label + 'は' + TEXT_STRING_MAX_LENGTH + '文字以下にしてください'
    case FormWidgetError.OVER_TEXTAREA_STRING_MAX:
      return label + 'は' + TEXTAREA_STRING_MAX_LENGTH + '文字以下にしてください'
    case FormWidgetError.NOT_CHECKED:
      return 'チェックされていません'
    case FormWidgetError.NOT_RE:
      return '同じ文字列を入力してください'
    case FormWidgetError.WRONG_PASSWORD:
      return 'パスワードが誤っています'
  }

  return '何らかのエラーが発生しています'
}

export const formWidgetErrorMessages = (name: string, errors: FormWidgetError[]): string[] => {
  return errors.map(error => {
    return formWidgetErrorMessage(name, error)
  })
}

export const requestErrorMessage = (error: FormError<Type>): string => {
  if (error.requestError) {
    return reqErrorMessage(error.requestError)
  }
  return reqErrorMessage(RequestError.INTERNAL_SERVER_ERROR)
}

export const useRequestErrored = () => {
  const hideLoading = useHideLoading()
  const writeNotice = useWriteNotice()
  return (error: Response) => {
    hideLoading()
    const formError = convertToFormError(error)
    formError.requestError && writeNotice(errorMessage(formError.requestError))
  }
}

export const hasErrors = (error: FormError<Type>): boolean => {
  return Object.values(error.formWidgetErrors).some(errorArray => errorArray.length > 0);
};
