import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

export const StatusType = {
  ROUND: '○',
  CROSS: '×',
  HYPHEN: '-'
} as const

type StatusType = (typeof StatusType)[keyof typeof StatusType]

export const StatusTypeClass = (type: StatusType): string => {
  switch (type) {
    case StatusType.ROUND:
      return 'c-status-status-description--tag-round'
    case StatusType.CROSS:
      return 'c-status-status-description--tag-cross'
    case StatusType.HYPHEN:
      return 'c-status-status-description--tag-hyphen'
  }
}

export const StatusDescriptionString = (type: StatusType): string => {
  switch (type) {
    case StatusType.ROUND:
      return '空きあり'
    case StatusType.CROSS:
      return '受付終了'
    case StatusType.HYPHEN:
      return '設定なし'
  }
}

interface Props {
  type: StatusType
}

const StatusDescription = ({ type }: Props) => {
  const className = 'c-status-status-description--type' + addClass(StatusTypeClass(type))
  return (
    <dl className='c-status-status-description'>
      <dt className={className}>{type}</dt>
      <dd className='c-status-status-description--text'>{StatusDescriptionString(type)}</dd>
    </dl>
  )
}

export default StatusDescription
