import Product from '~/model/product'

import { Color } from '~/common/utils/color'
import { useProductsWithCoupon } from '~/common/app/product'
import { useRelatedPvProductsListApi } from '~/common/api/product'
import ProductScrollList from '~/components/app/module/product/ProductScrollList'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import Content from '~/components/layouts/area/Content'
import Loading from '~/components/atoms/loading/Loading'
import MarginTop from '~/components/utils/margin/MarginTop'

interface Props {
  product: Product
  inModal?: boolean
}

const ProductRelatedPvContent = ({ products }: { products: Product[] }) => {
  return (
    <>
      <BlockTitle icon={IconType.PIN} iconColor={Color.SUPPORT_SUB}>
        この商品をみた人はこんな商品も見ています
      </BlockTitle>
      <ProductScrollList type={ProductCardType.SMALL} products={products} />
    </>
  )
}

const ProductRelatedPvList = ({ product, inModal = false }: Props) => {
  const { data: productList, isLoading } = useRelatedPvProductsListApi(product.id)
  const products = useProductsWithCoupon(productList)


  return (
    <>
      {products && products.length > 0 &&
        <>
          {inModal ?
            <MarginTop marginSp={24}>
              <ProductRelatedPvContent products={products} />
            </MarginTop> :
            <Content scroll>
              <ProductRelatedPvContent products={products} />
            </Content>
          }
        </>
      }
      {isLoading &&
        <Loading />
      }
    </>
  )
}

export default ProductRelatedPvList
