import React from 'react'

export const isString = (obj: any): boolean => {
  return typeof obj == 'string' || obj instanceof String
}

export const isMatchedPartial = (text: string, search: string): boolean => {
  return text.indexOf(search) >= 0
}

export const isMatchedPrefix = (text: string | undefined, search: string): boolean => {
  return text !== undefined && text !== null && text.indexOf(search) >= 0
}

export const isKanji = (keyword: string) => {
  const regexp = /([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu;
  return regexp.test(keyword)
}

export const lineToBreak = (message: string) => {
  return message ? message.split(/(\n)/).map((item, index) => {
    return (
      <React.Fragment key={index}>
        {item.match(/\n/) ? <br /> : item}
      </React.Fragment>
    )
  }) : ``
}

export const isDate = (text: string): boolean => {
  const date = new Date(text)
  return date instanceof Date && !isNaN(date.getTime())
}
