import { STYLE_MEDIA_PC } from '~/common/app/style'
import { ChildProps } from '~/common/utils/props'
interface Props extends ChildProps {
  marginSp?: 0 | 2 | 4 | 8 | 12 | 16 | 20 | 24 | 32 | 40
  marginPc?: 0 | 2 | 4 | 8 | 12 | 16 | 20 | 24 | 32 | 40
}

const MarginTop = ({ children, marginSp = 0, marginPc }: Props) => {
  return (
    <>
      <div className={'u-margin-top'}>{children}</div>
      <style jsx>{`
        .u-margin-top {
          width: 100%;
          margin-top: ${marginSp}px;
        }
        @media ${STYLE_MEDIA_PC} {
          .u-margin-top {
            margin-top: ${marginPc ?? marginSp}px;
          }
        }
      `}</style>
    </>
  )
}

export default MarginTop
