import { ChildProps } from '~/common/utils/props'

const TableFrame = ({ children }: ChildProps) => {
  return (
    <div className='c-table-frame'>
      <table className='c-table-frame__in'>{children}</table>
    </div>
  )
}

export default TableFrame
