import { STYLE_MEDIA_PC, STYLE_MEDIA_SP } from '~/common/app/style'
import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

export const SegmentsType = {
  ROW: 'ROW',
  COLUMN: 'COLUMN',
  ROW_REVERSE: 'ROW_REVERSE',
  COLUMN_REVERSE: 'COLUMN_REVERSE'
} as const

export type SegmentsType = (typeof SegmentsType)[keyof typeof SegmentsType]

export const spSegmentsType = (spColumn: SegmentsType): string => {
  switch (spColumn) {
    case SegmentsType.ROW:
      return 'row'
    case SegmentsType.COLUMN:
      return 'column'
    case SegmentsType.ROW_REVERSE:
      return 'row-reverse'
    case SegmentsType.COLUMN_REVERSE:
      return 'column-reverse'
  }
}
export const pcSegmentsType = (pcColumn: SegmentsType): string => {
  switch (pcColumn) {
    case SegmentsType.ROW:
      return 'row'
    case SegmentsType.COLUMN:
      return 'column'
    case SegmentsType.ROW_REVERSE:
      return 'row-reverse'
    case SegmentsType.COLUMN_REVERSE:
      return 'column-reverse'
  }
}

interface Props extends ChildProps {
  spGap?: 2 | 4 | 8 | 12 | 16 | 20 | 24 | 32
  spColumn?: SegmentsType
  pcGap?: 2 | 4 | 8 | 12 | 16 | 20 | 24 | 32 | 40
  pcColumn?: SegmentsType
  spAlignCenter?: boolean
  pcAlignCenter?: boolean
  spAlignEnd?: boolean
  pcAlignEnd?: boolean
  spCenter?: boolean
  pcCenter?: boolean
  spBetween?: boolean
  pcBetween?: boolean
  fitWidth?: boolean
  fitHeight?: boolean
  flex?: boolean
  flexMin?: boolean
  spEnd?: boolean
  pcEnd?: boolean
}

const Segments = ({
  children,
  spGap,
  pcGap,
  spColumn = SegmentsType.ROW,
  pcColumn = SegmentsType.ROW,
  spAlignCenter = false,
  pcAlignCenter = false,
  spAlignEnd = false,
  pcAlignEnd = false,
  spCenter = false,
  pcCenter = false,
  spBetween = false,
  pcBetween = false,
  fitWidth = false,
  fitHeight = false,
  flex = false,
  flexMin = false,
  spEnd = false,
  pcEnd = false
}: Props) => {
  return (
    <>
      <div
        className={
          'l-area-segments' +
          addClass('l-area-segments--fit-width', fitWidth) +
          addClass('l-area-segments--fit-height', fitHeight) +
          addClass('l-area-segments--flex', flex) +
          addClass('l-area-segments--flex-min', flexMin)
        }
      >
        {children}
      </div>
      <style jsx>{`
        .l-area-segments {
          display: flex;
          justify-content: flex-start;
          gap: ${spGap}px;
        }

        @media ${STYLE_MEDIA_SP} {
          .l-area-segments {
            flex-direction: ${spSegmentsType(spColumn)};
            align-items: ${spAlignCenter ? 'center' : spAlignEnd ? 'flex-end' : 'flex-start'};
            justify-content: ${spCenter
              ? 'center'
              : spBetween
              ? 'space-between'
              : spEnd
              ? 'flex-end'
              : 'flex-start'};
          }
        }

        @media ${STYLE_MEDIA_PC} {
          .l-area-segments {
            gap: ${pcGap ?? spGap}px;
            flex-direction: ${pcSegmentsType(pcColumn)};
            align-items: ${pcAlignCenter ? 'center' : pcAlignEnd ? 'flex-end' : 'flex-start'};
            justify-content: ${pcCenter
              ? 'center'
              : pcBetween
              ? 'space-between'
              : pcEnd
              ? 'flex-end'
              : 'flex-start'};
          }
        }
      `}</style>
    </>
  )
}

export default Segments
