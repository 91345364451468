import { useCategoryList } from '~/common/api/category'
import { productListPath, savedImagePath, usePathKey } from '~/common/app/path'
import NavList from '~/components/molecules/nav/NavList'
import NavRow from '~/components/molecules/nav/NavRow'
import NavListChild from '~/components/molecules/nav/NavListChild'
import NavRowChild from '~/components/molecules/nav/NavRowChild'

interface Props {
  showChild?: boolean
  scroll?: boolean
}

const CategoryNavList = ({ showChild = false, scroll = false }: Props) => {
  const key = usePathKey()
  const categories = useCategoryList()
  return (
    <NavList scroll={scroll}>
      {categories?.map((category) =>
        <NavRow key={category.id} src={savedImagePath(category.image_file)} alt={category.name}
                link={productListPath({ category_id: category.id, key })} text={category.name}>
          {showChild && category.children && category.children.length > 0 &&
            <NavListChild>
              {category.children.map((child) =>
                <NavRowChild key={child.id} link={productListPath({ category_id: child.id, key })}
                             text={child.name} />
              )}
            </NavListChild>
          }
        </NavRow>
      )}
    </NavList>
  )
}

export default CategoryNavList
