import React from 'react'
import FormTextBoxCommon, { FormTextBoxProps } from '~/components/app/form/widgets/FormTextBoxCommon'

const FormTextBox = (textBoxProps: FormTextBoxProps) => {
  return (
    <FormTextBoxCommon
      {...textBoxProps}
    />
  )
}

export default FormTextBox