import { STYLE_MEDIA_SP } from '~/common/app/style'
import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import Text from '~/components/atoms/text/Text'

export type IconTextPx = 8 | 10 | 12 | 14 | 16 | 20 | 22 | 28

interface Props extends ChildProps {
  iconColor: Color
  textColor?: Color
  iconSize: number
  textPx: IconTextPx
  iconType: IconType
  textBold?: boolean
  textVivid?: boolean
  tag?: 'span' | 'div' | 'p' | 'a' | 'h1' | 'h2' | 'h3'
  spTextNone?: boolean
  button?: boolean
}

const IconText = ({
  children,
  iconColor,
  textColor,
  iconSize,
  textPx,
  iconType,
  textBold = false,
  textVivid = false,
  tag = 'span',
  spTextNone = false,
  button = false
}: Props) => {
  const Tag = tag
  const gap = textPx <= 12 ? 4 : 8
  return (
    <>
      <Tag className={'c-icon-text' + addClass('c-icon-text--button', button)}>
        <Icon color={iconColor} size={iconSize} type={iconType} />
        {children && (
          <Text color={textColor} px={textPx} bold={textBold} vivid={textVivid}>
            {children}
          </Text>
        )}
      </Tag>
      <style jsx>{`
        ${tag} {
          align-items: ${button ? 'center' : 'baseline'};
          column-gap: ${gap}px;
        }
        .c-icon {
          transform: translateY(2px);
        }
        @media ${STYLE_MEDIA_SP} {
          ${tag} {
            column-gap: ${spTextNone ? 0 : gap}px;
          }
        }
      `}</style>
    </>
  )
}
export default IconText
