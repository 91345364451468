import { useCustomerFavoriteCpApi, useLoggedIn, useUserCustomer } from '~/common/api/user'
import { CP_FAVORITE_PRODUCT_THRESHOLD, CP_GRANT_POINT } from '~/common/app/user'
import { pointCampaignPath } from '~/common/app/path'
import { Color } from '~/common/utils/color'
import Loading from '~/components/atoms/loading/Loading'
import PointAchieveItem from '~/components/molecules/detail/PointAchieveItem'
import PointCampaign from '~/components/molecules/detail/PointCampaign'

const UserPointCampaign = () => {
  const loggedIn = useLoggedIn()
  const customer = useUserCustomer()
  const { data, isLoading } = useCustomerFavoriteCpApi(loggedIn)
  const achievedFavorite = (data?.favorite_product_count ?? 0) >= CP_FAVORITE_PRODUCT_THRESHOLD
  const achievedFirstUse = (customer && !customer.is_first_use) ?? false

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <PointCampaign
          color={Color.ASSERTION}
          link={pointCampaignPath()}
          description={
            'お気に入り' +
            CP_FAVORITE_PRODUCT_THRESHOLD +
            '商品登録&初めてのご利用で' +
            CP_GRANT_POINT +
            'ポイントプレゼント!!'
          }
        >
          <PointAchieveItem
            textTop={'Mission01'}
            textBottom={CP_FAVORITE_PRODUCT_THRESHOLD + '商品お気に入り登録'}
            achieved={achievedFavorite}
            achieveText={(data?.favorite_product_count ?? 0) + '商品'}
          />
          <PointAchieveItem
            textTop={'Mission02'}
            textBottom={'初めてのご利用'}
            achieved={achievedFirstUse}
            achieveText={achievedFirstUse ? '利用済み' : ''}
          />
        </PointCampaign>
      )}
    </>
  )
}

export default UserPointCampaign
