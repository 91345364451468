import React, {
  ChangeEventHandler
} from 'react'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'

export const CheckBoxSize = {
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
} as const

export type CheckBoxSize = (typeof CheckBoxSize)[keyof typeof CheckBoxSize]

interface Props {
  label?: string
  name: string
  value: number | string | boolean
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  size?: CheckBoxSize
}

const CheckBox = ({ label, name, value, checked, onChange, size = CheckBoxSize.MEDIUM }: Props) => {
  const id = typeof value == 'boolean' ? name : name + '_' + value
  return (
    <label className='c-checkbox'>
      <input id={id} className='c-checkbox__input' type='checkbox' name={name} checked={checked}
             onChange={onChange} />
      <span className={'c-checkbox__check' +
        addClass('c-checkbox__check--medium', size == CheckBoxSize.MEDIUM) +
        addClass('c-checkbox__check--large', size == CheckBoxSize.LARGE)}>
        <Icon color={Color.INVERSION} size={size == CheckBoxSize.LARGE ? 16 : 12}
              type={IconType.CHECK} />
      </span>
      {label &&
        <>{label}</>
      }
    </label>
  )
}

export default CheckBox
