import React from 'react'
import { Color } from '~/common/utils/color'
import ModalFrame, { ModalFrameSize } from '~/components/layouts/modal/ModalFrame'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import Text from '~/components/atoms/text/Text'
import FormRadioButton from '~/components/app/form/widgets/FormRadioButton'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import HiddenPc from '~/components/utils/hidden/HiddenPc'
import AreaBox from '~/components/layouts/area/AreaBox'
import MarginTop from '~/components/utils/margin/MarginTop'
import FormBase from '~/components/app/form/base/FormBase'
import { useFormErrorState, useFormState } from '~/common/form/handle'
import {
  initialData,
  medicineChoice,
  MedicineType,
  MedicineTypeError,
  medicineValidation
} from '~/components/app/form/cart/MedicineType'
import { hasErrors } from '~/common/form/error'

export interface MedicineModalProps {
  cartIn: () => void
}

interface Props extends MedicineModalProps {
  onClickClose(): void
}

const MedicineModal = ({ cartIn, onClickClose }: Props) => {
  const [formData, setData] = useFormState<MedicineType>(initialData)
  const [formError, setError] = useFormErrorState<MedicineTypeError>()

  return (
    <ModalFrame onClickClose={onClickClose} scrollable size={ModalFrameSize.LOW}>
      <BlockTitle verticalBar tag='h2' margin={8}>
        濫用性医薬品購入に関する確認
      </BlockTitle>
      <FormBase
        formData={formData}
        call={cartIn}
        validations={medicineValidation}
        setError={setError}
      >
        <Segments
          spGap={16}
          pcGap={16}
          spColumn={SegmentsType.COLUMN}
          pcColumn={SegmentsType.COLUMN}
        >
          <AreaBox
            color={Color.BG_EMPHASIZE}
            spPaddingBlock={16}
            spPaddingInline={16}
            pcPaddingBlock={16}
            pcPaddingInline={16}
          >
            <Text color={Color.ASSERTION} px={14} bold center tag={'p'} lineHeight={120}>
              本商品は、濫用等のおそれのある医薬品です。購入時に以下の注意事項をご確認の上、下記設問に回答してください。
            </Text>
          </AreaBox>
          <Text px={12} tag={'p'}>
            以下対象成分を含む医薬品は、濫用によるお客様の健康被害を防ぐため、法令に基づき、以下の事項の確認が義務付けられております。
            <br />
            また法令に基づき、販売数量はおひとり様1個までとなっております。
          </Text>
          <AreaBox
            color={Color.LOW_PRIORITY}
            spPaddingBlock={24}
            spPaddingInline={24}
            pcPaddingBlock={24}
            pcPaddingInline={24}
          >
            <Segments
              spGap={16}
              pcGap={16}
              spColumn={SegmentsType.COLUMN}
              pcColumn={SegmentsType.COLUMN}
              fitWidth
            >
              <Text px={14} bold tag={'p'} lineHeight={120}>
                濫用等のおそれのある成分（以下の成分を含む医薬品が対象です。）
              </Text>
              <Segments spGap={8} pcGap={8} spColumn={SegmentsType.COLUMN} fitWidth>
                <Text px={12} tag={'p'}>
                  ・エフェドリン
                </Text>
                <Text px={12} tag={'p'}>
                  ・コデイン
                </Text>
                <Text px={12} tag={'p'}>
                  ・ジヒドロコデイン
                </Text>
                <Text px={12} tag={'p'}>
                  ・ブロモバレリル尿素
                </Text>
                <Text px={12} tag={'p'}>
                  ・プソイドエフェドリン
                </Text>
                <Text px={12} tag={'p'}>
                  ・メチルエフェドリン
                </Text>
              </Segments>
            </Segments>
          </AreaBox>
          <Text px={12} tag={'p'}>
            「コデイン類」を含む「総合感冒薬・鎮咳去痰薬」等医薬品の12歳未満小児への服用はお控えいただき、医療機関の診察を受けてください。
          </Text>
          <Segments
            spGap={24}
            pcGap={24}
            spColumn={SegmentsType.COLUMN}
            pcColumn={SegmentsType.COLUMN}
            fitWidth
          >
            <Segments spGap={8} pcGap={16} spColumn={SegmentsType.COLUMN} pcBetween fitWidth>
              <Text px={14} bold tag={'p'} lineHeight={120}>
                このお薬のご購入者は18際以上ですか？
              </Text>
              <FormRadioButton
                choices={medicineChoice}
                name={'age'}
                value={formData.age}
                onChange={setData}
                horizon
                pcFitContent
              />
            </Segments>
            <Segments spGap={8} pcGap={16} spColumn={SegmentsType.COLUMN} pcBetween fitWidth>
              <Text px={14} bold tag={'p'} lineHeight={120}>
                本日購入のお薬と同じ効能・効果のお薬を他の薬局等で購入していますか？
              </Text>
              <FormRadioButton
                choices={medicineChoice}
                name={'other_shop'}
                value={formData.other_shop}
                onChange={setData}
                horizon
                pcFitContent
              />
            </Segments>
            <Segments spGap={8} pcGap={16} spColumn={SegmentsType.COLUMN} pcBetween fitWidth>
              <Text px={14} bold tag={'p'} lineHeight={120}>
                本日購入のお薬や同じ効能・効果のお薬を継続購入・継続服用していますか？
              </Text>
              <FormRadioButton
                choices={medicineChoice}
                name={'continuation'}
                value={formData.continuation}
                onChange={setData}
                horizon
                pcFitContent
              />
            </Segments>
          </Segments>
        </Segments>
        <MarginTop marginSp={24} marginPc={24}>
          <BlockTitle verticalBar tag='h2' margin={8}>
            医薬品販売に関する確認事項
          </BlockTitle>
          <Segments spGap={8} pcGap={16} spColumn={SegmentsType.COLUMN} pcBetween fitWidth>
            <Segments
              spGap={8}
              pcGap={8}
              spColumn={SegmentsType.COLUMN}
              pcColumn={SegmentsType.COLUMN}
            >
              <Text px={14} bold tag={'p'} lineHeight={120}>
                以下の注意事項をご確認の上、ご了承いただきましたか？
              </Text>
              <Text px={12} tag={'p'}>
                医薬品ご購入に関するご注意<br></br>
                商品ページに掲載されておりますお薬の説明書の記載を必ずご確認の上、ご購入ください。
                <br></br>
                ※当店で販売する医薬品は、使用期限が最短でも６カ月以上のものを販売しております。
              </Text>
            </Segments>
            <FormRadioButton
              choices={medicineChoice}
              name={'understand'}
              value={formData.understand}
              onChange={setData}
              horizon
              pcFitContent
            />
          </Segments>
        </MarginTop>
        <MarginTop marginSp={16} marginPc={24}>
          <Segments
            spGap={8}
            pcGap={8}
            spColumn={SegmentsType.COLUMN}
            pcColumn={SegmentsType.COLUMN}
            fitWidth
            spAlignCenter
            pcAlignCenter
          >
            <Button role={ButtonRole.HIGH_PRIORITY_YELLOW} size={ButtonSize.LARGE} px={14} noShrink>
              確認しました
            </Button>
            <Text color={Color.ASSERTION} px={12} center tag={'p'} lineHeight={120}>
              {hasErrors(formError)
                ? '大変申し訳ございませんが、お客様には本商品は販売できません。'
                : '\u00A0'}
            </Text>
            <HiddenPc>
              <Button
                role={ButtonRole.LOW_PRIORITY}
                size={ButtonSize.LARGE}
                px={14}
                onClick={onClickClose}
                type={'button'}
              >
                閉じる
              </Button>
            </HiddenPc>
          </Segments>
        </MarginTop>
      </FormBase>
    </ModalFrame>
  )
}

export default MedicineModal
