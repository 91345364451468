import { SHOP_HEADER_TAG_ID } from '~/common/app/shop'
import { STYLE_MEDIA_PC } from '~/common/app/style'
import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  color?: string
  border?: boolean
  tag?: 'header' | 'div'
  inversion?: boolean
  shop?: boolean
  gap?: 4 | 8 | 16 | 24
}

const HeaderFrame = ({
  children,
  color,
  border = false,
  tag = 'header',
  inversion = false,
  shop = false,
  gap
}: Props) => {
  const className =
    'l-header-frame' +
    addClass('l-header-frame--inversion', inversion) +
    addClass('l-header-frame--shop', shop)
  const Tag = tag
  return (
    <>
      <Tag id={shop ? SHOP_HEADER_TAG_ID : ''} className={className}>{children}</Tag>
      <style jsx>{`
        .l-header-frame {
          background: ${color ? color : '#fff'};
          column-gap: ${gap}px;

        }

        @media ${STYLE_MEDIA_PC} {
          .l-header-frame {
            border-top: ${border ? '4px solid #005B42' : 'none'};
            column-gap: ${gap}px;
          }
      `}</style>
    </>
  )
}

export default HeaderFrame
