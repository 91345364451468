import { Dispatch, SetStateAction } from 'react'
import { ChildProps } from '~/common/utils/props'
import { Color } from '~/common/utils/color'
import { IconInversion, IconType } from '~/components/atoms/icon/Icon'
import IconButton from '~/components/atoms/icon/IconButton'
import Text from '~/components/atoms/text/Text'

interface Props extends ChildProps {
  index: number
  total: number
  setIndex: Dispatch<SetStateAction<number>>
}

const SliderModal = ({ children, index, total, setIndex }: Props) => {
  const prevDeactive = index == 0
  const nextDeactive = (index + 1) == total

  return (
    <div className={'p-modal-slider'}>
      <div className={'p-modal-slider__body'}>
        <div className={'u-hidden-sp'}>
          <IconButton color={Color.INVERSION} size={40} type={IconType.ARROW}
                      inversion={IconInversion.HORIZON}
                      onClick={() => setIndex((index) => --index)} deactive={prevDeactive} />
        </div>
        {children}
        <div className={'u-hidden-sp'}>
          <IconButton color={Color.INVERSION} size={40} type={IconType.ARROW}
                      onClick={() => setIndex((index) => ++index)} deactive={nextDeactive} />
        </div>
      </div>
      <div className={'p-modal-slider__bottom'}>
        <div className={'u-hidden-pc'}>
          <IconButton color={Color.INVERSION} size={40} type={IconType.ARROW}
                      inversion={IconInversion.HORIZON}
                      onClick={() => setIndex((index) => --index)} deactive={prevDeactive} />
        </div>
        <Text color={Color.INVERSION}>{index + 1}/{total}</Text>
        <div className={'u-hidden-pc'}>
          <IconButton color={Color.INVERSION} size={40} type={IconType.ARROW}
                      onClick={() => setIndex((index) => ++index)} deactive={nextDeactive} />
        </div>
      </div>
    </div>
  )
}

export default SliderModal
