import React from 'react'
import { FormPartsProps, useInputHandleChange } from '~/common/form/handle'
import { WidgetChoice } from '~/common/form/choice'
import PullDown from '~/components/atoms/pulldown/PullDown'
import FormWidgetErrorMessages from '~/components/app/form/widgets/FormWidgetErrorMessages'
import FormInput from '~/components/layouts/form/FormInput'

interface Props extends FormPartsProps<number | string> {
  choices: WidgetChoice<number | string>[]
  noErrorHighlight?: boolean
}

const FormPullDown = ({
  label,
  name,
  onChange,
  errors = [],
  choices = [],
  value,
  noErrorHighlight = false
}: Props) => {
  const handleChange = useInputHandleChange<HTMLSelectElement>(name, onChange)

  return (
    <FormInput>
      <PullDown
        name={name}
        choices={choices}
        value={value}
        onChange={handleChange}
        error={!noErrorHighlight && errors?.length > 0}
      />
      <FormWidgetErrorMessages label={label as string} errors={errors} />
    </FormInput>
  )
}

export default FormPullDown
