import { MouseEvent, ReactNode } from 'react'
import css from 'styled-jsx/css'
import { Color, textColorClass } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import { IconType } from '~/components/atoms/icon/Icon'
import IconText, { IconTextPx } from '~/components/atoms/icon/IconText'
import { TextPx } from '~/components/atoms/text/Text'
import InternalLink from '~/components/utils/link/InternalLink'
import ExternalLink from '~/components/utils/link/ExternalLink'

export const ButtonSize = {
  MEDIUM: 'medium',
  LARGE: 'large',
  WIDE_SMALL: 'wide-small',
  WIDE_MEDIUM: 'wide-medium'
} as const

export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize]
export type ButtonType = 'submit' | 'reset' | 'button'

export const ButtonRole = {
  HIGH_PRIORITY_YELLOW: 'high_priority_yellow',
  HIGH_PRIORITY_DEEP_GREEN: 'high_priority_deep_green',
  HIGH_PRIORITY_LIGHT_GREEN: 'high_priority_light_green',
  DECISION: 'decision',
  DECISION_INVERSION: 'decision_inversion',
  LOW_PRIORITY: 'low_priority',
  LOW_PRIORITY_INVERSION: 'low_priority_inversion',
  DEACTIVE: 'deactive',
  UNSELECTED: 'unselected',
  INVERSION: 'inversion',
  YELLOW: 'yellow',
  DELIVERY: 'delivery',
  PICKUP: 'pickup'
} as const

export type ButtonRole = (typeof ButtonRole)[keyof typeof ButtonRole]

const buttonSizeClass = (type: ButtonSize): string => {
  return 'c-button--size-' + type
}

const buttonSizeFontSize = (type: ButtonSize) => {
  switch (type) {
    case ButtonSize.LARGE:
      return 14
    default:
      return 12
  }
}

const buttonRoleFontColor = (role: ButtonRole): Color => {
  switch (role) {
    case ButtonRole.HIGH_PRIORITY_YELLOW:
    case ButtonRole.INVERSION:
      return Color.PRIMARY
    case ButtonRole.HIGH_PRIORITY_LIGHT_GREEN:
    case ButtonRole.HIGH_PRIORITY_DEEP_GREEN:
    case ButtonRole.DECISION:
    case ButtonRole.DEACTIVE:
    case ButtonRole.YELLOW:
    case ButtonRole.UNSELECTED:
    case ButtonRole.DELIVERY:
    case ButtonRole.PICKUP:
      return Color.INVERSION
    case ButtonRole.DECISION_INVERSION:
      return Color.EMPHASIS
    case ButtonRole.LOW_PRIORITY:
    case ButtonRole.LOW_PRIORITY_INVERSION:
      return Color.LINK
  }
}

const buttonRoleFontColorClass = (role: ButtonRole): string => {
  return textColorClass(buttonRoleFontColor(role))
}

const buttonRoleColorClass = (role: ButtonRole): string => {
  const baseName = 'c-button--color-'
  switch (role) {
    case ButtonRole.HIGH_PRIORITY_YELLOW:
      return baseName + 'yellow'
    case ButtonRole.HIGH_PRIORITY_DEEP_GREEN:
      return baseName + 'deep-green'
    case ButtonRole.HIGH_PRIORITY_LIGHT_GREEN:
      return baseName + 'light-green'
    case ButtonRole.DECISION:
      return baseName + 'red'
    case ButtonRole.DECISION_INVERSION:
      return baseName + 'red-inversion'
    case ButtonRole.LOW_PRIORITY:
      return baseName + 'light-blue'
    case ButtonRole.LOW_PRIORITY_INVERSION:
    case ButtonRole.INVERSION:
      return baseName + 'white'
    case ButtonRole.DEACTIVE:
      return baseName + 'gray'
    case ButtonRole.YELLOW:
      return baseName + 'yellow-reverse'
    case ButtonRole.UNSELECTED:
      return baseName + 'lite-gray'
    case ButtonRole.DELIVERY:
      return baseName + 'delivery'
    case ButtonRole.PICKUP:
      return baseName + 'pickup'
  }
}

const buttonRoleBold = (role: ButtonRole): boolean => {
  switch (role) {
    case ButtonRole.DECISION_INVERSION:
      return false
    default:
      return true
  }
}

interface Props {
  children?: ReactNode
  pcChildren?: ReactNode
  size?: ButtonSize
  px?: TextPx
  iconSize?: number
  iconFill?: Color
  role: ButtonRole
  link?: string
  iconType?: IconType
  disabled?: boolean
  expanded?: boolean
  flex?: boolean
  noShrink?: boolean
  spTextNone?: boolean
  lineClose?: boolean
  shadow?: boolean
  onClick?(e: MouseEvent<HTMLElement>): void
  // FIXME: temporally for markt_web page
  noInternal?: boolean
  external?: boolean
  type?: ButtonType | undefined
}

const Button = ({
  children,
  pcChildren,
  size = ButtonSize.MEDIUM,
  px,
  iconSize,
  role,
  link,
  iconType,
  iconFill,
  disabled = false,
  expanded = false,
  flex = false,
  noShrink = false,
  spTextNone = false,
  lineClose = false,
  shadow = false,
  onClick,
  noInternal = false,
  external = false,
  type
}: Props) => {
  const fontColor = buttonRoleFontColor(role)
  const iconColor = iconFill ?? buttonRoleFontColor(role)
  const fontSize = px ?? buttonSizeFontSize(size)
  const fontBold = buttonRoleBold(role)
  const textChild = pcChildren ? (
    <>
      {children && <span className='u-hidden-pc'>{children}</span>}
      <span className='u-hidden-sp'>{pcChildren}</span>
    </>
  ) : (
    <>{children && <>{children}</>}</>
  )

  const child = (
    <>
      {iconType ? (
        <IconText
          iconType={iconType}
          iconColor={iconColor}
          iconSize={iconSize ?? fontSize}
          textPx={fontSize as IconTextPx}
          textBold={fontBold}
          textColor={fontColor}
          spTextNone={spTextNone}
          button
        >
          {textChild}
        </IconText>
      ) : (
        <>{textChild}</>
      )}
    </>
  )

  const { className: fontSizeClass, styles: fontSizeStyle } = css.resolve`
    font-size: ${fontSize}px;
  `

  const className =
    'c-button' +
    addClass(buttonSizeClass(size)) +
    addClass(fontSizeClass) +
    addClass(buttonRoleColorClass(role)) +
    addClass(buttonRoleFontColorClass(role), !iconType) +
    addClass(fontBold ? 'c-button--bold-thick' : 'c-button--bold-normal', !iconType) +
    addClass('c-button--expanded', expanded) +
    addClass('c-button--flex', flex) +
    addClass('c-button--no-shrink', noShrink) +
    addClass('c-button--line-close', lineClose) +
    addClass('c-button--shadow', shadow)

  const Tag = external ? ExternalLink : InternalLink

  return (
    <>
      {link && !disabled ? (
        <>
          {noInternal ?
            <a href={link} className={className}>
              {child}
            </a> :
            <Tag link={link} className={className}>
              {child}
            </Tag>
          }
        </>
      ) : (
        <button className={className} onClick={onClick} disabled={disabled} type={type}>
          {child}
        </button>
      )}
      {fontSizeStyle}
    </>
  )
}

export default Button
