import ImageWatcher from '~/components/utils/image/ImageWatcher'
import Product from '~/model/product'
import { useShowModal } from '~/common/app/store/modal'
import { STYLE_MEDIA_PC } from '~/common/app/style'
import { Modal } from '~/components/app/modal/AppModal'
import { ModalImageProps } from '~/components/app/modal/ImageModal'
import { savedImagePath } from '~/common/app/path'
import { sendProductAnalysisData } from '~/common/app/productSearch'

interface Props {
  product: Product
  expandable?: boolean
}

const ProductImage = ({ product, expandable = false }: Props) => {
  const showModal = useShowModal()
  const imagePaths = (product.product_images && product.product_images?.length > 0) ?
    product.product_images?.map((image) => {
      return savedImagePath(image)
    }) : [savedImagePath(undefined)]

  const onSelect = (index: number) => {
    showModal({
      type: Modal.IMAGE,
      childProps: {
        images: imagePaths,
        firstIndex: index,
        alt: product.name
      } as ModalImageProps
    })
  }

  const handleLoad = () => {
    sendProductAnalysisData(product.searched_product_id)
  }

  return (
    <>
      <div>
        <ImageWatcher images={imagePaths} alt={product.name}
                      onSelect={expandable ? onSelect : undefined} onLoad={handleLoad}/>
      </div>
      <style jsx>{`
        @media ${STYLE_MEDIA_PC} {
          div {
            min-width: 400px;
          }
        }
      `}</style>
    </>
  )
}

export default ProductImage
