import { useState, useEffect } from 'react'

export const useIsSpDevice = (): boolean => {
  const BREAK_POINT_PC = 1041

  const isClient = typeof window === 'object'

  type WindowSize = {
    width: number | undefined
    height: number | undefined
  }

  const getSize = (): WindowSize => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState<WindowSize>(getSize)

  useEffect(() => {
    if (!isClient) {
      return
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize.width ? windowSize.width < BREAK_POINT_PC : false
}