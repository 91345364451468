import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'
import InternalLink from '~/components/utils/link/InternalLink'

interface Props extends ChildProps {
  description: string
  link: string
  descriptionBlack?: boolean
  color: Color
}

const PointCampaign = ({ children, description, link, descriptionBlack = false, color }: Props) => {
  return (
    <>
      <div className={'p-point-cp-wrap'}>
        <InternalLink
          link={link}
          className={'p-point-cp-title' + addClass('p-point-cp-title--black', descriptionBlack)}
        >
          {description}
        </InternalLink>
        <div className='p-point-cp-list'>{children}</div>
      </div>
      <style jsx>{`
        .p-point-cp-wrap {
          background: ${color};
        }
      `}</style>
    </>
  )
}
export default PointCampaign
