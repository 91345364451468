import { useEffect } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import { useRouter } from 'next/router'

import User from '~/model/user'
import Area from '~/model/area'
import Address from '~/model/address'
import { ApiPath, apiPutFetcher, fetchApi } from '~/common/api/base'
import { useMoveKeyPath } from '~/common/app/path'
import { RequestError } from '~/common/app/requestError'
import { useCloseModal, useShowModal } from '~/common/app/store/modal'
import { Modal } from '~/components/app/modal/AppModal'
import { ConfirmModalProps } from '~/components/app/modal/ConfirmModal'
import { useHideLoading, useShowLoading } from '~/common/app/store/loading'
import { useArea } from '~/common/api/area'
import { useShop } from '~/common/api/shop'

export const Mode = {
  DELIVERY: false,
  PICKUP: true
}

export type Mode = (typeof Mode)[keyof typeof Mode]

export interface SwitchAreaResponse {
  area: Area
}

export interface UserNewOrderResponse {
  has_new_status_order: boolean
}

export interface AddressListResponse {
  addresses: Address[]
  currentArea: Area
}

export interface CpPointResponse {
  is_buy_this_week: boolean
  is_buy_last_week: boolean
}

export interface FavoriteCpPointResponse {
  favorite_product_count: number
}

export const fetchUser = async (cookie?: string) => {
  const { data } = await fetchApi<User>(ApiPath.USER, {}, { headers: { Cookie: cookie ?? '' } })
  return data
}

export const useUserApi = () => {
  return useSWR<User>(ApiPath.USER)
}

export const useUserCustomer = () => {
  const { data } = useUserApi()
  return data?.customer
}

export const useLoggedIn = (): boolean => {
  const { data } = useUserApi()
  return !!data && !!data.customer
}

export const useLoggedInApi = () => {
  const { data, ...props } = useUserApi()
  const loggedIn = !!data && !!data.customer
  return { loggedIn, ...props }
}

// 基本ポイント付与率は常に1の想定
export const BASIC_POINT_RATE = 1

/**
 * Use useArea if you would to refer to current path
 */
export const useUserArea = () => {
  const { data } = useUserApi()
  return data && data.access_area_id && data.access_area_key
    ? {
        id: data.access_area_id,
        area_key: data.access_area_key
      }
    : null
}

/**
 * Use useShop if you would to refer to current path
 */
export const useUserShop = () => {
  const { data } = useUserApi()
  return data && data.front_shop_id && data.front_shop_key
    ? {
        id: data.front_shop_id,
        shop_key: data.front_shop_key
      }
    : null
}

export const useUserMode = () => {
  const { data } = useUserApi()
  return data?.pickup_mode
}

export const useUserNewOrderApi = () => {
  return useSWR<UserNewOrderResponse>(ApiPath.USER_NEW_ORDER)
}

export const useMoveByUserShopArea = () => {
  const currentArea = useArea()
  const currentShop = useShop()

  const area = useUserArea()
  const shop = useUserShop()
  const move = useMoveKeyPath()

  useEffect(() => {
    if (!currentShop) {
      if (currentArea) {
        area && currentArea.area_key != area.area_key && move(area.area_key)
      } else if (shop) {
        move(shop.shop_key)
      } else if (area) {
        move(area.area_key)
      }
    }
  }, [area, shop])
}

/**
 * support api
 */
export const useCustomerCpApi = (loggedIn: boolean) => {
  return useSWR<CpPointResponse>(loggedIn ? ApiPath.CP_POINT : null)
}

export const useCustomerFavoriteCpApi = (loggedIn: boolean) => {
  return useSWR<FavoriteCpPointResponse>(loggedIn ? ApiPath.CP_POINT_FAVORITE : null)
}

export const useUserAddressList = () => {
  return useSWR<AddressListResponse>(ApiPath.ADDRESS_LIST)
}

/**
 * switch api
 */
export const useSwitchShop = () => {
  const { mutate } = useSWRConfig()
  const options = {
    onSuccess: (data: Response) => {
      mutate(ApiPath.USER)
    }
  }

  const { trigger } = useSWRMutation(ApiPath.SHOP_SWITCH, apiPutFetcher, options)

  return (shopKey?: string) => trigger(shopKey ? { shop_key: shopKey } : { direct_home: 1 })
}

export const useSwitchMode = () => {
  const { mutate } = useSWRConfig()
  const mode = useUserMode()
  const showLoading = useShowLoading()
  const hideLoading = useHideLoading()
  const showModal = useShowModal()
  const closeModal = useCloseModal()

  const options = {
    onSuccess: (data: Response) => {
      mutate(ApiPath.USER)
      mutate([ApiPath.CART, { should_validate: false }])
      hideLoading()
      closeModal()
    },
    onError: (data: Response) => {
      if (data.status == RequestError.CART_EXIST) {
        showModal({
          type: Modal.CONFIRM,
          childProps: {
            onClickYes: () => {
              showLoading()
              trigger({ pickup_mode: !mode, should_cart_delete: true })
            },
            title: 'カートの商品が削除されます',
            message:
              '配送注文と店舗受取注文は同時に行えません。カート商品を削除してよろしいですか？'
          } as ConfirmModalProps
        })
      }
      hideLoading()
    }
  }

  const { trigger } = useSWRMutation(ApiPath.MODE_SWITCH, apiPutFetcher, options)

  return () => {
    showLoading()
    trigger({ pickup_mode: !mode, should_cart_delete: false })
  }
}

/**
 * redirect home api
 */
export const useRedirectHome = () => {
  const { mutate } = useSWRConfig()
  const router = useRouter()
  const area = useUserArea()

  const options = {
    onSuccess: () => {
      mutate(ApiPath.USER)
      router.push(
        router.pathname.replace('/[key]', area ? '/' + area.area_key : '') + window.location.search
      )
    }
  }

  const { trigger } = useSWRMutation(ApiPath.SHOP_SWITCH, apiPutFetcher, options)

  return () => trigger({ direct_home: true })
}

export const useBasePointRate = () => {
  const customer = useUserCustomer()
  const customerPointRate = customer ? customer.customer_point_rate : 0

  return BASIC_POINT_RATE + customerPointRate
}
