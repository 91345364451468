import Product from '~/model/product'
import { productListPath, usePathKey } from '~/common/app/path'
import TagLink from '~/components/atoms/link/TagLink'
import TagLinkList from '~/components/atoms/link/TagLinkList'

interface Props {
  product: Product
}

const ProductCategoryTags = ({ product }: Props) => {
  const key = usePathKey()
  const categoryTags = product.middleCategories ? product.middleCategories.map((category) => {
    return {
      text: category.name,
      link: productListPath({ category_id: category.id, key })
    }
  }) : []

  return (
    <TagLinkList>
      {categoryTags.map((item, index) =>
        <TagLink key={index} link={item.link} text={item.text} />
      )}
    </TagLinkList>
  )
}

export default ProductCategoryTags
