import { Store, useStoreSWR } from '~/common/app/store/store'

export const useGetLoading = (): boolean => {
  const { data } = useStoreSWR<boolean, Error>(Store.LOADING, false)
  return data ?? false
}

export const useShowLoading = (): () => void => {
  const { mutate } = useStoreSWR<boolean, Error>(Store.LOADING)
  return () => mutate(true)
}

export const useHideLoading = (): () => void => {
  const { mutate } = useStoreSWR<boolean, Error>(Store.LOADING)
  return () => mutate(false)
}
