import { ChildProps } from '~/common/utils/props'

const Main = ({ children }: ChildProps) => {
  return (
    <main className={'l-main'}>
      {children}
    </main>
  )
}

export default Main
