import Product from '~/model/product'
import { Operation, useFavoriteOperationApi, useSortFavoriteOperationApi } from '~/common/api/favorite'
import { useShowModal } from '~/common/app/store/modal'
import { useAppLargeProductCardPropsList } from '~/common/app/product'
import { Modal } from '~/components/app/modal/AppModal'
import { useLoggedInApi } from '~/common/api/user'
import { groupBy } from '~/common/utils/array'
import { AppProductCardProps } from '~/components/app/module/product/AppProductCard'

export const useFavoriteOperation = (): ((id: number, isFavorite: boolean) => void) => {
  const favoriteOperationApi = useFavoriteOperationApi()
  const showModal = useShowModal()
  const { loggedIn, isLoading: isLoadingLogin } = useLoggedInApi()

  return (id: number, isFavorite: boolean) => {
    if (!isLoadingLogin && loggedIn) {
      favoriteOperationApi({
        operation: isFavorite ? Operation.DELETE : Operation.ADD,
        id: id
      })
    } else {
      showModal({ type: Modal.LOGIN })
    }
  }
}

export const useSortFavoriteOperation = (favoriteProductIds: number[]) => {
  return useSortFavoriteOperationApi(favoriteProductIds)
}

export const useGroupedProducts = (products: Product[]) => {
  const cardPropsList = useAppLargeProductCardPropsList(products)

  return Object.entries(groupBy(cardPropsList ?? [], (v) => v.product.shop.name)) as [string, AppProductCardProps[]][]
}
