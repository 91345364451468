import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import Image from 'next/image'

interface Props extends ChildProps {
  icon?: IconType
  iconColor?: Color | string
  iconWidth?: number
  iconHeight?: number
  iconImage?: string
  verticalBar?: boolean
  inModal?: boolean
  tag?: 'h2' | 'h3'
  margin?: 4 | 8 | 12 | 16
}

const BlockTitle = ({
  children,
  icon,
  iconColor = Color.SUPPORT_SUB,
  iconImage,
  verticalBar = false,
  inModal = false,
  tag = 'h3',
  margin = 12,
  iconWidth = 16,
  iconHeight = 16
}: Props) => {
  const Tag = tag
  return (
    <>
      <Tag className={'c-block-title' + addClass('c-block-title--vertical_var', verticalBar) + addClass('c-block-title--in-modal', inModal)}>
        {icon && <Icon color={iconColor} size={iconWidth} type={icon} />}
        {iconImage && <Image src={iconImage} width={iconWidth} height={iconHeight} alt={''} />}
        <span>{children}</span>
      </Tag>
      <style jsx>{`
        ${Tag} {
          margin-bottom: ${margin}px;
        }

        .c-block-title--vertical_var::before {
          background: ${iconColor}
        }
      `}</style>
    </>
  )
}

export default BlockTitle
