import React from 'react'
import Image from 'next/image'

import { Color } from '~/common/utils/color'
import { convertToPrice } from '~/common/utils/price'
import { QUANTITY_MAX, QUANTITY_MIN } from '~/common/utils/input'
import { fixByLimit } from '~/common/utils/number'
import { useTimerState } from '~/common/utils/timer'
import { useQuantityInput } from '~/common/utils/input'
import ImageFitContain from '~/components/utils/image/ImageFitContain'
import Stepper from '~/components/atoms/stepper/Stepper'
import Text from '~/components/atoms/text/Text'

export interface CartProductCardProps {
  productClassId: number
  image: string
  name: string
  shopName: string
  price: number
  tax: number
  cartQuantity: number
  onClickChangeQuantity?(quantity: number): void
  onClickDelete?(productClassId: number): void
}

export const PcCartProductCard = ({
  productClassId,
  image,
  name,
  shopName,
  price,
  tax,
  cartQuantity = 0,
  onClickChangeQuantity,
  onClickDelete
}: CartProductCardProps) => {

  const {
    quantity,
    inputValue,
    setInputValue,
    onBlur,
    onEnterKeyDown,
  } = useQuantityInput({ initialValue: cartQuantity, onChange: onClickChangeQuantity })

  return (
    <>
      <li className='p-cart-product-card-pc'>
        <div className='p-cart-product-card-pc__image'>
          <ImageFitContain src={image} alt={name} />
        </div>
        <div className='p-cart-product-card-pc__detail'>
          <div className='p-cart-product-card-pc__text'>
            <p className='p-cart-product-card-pc__name'>{name}</p>
            <p className='p-cart-product-card-pc__store'>{shopName}</p>
          </div>
          <div className='p-cart-product-card-pc__wrap'>
            <div className='p-cart-product-card-pc__price'>
              <Text px={10} color={Color.EMPHASIS} bold en>
                ¥
              </Text>
              <Text px={16} color={Color.EMPHASIS} bold en>
                {convertToPrice(price)}
              </Text>
              {tax && <Text px={10}>(税込¥{convertToPrice(tax)})</Text>}
            </div>
            <div className='p-cart-product-card-pc__quantity'>
              <input
                className={'p-cart-product-card-pc__quantity__input'}
                type={'number'}
                value={inputValue}
                inputMode={'numeric'}
                min={QUANTITY_MIN}
                max={QUANTITY_MAX}
                step='1'
                onFocus={() => setInputValue(String(quantity))}
                onChange={(e) => setInputValue(String(e.target.value))}
                onBlur={onBlur}
                onKeyDown={onEnterKeyDown}
              />
            </div>
          </div>
        </div>
        <div
          className='p-cart-product-card-pc__close'
          onClick={() => {
            if (typeof onClickDelete != 'undefined') {
              onClickDelete(productClassId)
            }
          }}
        >
          <Image src='/assets/images/close1.png' alt='' width='16' height='16' />
        </div>
      </li>
    </>
  )
}

export const SpCartProductCard = ({
  productClassId,
  image,
  name,
  shopName,
  price,
  tax,
  cartQuantity = 0,
  onClickChangeQuantity,
  onClickDelete
}: CartProductCardProps) => {
  const [quantity, setQuantity] = useTimerState(cartQuantity, onClickChangeQuantity)
  const onChangeValue = (inputValue: number) => {
    setQuantity(fixByLimit(inputValue, QUANTITY_MAX))
  }

  return (
    <>
      <li className='p-cart-product-card-sp'>
        <div className='p-cart-product-card-sp__main'>
          <div className='p-cart-product-card-sp__image'>
            <ImageFitContain src={image} alt={name} />
          </div>
          <div className='p-cart-product-card-sp__price'>
            <Text px={10} color={Color.EMPHASIS} bold vivid en>
              ¥
            </Text>
            <Text px={16} color={Color.EMPHASIS} bold vivid en>
              {convertToPrice(tax)}
            </Text>
            <Text px={10} color={Color.EMPHASIS} bold vivid en>
              (税込)
            </Text>
          </div>
        </div>
        <div
          className='p-cart-product-card-sp__close'
          onClick={() => {
            if (typeof onClickDelete != 'undefined') {
              onClickDelete(productClassId)
            }
          }}
        >
          <Image src='/assets/images/close1.png' alt='' width='16' height='16' />
        </div>
        <div className='p-cart-product-card-sp__name'>{name}</div>
        <div className='p-cart-product-card-sp__store'>{shopName}</div>
        <div className='p-product-card__quantity'>
          <Stepper value={cartQuantity} onChange={onChangeValue} />
        </div>
      </li>
    </>
  )
}
