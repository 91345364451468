const Loading = () => {
  return (
    <div className='c-loading'>
      <div className='c-loading__dot'></div>
      <div className='c-loading__dot'></div>
      <div className='c-loading__dot'></div>
    </div>
  )
}
export default Loading
