import React, { ChangeEvent } from 'react'
import { FormPartsProps, useInputHandleChange } from '~/common/form/handle'
import { TEXT_STRING_MAX_LENGTH } from '~/common/form/parameter'
import FormWidgetErrorMessages from '~/components/app/form/widgets/FormWidgetErrorMessages'
import FormInput from '~/components/layouts/form/FormInput'
import TextArea, { TextAreaPx, TextAreaVisual } from '~/components/atoms/textarea/TextArea'

export interface FormTextBoxProps extends FormPartsProps<string> {
  px?: TextAreaPx
  pcPx?: TextAreaPx
  placeholder?: string
  maxLength?: number
  high?: boolean
  autoComplete?: string
  visual?: TextAreaVisual
  wide?: boolean
  noErrorHighlight?: boolean

  onBlur?(event: ChangeEvent<HTMLTextAreaElement>): void

  onFocus?(event: ChangeEvent<HTMLTextAreaElement>): void
}

const FormTextArea = ({
  px,
  pcPx,
  name,
  value,
  onChange,
  maxLength = TEXT_STRING_MAX_LENGTH,
  label = '',
  placeholder = '',
  errors = [],
  autoComplete = 'off',
  onBlur,
  visual,
  wide = false,
  noErrorHighlight = false
}: FormTextBoxProps) => {
  const handleChange = useInputHandleChange<HTMLTextAreaElement>(name, onChange)

  return (
    <FormInput wide={wide}>
      <TextArea
        px={px}
        pcPx={pcPx}
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        placeholder={placeholder.length > 0 ? placeholder : label}
        autoComplete={autoComplete}
        error={!noErrorHighlight && errors.length > 0}
        onBlur={onBlur}
        visual={visual}
      />
      <FormWidgetErrorMessages label={label} errors={errors} />
    </FormInput>
  )
}

export default FormTextArea
