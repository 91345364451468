import { MouseEvent } from 'react'
import css from 'styled-jsx/css'
import { Color, textColorClass } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'
import { TextPx } from '~/components/atoms/text/Text'
import ExternalLink from '~/components/utils/link/ExternalLink'
import InternalLink from '~/components/utils/link/InternalLink'

interface Props extends ChildProps {
  color?: Color
  px?: TextPx
  arrow?: Color
  listArrow?: boolean
  underline?: boolean
  link?: string
  external?: boolean
  bold?: boolean
  nowrap?: boolean
  // FIXME: temporally for markt_web page
  noInternal?: boolean

  onClick?(e: MouseEvent<HTMLElement>): void
}

const LinkText = ({
  children,
  color = Color.LINK,
  px = 14,
  arrow,
  listArrow = false,
  underline = false,
  link,
  external = false,
  bold = false,
  nowrap = false,
  noInternal = false,
  onClick
}: Props) => {
  const { className: sizeClass, styles: sizeStyle } = css.resolve`
    font-size: ${px}px;
    ::before,
    ::after {
      border-color: ${arrow};
    }
  `

  const className =
    'c-text-link' +
    addClass(textColorClass(color)) +
    addClass('c-text-link--arrow', !!arrow) +
    addClass('c-text-link--list-arrow', listArrow) +
    addClass('c-text-link--underline', underline) +
    addClass('c-text-link--bold', bold) +
    addClass('c-text-link--nowrap', nowrap) +
    addClass(sizeClass)

  const Tag = external ? ExternalLink : InternalLink

  return (
    <>
      {link ? (
        <>
          {noInternal ?
            <a href={link} className={className}>
              {children}
            </a> :
            <Tag link={link} className={className}>
              {children}
            </Tag>
          }
        </>
      ) : (
        <span className={className} onClick={onClick}>
          {children}
        </span>
      )}
      {sizeStyle}
    </>
  )
}

export default LinkText
