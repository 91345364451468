import { Color } from '~/common/utils/color'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import Text from '~/components/atoms/text/Text'

interface Props {
  reviewer_name: string
  recommend_level: number
  comment: string
  date: string
  scroll?: boolean
}

const Review = ({ reviewer_name, recommend_level, comment, date, scroll = false }: Props) => {
  const wrapClassName = scroll ? 'p-review-item p-review-item--scroll' : 'p-review-item'
  return (
    <>
      <li className={wrapClassName}>
        <div className='p-review-item__info'>
          <Icon color={Color.SUPPORT} size={15} type={IconType.USER} />
          <span className='p-review-item__info__reviewer-name'>{reviewer_name}</span>
          {scroll ? (
            <span className='p-review-item__info__stars'>
              <Text px={16} color={Color.EMPHASIS_SUB}>
                ★
              </Text>
              <Text px={14} color={Color.EMPHASIS_SUB}>
                {recommend_level}
              </Text>
            </span>
          ) : (
            <span
              className={`p-review-item__info__stars p-review-item__info__stars--0${recommend_level}`}
            ></span>
          )}
        </div>
        <div className='p-review-item__comment'>
          <Text px={12} tag={'p'}>
            {comment}
          </Text>
        </div>
        <p className='p-review-item__date'>{date}</p>
      </li>
    </>
  )
}
export default Review
