import React, { useEffect, useMemo, useState } from 'react'

import Area from '~/model/area'
import Product from '~/model/product'
import Category from '~/model/category'
import { ShopDetail } from '~/model/shop'

import {
  convertSearchedProduct,
  extractSearchedCategories,
  extractChildCategories
} from '~/common/app/productSearch'
import {
  CollectionProductsResponse,
  useCollectionApi,
  useFavoriteProductListApi,
  useSearchedProductList,
  useSimilarSearchedProductList,
  useTokubaiProductListApi,
  usePickupProductListApi
} from '~/common/api/product'
import { extractCouponTagInfos, extractPointAndCouponForProductCard } from '~/common/app/coupon'
import { useMakeShopNextAcceptableMessage } from '~/common/api/shop'
import { Params } from '~/common/api/base'
import { CouponListResponse, useCouponListApi } from '~/common/api/coupon'
import { useBasePointRate } from '~/common/api/user'
import { useArea } from '~/common/api/area'
import { sendSearchedProductAnalysisData } from '~/common/app/productSearch'
import { AppProductCardProps } from '~/components/app/module/product/AppProductCard'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'

export const PRODUCT_LIST_DISPLAY_MAX = 50
export const SIMILAR_PRODUCT_LIST_DISPLAY_MAX = 10

export const extractProductClassIds = (products: Product[] | undefined) => {
  return products?.map((product: Product) => product.product_class_id)
}

export const productTitle = (product: Product) => {
  return [product.name, product.amount].filter(n => n).join(' | ')
    + (product.medicine_risk_name ? '【' + product.medicine_risk_name + '】' : '')
}

export const currentPrice = (product: Product): number => {
  return (product.on_sale && product.sale_price != undefined) ? product.sale_price : product.price
}

export const currentPriceIncTax = (product: Product): number => {
  return (product.on_sale && product.sale_price_inc_tax != undefined) ? product.sale_price_inc_tax : product.price_inc_tax
}

export const handleSortChange = (
  e: React.ChangeEvent<HTMLSelectElement>,
  push: (param: Params) => void
) => {
  const selectedValue = e.target.value
  push({ order_by: selectedValue })
}

export const handleCategoryChange = (
  e: React.ChangeEvent<HTMLSelectElement>,
  push: (param: Params) => void
) => {
  const selectedValue = e.target.value
  push({ category_id: selectedValue })
}

export const useProductList = (sortValue: number | undefined = undefined, pageNo: number | undefined = undefined,
  word: string | undefined = undefined, category: Category | undefined = undefined, categoryIds?: number[], queryShopIds?: string[], request = true) => {
  const area = useArea()
  const {
    data,
    isLoading
  } = useSearchedProductList(sortValue, pageNo, word, category, categoryIds, queryShopIds, request)

  const products = useMemo(() => {
    return data?.result?.items.map((searchedProduct) => convertSearchedProduct(searchedProduct, area))
  }, [data])

  const [currentWord, setCurrentWord] = useState<string | undefined>(undefined)
  const [narrowCategories, setNarrowCategories] = useState<{ value: number; name: string }[]>([])
  const [breadCrumbCategories, setBreadCrumbCategories] = useState<{ value: number; name: string }[]>([])

  useEffect(() => {
    if (category && !word) {
      const facetBreadCrumbCategories = category.hierarchy === 1 ? data?.result?.facet_fields?.c2 : data?.result?.facet_fields?.c3
      if (facetBreadCrumbCategories) {
        const selectedCategoryId = category.id
        const childCategories = extractChildCategories(extractSearchedCategories(facetBreadCrumbCategories), selectedCategoryId)
        setBreadCrumbCategories(childCategories)
      }
    } else {
      setBreadCrumbCategories([])
    }
  }, [category, products])

  useEffect(() => {
    if (narrowCategories) {
      setCurrentWord(undefined)
      setNarrowCategories([])
    }
  }, [word])

  useEffect(() => {
    if ((currentWord != word || typeof narrowCategories === 'undefined') && !category) {
      const facetCategories = word && data?.result?.facet_fields?.c2
      if (facetCategories) {
        setCurrentWord(word)
        const categories = extractSearchedCategories(facetCategories)
        setNarrowCategories(categories)
      }
    }
    const searchProductIds = products?.map((product) => product.searched_product_id)
    if (searchProductIds) {
      sendSearchedProductAnalysisData(word, searchProductIds)
    }
  }, [products])

  return { products, narrowCategories, breadCrumbCategories, total: data?.result?.total, isLoading }
}

export const attachCouponToProducts = (productList: Product[], couponListResponse: CouponListResponse | undefined, baseRate: number) => {
  const couponTagInfos = extractCouponTagInfos(couponListResponse)

  return productList.map((product) => {
    const {
      maxPoint,
      couponTagContents
    } = extractPointAndCouponForProductCard(product, baseRate, couponTagInfos)
    if (maxPoint !== undefined) {
      return {
        ...product,
        add_point: maxPoint,
        coupon_tag_contents: couponTagContents
      }
    }
    return product
  })
}

const usePageProductList = (sortValue?: number, pageNo?: number, word?: string, category?: Category, categoryIds?: number[], queryShopIds?: string[], request = true) => {
  const [products, setProducts] = useState<Product[] | undefined>(undefined)
  const {
    products: searchedProducts,
    narrowCategories,
    breadCrumbCategories,
    isLoading,
    total
  } = useProductList(sortValue, pageNo, word, category, categoryIds, queryShopIds, request)

  useEffect(() => {
    searchedProducts && setProducts(
      pageNo === 1 ? searchedProducts :
        (prevSearchProducts) => prevSearchProducts != undefined ? [...prevSearchProducts, ...searchedProducts] : searchedProducts
    )
  }, [searchedProducts, pageNo])


  return { products, narrowCategories, breadCrumbCategories, total, isLoading }
}

export const useProductsWithCoupon = (
  products: Product[] | undefined
) => {
  const { data: couponsData } = useCouponListApi(false, true)
  const baseRate = useBasePointRate()

  return products && products.length > 0 ? attachCouponToProducts(products, couponsData, baseRate) : []
}

const useTokubaiProductList = (sortValue?: number, pageNo?: number, isSale?: boolean) => {
  const {
    data: tokubaiProductData,
    isLoading: isTokubaiLoading
  } = useTokubaiProductListApi(sortValue, isSale, pageNo ? (pageNo - 1) * PRODUCT_LIST_DISPLAY_MAX : 0)
  const tokubaiProducts = tokubaiProductData?.products
  const tokubaiProuductTotal = tokubaiProductData?.total
  const [tokubaiProductList, setTokubaiProductList] = useState<Product[] | undefined>(undefined)

  useEffect(() => {
    setTokubaiProductList(undefined)
  }, [sortValue])

  useEffect(() => {
    tokubaiProducts && setTokubaiProductList(
      pageNo === 1 ? tokubaiProducts :
        (prevTokubaiProductList) => prevTokubaiProductList != undefined ? [...prevTokubaiProductList, ...tokubaiProducts] : tokubaiProducts
    )
  }, [tokubaiProducts])

  return { tokubaiProductList, tokubaiProuductTotal, isTokubaiLoading }
}

const usePickupProductList = (sortValue?: number, pageNo?: number, isPickup?: boolean) => {
  const {
    data: pickupProductData,
    isLoading: isPickupLoading
  } = usePickupProductListApi(sortValue, isPickup, pageNo ? (pageNo - 1) * PRODUCT_LIST_DISPLAY_MAX : 0)

  const pickupProducts = pickupProductData?.[0]?.products
  const pickupProuductTotal = pickupProductData?.[0]?.total
  const [pickupProductList, setPickupProductList] = useState<Product[] | undefined>(undefined)

  useEffect(() => {
    setPickupProductList(undefined)
  }, [sortValue])

  useEffect(() => {
    pickupProducts && setPickupProductList(
      pageNo === 1 ? pickupProducts :
        (prevPickupProductList) => prevPickupProductList != undefined ? [...prevPickupProductList, ...pickupProducts] : pickupProducts
    )
  }, [pickupProducts])

  return { pickupProductList, pickupProuductTotal, isPickupLoading }
}

export const useFullProductList = (shop?: ShopDetail | undefined, area?: Area | undefined, sortValue?: number, pageNo?: number, word?: string | undefined, category?: Category | undefined, categoryIds?: number[], queryShopIds?: string[], collectionCode?: string | undefined, isSale?: boolean, isPickup?: boolean, isReady?: boolean):
  { products: Product[] | undefined; narrowCategories: { value: number; name: string }[]; breadCrumbCategories: { value: number; name: string }[]; isLoading: boolean; total: number | undefined; collection?: CollectionProductsResponse } => {

  const [searchedProducts, setSearchedProducts] = useState<Product[] | undefined>(undefined)
  const request = !(collectionCode || isSale || isPickup) && isReady

  const {
    products,
    narrowCategories,
    breadCrumbCategories,
    isLoading,
    total
  } = usePageProductList(sortValue, pageNo, word, category, categoryIds, queryShopIds, request)

  const {
    data: collection,
    isLoading: isCollectionLoading
  } = useCollectionApi(collectionCode, shop?.baseInfo.shop_key, area?.area_key, sortValue)

  const {
    tokubaiProductList,
    tokubaiProuductTotal,
    isTokubaiLoading
  } = useTokubaiProductList(sortValue, pageNo, isSale)

  const {
    pickupProductList,
    pickupProuductTotal,
    isPickupLoading
  } = usePickupProductList(sortValue, pageNo, isPickup)

  const { data: couponListResponse } = useCouponListApi(false, true)
  const baseRate = useBasePointRate()

  const productList = collectionCode ? collection?.products : isSale ? tokubaiProductList : isPickup ? pickupProductList : products

  useEffect(() => {
    const productsWithCoupon = productList ? attachCouponToProducts(productList, couponListResponse, baseRate) : searchedProducts
    productsWithCoupon != undefined && setSearchedProducts(productsWithCoupon)
  }, [productList])

  useEffect(() => {
    productList && setSearchedProducts(attachCouponToProducts(productList, couponListResponse, baseRate))
  }, [couponListResponse])

  return {
    products: searchedProducts,
    narrowCategories: narrowCategories ?? [],
    breadCrumbCategories: breadCrumbCategories,
    isLoading: isLoading || isCollectionLoading || isTokubaiLoading || isPickupLoading,
    total: total ?? collection?.products.length ?? tokubaiProuductTotal ?? pickupProuductTotal,
    collection: collection
  }
}

export const useAppLargeProductCardPropsList = (products?: Product[]) => {
  const { data: favoriteProductList } = useFavoriteProductListApi()
  const favoriteIdList = favoriteProductList?.map((product) => product.id) ?? []
  const make = useMakeShopNextAcceptableMessage()

  return useMemo((): AppProductCardProps[] => {
    return products ? products.map((product) => {
      return {
        product,
        type: ProductCardType.LARGE,
        isFavorite: favoriteIdList?.includes(product.id) ?? false,
        addPoint: product.add_point,
        acceptableNextTime: make(product.shop)
      }
    }) : []
  }, [products, favoriteIdList, make])
}

export const useSimilarProductList = (category?: Category) => {
  const area = useArea()
  const {
    data,
    isLoading
  } = useSimilarSearchedProductList(category)

  const products = data?.result?.items.map((searchedProduct) => convertSearchedProduct(searchedProduct, area))
  return { products, isLoading, total: data?.result?.total }
}
