import React, { ChangeEvent } from 'react'
import { addClass } from '~/common/utils/html'
import { STYLE_MEDIA_PC } from '~/common/app/style'

const AREA_STRING_MAX_LENGTH = 3000

export const TextAreaVisual = {
  NORMAL: 'NORMAL',
  INVERSION: 'INVERSION'
} as const

export type TextAreaVisual = (typeof TextAreaVisual)[keyof typeof TextAreaVisual]

export type TextAreaPx = 13 | 14 | 16

interface Props {
  name?: string
  px?: TextAreaPx
  pcPx?: TextAreaPx
  value: string

  onChange(event: ChangeEvent<HTMLTextAreaElement>): void

  onBlur?(event: ChangeEvent<HTMLTextAreaElement>): void

  maxLength?: number
  placeholder?: string
  autoComplete?: string
  autoFocus?: boolean
  error?: boolean
  visual?: TextAreaVisual
}

const TextArea = ({
  name = '',
  px = 14,
  pcPx,
  value,
  onChange,
  onBlur,
  maxLength = AREA_STRING_MAX_LENGTH,
  placeholder = '',
  autoComplete = 'off',
  autoFocus = false,
  error = false,
  visual = TextAreaVisual.NORMAL
}: Props) => {
  return (
    <>
    <textarea
      className={
        'c-textarea' +
        addClass('c-textarea--visual-normal', visual == TextAreaVisual.NORMAL) +
        addClass('c-textarea--error', error)
      }
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={maxLength}
      placeholder={placeholder}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
    />
      <style jsx>{`
        textarea {
          font-size: ${px}px;
        }

        @media ${STYLE_MEDIA_PC} {
          textarea {
            font-size: ${pcPx ?? px}px;
          }
        }
      `}</style>
    </>
  )
}

export default TextArea
