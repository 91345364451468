export const Color = {
  PRIMARY: '#222',
  SECONDARY: '#999',
  THIRD: '#e75423',
  ASSERTION: '#cc0214',
  SUPPORT: '#9ab626',
  SUPPORT_SUB: '#005b42',
  LINK: '#008fbf',
  INVERSION: '#fff',
  EMPHASIS: '#cc0214',
  EMPHASIS_SUB: '#ffc52e',
  LOW_PRIORITY: '#f2f2f2',
  THIRD_LIGHT: '#eb764f',
  STORE_PICK: '#1b2d74',
  BG_EMPHASIZE: '#fbf3e4',
  BG_ALERT: '#ffdfd9',
  BEIGE_MAIN: '#f3ebe9',
  LINE: '#ddd',
  INACTIVE: '#aaa'
} as const
export type Color = (typeof Color)[keyof typeof Color]

export const textColorClass = (color: Color): string => {
  switch (color) {
    case Color.PRIMARY:
      return 'u-color-text--primary'
    case Color.SECONDARY:
      return 'u-color-text--secondary'
    case Color.THIRD:
      return 'u-color-text--third'
    case Color.ASSERTION:
      return 'u-color-text--assertion'
    case Color.SUPPORT:
      return 'u-color-text--support'
    case Color.SUPPORT_SUB:
      return 'u-color-text--support-sub'
    case Color.LINK:
      return 'u-color-text--link'
    case Color.INVERSION:
      return 'u-color-text--inversion'
    case Color.EMPHASIS:
      return 'u-color-text--emphasis'
    case Color.EMPHASIS_SUB:
      return 'u-color-text--emphasis-sub'
    case Color.LOW_PRIORITY:
      return 'u-color-text--low-priority'
    case Color.THIRD_LIGHT:
      return 'u-color-text--third-light'
    case Color.STORE_PICK:
      return 'u-color-text--store-pick'
  }
  return ''
}
