import { Color } from '~/common/utils/color'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  color?: Color | string
}

const AreaMessage = ({ children, color = Color.BG_EMPHASIZE }: Props) => {
  return (
    <>
      <div className='l-area-message'>{children}</div>
      <style jsx>{`
        .l-area-message {
          background: ${color};
        }
      `}</style>
    </>
  )
}

export default AreaMessage
