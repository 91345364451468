import React, { useEffect, useState, useCallback, useRef } from 'react'

/**
 * https://zenn.dev/catnose99/articles/0f0bb01ee6a940
 */

const CHECK_DURATION_MS = 100
const HEADER_Y_DIFF = 100

const useThrottle = <T extends any>(
  fn: (args?: T) => void,
  durationMS: number
) => {
  const scrollingTimer = useRef<undefined | NodeJS.Timeout>()
  return useCallback(
    (args?: T) => {
      if (scrollingTimer.current) return
      scrollingTimer.current = setTimeout(() => {
        fn(args)
        scrollingTimer.current = undefined
      }, durationMS)
    },
    [scrollingTimer, fn, durationMS]
  )
}

export const useOffsetTop = (ref?: React.RefObject<HTMLElement>) => {
  const [viewportTop, setViewportTop] = useState<number | undefined>(undefined)
  const [pageOffsetTop, setPageOffsetTop] = useState<number | undefined>(undefined)

  const handler = useThrottle(() => {
    if (!ref?.current) return

    const clientRect = ref.current.getBoundingClientRect()
    setViewportTop(clientRect.top)
    const newPageOffsetTop = clientRect.top + window.pageYOffset
    setPageOffsetTop(newPageOffsetTop)
  }, CHECK_DURATION_MS)

  useEffect(() => {
    if (!ref?.current) return

    handler()
    window.addEventListener('scroll', handler)

    return () => window.removeEventListener('scroll', handler)
  }, [handler])

  return { viewportTop, pageOffsetTop }
}

export const useScrollToTag = (id: string, headerDiff = false) => {
  const targetEl = typeof document !== 'undefined' ? document.getElementById(id) : undefined
  return () => {
    const top = targetEl?.getBoundingClientRect()['top']
    top && scrollTo({ top: headerDiff ? top - HEADER_Y_DIFF : top, behavior: 'smooth' })
    // targetEl?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}
