import { ChildProps } from '~/common/utils/props'
import { addClass } from '~/common/utils/html'

interface Props extends ChildProps {
  wide?: boolean
  shrink?: boolean
}

const FormInput = ({ children, wide = false, shrink = false }: Props) => {
  return (
    <div className={'l-form-input' + addClass('l-form-input--wide', wide)
      + addClass('l-form-input--shrink', shrink)}>
      {children}
    </div>
  )
}

export default FormInput
