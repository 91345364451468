import { ChildProps } from '~/common/utils/props'

const ScreenMessage = ({ children }: ChildProps) => {
  return (
    <div className='c-message-screen'>
      <p>{children}</p>
    </div>
  )
}
export default ScreenMessage
