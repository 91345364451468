import { MouseEvent } from 'react'
import { ChildProps } from '~/common/utils/props'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import { addClass } from '~/common/utils/html'

export const ModalFrameSize = {
  NORMAL: 'NORMAL',
  LOW: 'LOW',
  NO: 'NO',
  AUTO: 'AUTO'
} as const

export type ModalFrameSize = (typeof ModalFrameSize)[keyof typeof ModalFrameSize]

interface Props extends ChildProps {
  onClickClose(e: MouseEvent<HTMLElement>): void
  scrollable?: boolean
  center?: boolean
  size?: ModalFrameSize
}

const ModalFrame = ({
  children,
  onClickClose,
  scrollable = false,
  center = false,
  size = ModalFrameSize.NORMAL
}: Props) => {
  return (
      <div className={'l-modal-content' +
        addClass(size == ModalFrameSize.NORMAL ? 'l-modal-content--frame-normal' :
          size == ModalFrameSize.LOW ? 'l-modal-content--frame-low' :
            size == ModalFrameSize.NO ? 'l-modal-content--frame-no' : 'l-modal-content--frame-auto')}>
      <button className='l-modal-close' onClick={onClickClose}>
        <Icon color={'inherit'} size={40} type={IconType.CLOSE_MODAL} />
      </button>
      <div
        className={'l-modal-content__in'
          + addClass('l-modal-content__in--scrollable', scrollable)
          + addClass('l-modal-content__in--center', center)}>
        {children}
      </div>
    </div>
  )
}

export default ModalFrame
