// import moment from 'moment'

import { Type } from './type'
import { FormWidgetError, FormWidgetErrors } from './error'
import {
  PASSWORD_MIN_LENGTH,
  TEXT_STRING_MAX_LENGTH,
  TEXTAREA_STRING_MAX_LENGTH
} from '~/common/form/parameter'

export const MASKING_PASSWORD = '**********'
const RE_SUFFIX = '_re'

export interface Validations {
  [index: string]: FormWidgetError[]
}

export const isMaskingPassword = (key: string, value: any): boolean => {
  return key == 'password' && value == MASKING_PASSWORD
}

export const validate = (inputs: Type, validations: Validations): FormWidgetErrors => {
  let errors: FormWidgetErrors = {}
  Object.keys(validations).forEach(key => {
    const input = inputs[key]
    if (isMaskingPassword(key, input)) {
      return
    }
    const error = validations[key].filter(validation => {
      switch (validation) {
        case FormWidgetError.IS_EMPTY:
          return isEmpty(input)
        case FormWidgetError.NOT_INTEGER:
          return notInteger(input)
        case FormWidgetError.NOT_ALPHA_NUMERIC:
          return notAlphaNumeric(input)
        case FormWidgetError.NOT_ALPHA_NUMERIC_SYMBOL:
          return notAlphaNumericSymbol(input)
        case FormWidgetError.NOT_INCLUDE_ALPHA_NUMERIC:
          return notIncludeAlphaNumeric(input)
        case FormWidgetError.NOT_EMAIL:
          return notEmail(input)
        case FormWidgetError.NOT_POSTAL_CODE:
          return notPostalCode(input)
        // case FormWidgetError.NOT_DATE:
        //   return notDate(input)
        case FormWidgetError.OVER_TEXT_STRING_MAX:
          return overStringLength(input, TEXT_STRING_MAX_LENGTH)
        case FormWidgetError.OVER_TEXTAREA_STRING_MAX:
          return overStringLength(input, TEXTAREA_STRING_MAX_LENGTH)
        case FormWidgetError.BELOW_PASSWORD_MIN_LENGTH:
          return belowStringLength(input, PASSWORD_MIN_LENGTH)
        case FormWidgetError.NOT_CHECKED:
          return notChecked(input)
        case FormWidgetError.NOT_RE:
          return notSameValueAsConfirmed(input, key, inputs)
        case FormWidgetError.IS_NOT_TRUE:
          return !Boolean(Number(input))
        case FormWidgetError.IS_NOT_FALSE:
          return Boolean(Number(input))
      }
    })
    errors[key] = error
  })

  return errors
}

const isEmpty = <T extends string | number>(value: T): boolean => {
  if (value == null || value == '' || value == undefined) {
    return true
  }
  return false
}

const notInteger = (value: number): boolean => {
  if (isEmpty(value)) {
    return false
  }
  return isNaN(value)
}

const notAlphaNumeric = (value: string): boolean => {
  if (isEmpty(value)) {
    return false
  }
  return !/^[0-9a-zA-Z]+$/i.test(value)
}

const notAlphaNumericSymbol = (value: string): boolean => {
  if (isEmpty(value)) {
    return false
  }
  return !/^[0-9a-zA-Z!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]+$/i.test(value)
}

const notIncludeAlphaNumeric = (value: string): boolean => {
  if (isEmpty(value)) {
    return false
  }
  return !/^(?=.*?[a-z])(?=.*?\d)/i.test(value)
}

const notEmail = (value: string): boolean => {
  if (isEmpty(value)) {
    return false
  }
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}

const notPostalCode = (value: string): boolean => {
  if (isEmpty(value)) {
    return false
  }
  return !/^[0-9-]+$/i.test(value)
}

// const notDate = (value: moment.Moment): boolean => {
//   if (isEmpty(value)) {
//     return false
//   }
//   return !moment(value).isValid()
// }

const overStringLength = (value: string, length: number): boolean => {
  if (isEmpty(value)) {
    return false
  }
  return value.length > length
}

const belowStringLength = (value: string, length: number): boolean => {
  if (isEmpty(value)) {
    return true
  }
  return value.length < length
}

const notChecked = (value: boolean): boolean => {
  return !value
}

const notSameValueAsConfirmed = (value: string, key: string, inputs: Type): boolean => {
  if (isEmpty(value)) {
    return false
  }
  const confirmedKey = key.replace(RE_SUFFIX, '')
  const confirmed = inputs[confirmedKey]
  return value != confirmed
}

export const isEmail = (text: string) => {
  return !notEmail(text)
}
