import { MouseEvent } from 'react'
import { Color } from '~/common/utils/color'
import Text from '~/components/atoms/text/Text'
import InternalLink from '~/components/utils/link/InternalLink'

interface Props {
  textTop: string
  textBottom: string
  link?: string
  achieved: boolean
  achieveText: string

  onClick?(e: MouseEvent<HTMLElement>): void
}

const PointAchieveItem = ({ textTop, textBottom, link, achieved, achieveText }: Props) => {
  const achievedClassName = 'p-point-achieve-item__status p-point-achieve-item__status--achieve'
  const notAchievedClassName =
    'p-point-achieve-item__status p-point-achieve-item__status--not_achieve'
  return (
    <>
      {link ? (
        <InternalLink className='p-point-cp-item p-point-cp-item--link' link={link}>
          <div className='p-point-cp-item__content'>
            <Text px={8} color={Color.ASSERTION} bold>
              {textTop}
            </Text>
            <Text px={10} color={Color.PRIMARY} bold>
              {textBottom}
            </Text>
          </div>
          <div className={achieved ? achievedClassName : notAchievedClassName}>
            <p className='p-point-achieve-item__status__mark'>{achieved ? '○' : '×'}</p>
            <p className='p-point-achieve-item__status__detail'>{achieveText}</p>
          </div>
        </InternalLink>
      ) : (
        <div className='p-point-cp-item'>
          <div className='p-point-cp-item__content'>
            <Text px={8} color={Color.ASSERTION} bold>
              {textTop}
            </Text>
            <Text px={10} color={Color.PRIMARY} bold>
              {textBottom}
            </Text>
          </div>
          <div className={achieved ? achievedClassName : notAchievedClassName}>
            <p className='p-point-achieve-item__status__mark'>{achieved ? '○' : '×'}</p>
            <p className='p-point-achieve-item__status__detail'>{achieveText}</p>
          </div>
        </div>
      )}
    </>
  )
}
export default PointAchieveItem
