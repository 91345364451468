import ImageFitContain from '~/components/utils/image/ImageFitCover'
import InternalLink from '~/components/utils/link/InternalLink'
import ExternalLink from '~/components/utils/link/ExternalLink'

interface Props {
  src: string
  alt: string
  link?: string
  external?: boolean
}

const PcSideBanner = ({ src, alt, link, external }: Props) => {
  const Tag = external ? ExternalLink : InternalLink
  return (
    <>
      {link ? (
        <Tag link={link} className='c-image-banner-pc-side'>
          <ImageFitContain src={src} alt={alt} />
        </Tag>
      ) : (
        <div className='c-image-banner-pc-side'>
          <ImageFitContain src={src} alt={alt} />
        </div>
      )}
    </>
  )
}

export default PcSideBanner
