import React from 'react'

import { QUANTITY_MAX, QUANTITY_MIN } from '~/common/utils/input'
import { addClass } from '~/common/utils/html'
import { useQuantityInput } from '~/common/utils/input'

interface Props {
  value: number
  onChange?(value: number): void
  fill?: boolean
}

const Stepper = ({ value, onChange, fill = false }: Props) => {

  const {
    quantity,
    inputValue,
    setInputValue,
    onChangeValue,
    onBlur,
    onEnterKeyDown,
  } = useQuantityInput({ initialValue: value, onChange })

  return (
    <>
      <div className={'c-stepper' + addClass('c-stepper--fill', fill)}>
        <button
          className='c-stepper__decrement c-stepper__button'
          onClick={() => onChangeValue(Number(quantity) - 1)}
        >
          -
        </button>
        <input
          className={'c-stepper__count'}
          type={'number'}
          min={QUANTITY_MIN}
          max={QUANTITY_MAX}
          value={inputValue}
          size={2}
          tabIndex={0}
          onFocus={() => setInputValue(String(quantity))}
          onChange={(e) => setInputValue(String(e.target.value))}
          onBlur={onBlur}
          onKeyDown={onEnterKeyDown}
        />
        <button
          className='c-stepper__increment c-stepper__button'
          onClick={() => onChangeValue(Number(quantity) + 1)}
        >
          +
        </button>
      </div>
    </>
  )
}

export default Stepper
