import { Color } from '~/common/utils/color'
import InternalLink from '~/components/utils/link/InternalLink'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import Text from '~/components/atoms/text/Text'

interface Props {
  link: string
  iconType: IconType
  text: string
  // TODO: temporally for markt_web page
  noInternal?: boolean
}

const RowButton = ({ link, iconType, text, noInternal }: Props) => {
  return (
    <li className={'c-button-row'}>
      <InternalLink link={link} className={'c-button-row__in'} noInternal>
        <Icon type={iconType} color={Color.INVERSION} size={23} />
        <Text color={Color.INVERSION} px={12}>{text}</Text>
      </InternalLink>
    </li>
  )
}

export default RowButton
