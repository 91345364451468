import { cartPath } from '~/common/app/path'
import { useHideCartFooter, useShowCartFooter } from '~/common/app/store/carFooter'
import { useCart } from '~/common/api/cart'
import {
  useCartOperation,
  useDeliveryFeeDiff,
  useCartTotalQuantity,
  useMakeCartProductCardPropsList, useCartMessage
} from '~/common/app/cart'
import { useArea } from '~/common/api/area'
import { Mode, useUserMode } from '~/common/api/user'
import { modeLabel } from '~/common/app/delivery'
import CartFooter from '~/components/molecules/cart/CartFooter'

const AppCartFooter = () => {
  const show = useShowCartFooter()
  const close = useHideCartFooter()
  const cart = useCart()
  const area = useArea()
  const mode = useUserMode()
  const cartOperation = useCartOperation()
  const cartProductCardProps = useMakeCartProductCardPropsList(cart)
  const message = useCartMessage()

  const onDelete = (productClassId: number) => {
    cartOperation(productClassId, 0)
  }

  return (
    <CartFooter
      total={cart?.total_price}
      cartTotalQuantity={useCartTotalQuantity()}
      message={message}
      cartLink={cartPath()}
      deliveryText={(area && mode == Mode.DELIVERY) ? area.delivery_time : ''}
      items={cartProductCardProps}
      modeLabel={modeLabel(mode)}
      onDelete={onDelete}
      onOpen={show}
      onClose={close}
    />
  )
}

export default AppCartFooter
