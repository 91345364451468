import React, { ReactNode } from 'react'
import Image from 'next/image'

import { CouponTagContents } from '~/common/app/coupon'
import { addClass } from '~/common/utils/html'
import { Color } from '~/common/utils/color'
import { convertToPrice } from '~/common/utils/price'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import Tag, { TagType } from '~/components/atoms/tag/Tag'
import Stepper from '~/components/atoms/stepper/Stepper'
import Text from '~/components/atoms/text/Text'
import CheckBox, { CheckBoxSize } from '~/components/atoms/checkbox/CheckBox'
import ExternalLink from '~/components/utils/link/ExternalLink'
import ImageFitContain from '~/components/utils/image/ImageFitContain'
import CouponTagList from '~/components/molecules/tag/CouponTagList'

export const ProductCardType = {
  MICRO: 'micro',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const
export type ProductCardType = (typeof ProductCardType)[keyof typeof ProductCardType]

export const productCardTypeClass = (type: ProductCardType): string => {
  return 'p-product-card--' + type
}

const ImageWrapTag = ({ children, onClick, link }: {
  children: ReactNode,
  onClick?(): void,
  link?: string
}) => {
  return onClick ?
    <div className={'p-product-card__wrap'} onClick={onClick}>
      {children}
    </div> :
    <ExternalLink className={'p-product-card__wrap'} link={link as string}>
      {children}
    </ExternalLink>
}

interface MainBlockProps extends BaseProps {
  showTopRanking: boolean
}

const MainBlock = ({
  type = ProductCardType.LARGE,
  image,
  name,
  price,
  tax,
  tokubai = false,
  saleText,
  inactiveSaleText,
  seasonal = false,
  point,
  shopName,
  disableAm = false,
  disablePm = false,
  deliveryText,
  link,
  soldout = false,
  outOfSale = false,
  noSelectableText,
  rank,
  cartQuantity = 0,
  selected = false,
  couponTagContents = undefined,
  onClick = undefined,
  onClickChangeQuantity = undefined,
  onClickCartIn = undefined,
  onClickFavorite = undefined,
  onClickSelect = undefined,
  showTopRanking
}: MainBlockProps) => {
  const showButtonArea = type != ProductCardType.MICRO
  const showInLarge = type == ProductCardType.LARGE
  const shopNoticeSp =
    disableAm && !disablePm ? '午前便×' : !disableAm && disablePm ? '午後便×' : ''
  const shopNoticePc =
    disableAm && !disablePm ? '午前便不可' : !disableAm && disablePm ? '午後便不可' : ''
  const shopNameClass =
    shopNoticeSp && shopNoticePc ? 'p-product-card__store-information__store-name--short' : ''
  const isFutureSale = !saleText && inactiveSaleText

  return (
    <>
      {noSelectableText &&
        <div className='p-product-card__out-of-sale-text'>
          <Text color={Color.EMPHASIS} px={10}>
            {noSelectableText}
          </Text>
        </div>
      }
      {typeof onClickSelect == 'function' && (
        <div className='p-product-card__check'>
          <CheckBox
            name={''}
            value={true}
            checked={selected}
            onChange={onClickSelect}
            size={CheckBoxSize.LARGE}
          />
        </div>
      )}

      {!showInLarge && shopName && (
        <div className='p-product-card__store'>
          <Tag tagType={TagType.WIDE}>{shopName}</Tag>
        </div>
      )}

      <ImageWrapTag onClick={onClick} link={link}>
        <div className={'p-product-card__main'}>
          <ImageFitContain src={image} alt={name ?? ''} background />
          {(tokubai || saleText || inactiveSaleText) && (
            <div className='p-product-card__sale'>
              {tokubai && <Icon color={Color.EMPHASIS} type={IconType.TOKUBAI} size={24} />}
              {(saleText || inactiveSaleText) && (
                <span className='p-product-card__sale__period'>
                  <Text px={10} color={Color.EMPHASIS} bold vivid isHtml>
                    {saleText ?? inactiveSaleText ?? ''}
                  </Text>
                </span>
              )}
            </div>
          )}

          {seasonal && (
            <div className='p-product-card__seasonal'>
              <Icon color={Color.EMPHASIS} type={IconType.SEASONAL} size={32} />
            </div>
          )}
          {point !== undefined &&  (
            <div className='p-product-card__point'>
              <Text px={10} color={Color.SUPPORT_SUB} bold vivid>
                {point}
              </Text>
            </div>
          )}

          {price != undefined && (
            <div className='p-product-card__price'>
              <Text px={10} color={Color.EMPHASIS} bold vivid en>
                ¥
              </Text>
              <Text px={16} color={Color.EMPHASIS} bold vivid en>
                {convertToPrice(price)}
              </Text>
              <br className='u-hidden-pc'></br>
              <div>
                {tax != undefined && (
                  <Text px={10} vivid>
                    (税込¥{convertToPrice(tax)})
                  </Text>
                )}
              </div>
            </div>
          )}
        </div>

        {name && <p className='p-product-card__name'>{name}</p>}

      </ImageWrapTag>
      {showButtonArea && couponTagContents && (
        <CouponTagList couponTagContents={couponTagContents} />
      )}
      {showInLarge && shopName && (
        <div className='p-product-card__store-information'>
          <p className={'p-product-card__store-information__store-name' + addClass(shopNameClass)}>
            {shopName}
          </p>
          {shopNoticeSp && shopNoticePc && (
            <>
              <p className='p-product-card__store-information__delivery u-hidden-pc'>
                {shopNoticeSp}
              </p>
              <p className='p-product-card__store-information__delivery u-hidden-sp'>
                {shopNoticePc}
              </p>
            </>
          )}
        </div>
      )}

      {showButtonArea &&
        <>
          <div className='p-product-card__bottom-wrap'>
            {showInLarge && (
              <button className='p-product-card__favorite' onClick={onClickFavorite}>
                <Icon color={Color.INVERSION} type={IconType.STAR} size={14} />
              </button>
            )}

            {!link && cartQuantity > 0 ? (
              <div className='p-product-card__quantity'>
                <Stepper value={cartQuantity} onChange={onClickChangeQuantity} />
              </div>
            ) : outOfSale ? (
              <Button
                pcChildren={'類似商品を探す'}
                size={ButtonSize.MEDIUM}
                role={ButtonRole.LOW_PRIORITY_INVERSION}
                expanded
                link={link}
              >
                類似商品
              </Button>
            ) : isFutureSale ? (
              <Button size={ButtonSize.MEDIUM} role={ButtonRole.DEACTIVE} expanded disabled>
                現在価格と異なります
              </Button>
            ) : soldout ? (
              <Button size={ButtonSize.MEDIUM} role={ButtonRole.DEACTIVE} expanded disabled>
                品切れ
              </Button>
            ) : (
              <Button
                pcChildren={!link ? 'カートに追加' : '商品情報を見る'}
                size={ButtonSize.MEDIUM}
                role={ButtonRole.DECISION_INVERSION}
                iconType={!link ? IconType.ADD : IconType.DETAIL}
                onClick={onClickCartIn}
                link={link}
                expanded
                external
              >
                {!link ? 'カート' : '商品情報'}
              </Button>
            )}
          </div>

          {deliveryText && (
            <p className='p-product-card__delivery u-hidden-sp'>
              <Text px={10} color={Color.SUPPORT} bold>
                {deliveryText}
              </Text>
            </p>
          )}
        </>
      }

      {showTopRanking &&
        <div className='p-product-card-ranking__top-rank'>
          <Image src={'/assets/images/ranking-no' + rank + '.png'} alt='' width='40' height='40' />
        </div>
      }
    </>
  )
}

const Frame = ({ showTopRanking, favorite, children }: { showTopRanking: boolean, favorite?: boolean, children: ReactNode }) => {
  return (
    <>
      {showTopRanking ?
        <div className={
          'p-product-card-ranking__top-in' +
          addClass('p-product-card-ranking__top-in-favorite', favorite)
        }>{children}</div> :
        <>{children}</>
      }
    </>
  )
}

interface BaseProps {
  type: ProductCardType
  image: string
  name?: string
  price?: number
  tax?: number
  tokubai?: boolean
  saleText?: string
  inactiveSaleText?: string
  seasonal?: boolean
  point?: string
  string?: string
  shopName?: string
  disableAm?: boolean
  disablePm?: boolean
  deliveryText?: string
  link?: string
  favorite?: boolean
  soldout?: boolean
  outOfSale?: boolean
  noSelectableText?: string
  rank?: number
  cartQuantity?: number
  selected?: boolean
  couponTagContents? : CouponTagContents
  onClick?(): void
  onClickChangeQuantity?(quantity: number): void
  onClickCartIn?(): void
  onClickFavorite?(): void
  onClickSelect?(): void
}

const BaseProductCard = React.memo((props: BaseProps) => {
  const { rank, type, favorite, soldout, outOfSale } = props

  const showTopRanking = typeof rank != 'undefined' && rank > 0 && rank < 4
  const showUnderRanking = typeof rank != 'undefined' && rank > 3

  return (
    <>
      <li
        className={
          'p-product-card' +
          addClass(productCardTypeClass(type)) +
          addClass('p-product-card--is-favorite', favorite) +
          addClass('p-product-card--is-soldout', soldout) +
          addClass('p-product-card--is-out-of-sale', outOfSale) +
          addClass('p-product-card-ranking--top', showTopRanking) +
          addClass('p-product-card-ranking--under', showUnderRanking)
        }
      >
        <Frame showTopRanking={showTopRanking} favorite={favorite}>
          <MainBlock {...props} showTopRanking={showTopRanking} />
        </Frame>
      </li>
    </>
  )
})

export default BaseProductCard
