import { ChangeEvent, KeyboardEvent, MouseEvent } from 'react'
import { ChildProps } from '~/common/utils/props'
import { useOutsideClick } from '~/common/utils/outside'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import TextBox from '~/components/atoms/textbox/TextBox'
import Button, { ButtonRole } from '~/components/atoms/button/Button'
import { TextBoxVisual,TextBoxSize } from '~/components/atoms/textbox/TextBox'
import { ButtonSize } from '~/components/atoms/button/Button'
import { IconType } from '~/components/atoms/icon/Icon'
import LinkText from '~/components/atoms/text/LinkText'


interface Props extends ChildProps {
  text: string
  onClick(e: MouseEvent<HTMLElement>): void
  onCancelClick(e: MouseEvent<HTMLElement>): void
  onChange(event: ChangeEvent<HTMLInputElement>): void
  onKeyDown(e: KeyboardEvent<HTMLInputElement>): void
  outSideClick(): void
}

const SearchSuggest = ({children, text, onChange, onClick, onCancelClick, onKeyDown, outSideClick}: Props) => {
  const ref = useOutsideClick(() => {
    outSideClick()
  })

  return (
    <form>
      <div className='p-suggest-frame' ref={ref}>
        <div className='p-suggest-frame__sp-header'>
          <div className='p-suggest-textbox'>
            <TextBox
              px={16}
              visual={TextBoxVisual.INVERSION}
              size={TextBoxSize.WIDE}
              value={text}
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder={'キーワードを入力'}
              autoFocus={true}
            />{' '}
            <div className='p-suggest-textbox__button'>
              <Button
                size={ButtonSize.LARGE}
                role={ButtonRole.HIGH_PRIORITY_YELLOW}
                iconType={IconType.SEARCH}
                onClick={onClick}
                spTextNone
                px={14}
                iconSize={16}
              />
            </div>
          </div>
          <LinkText px={12} onClick={onCancelClick} bold>
            キャンセル
          </LinkText>
        </div>
        <ul className='p-suggest-frame__in'>
          <li className='p-suggest-frame__title'>
            <BlockTitle verticalBar margin={8}>
              キーワード検索
            </BlockTitle>
          </li>
          {children}
        </ul>
      </div>
    </form>
  )
}

export default SearchSuggest