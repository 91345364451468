import { ReactNode, useEffect } from 'react'
import css from 'styled-jsx/css'

import Product, { PointItem, ProductStatus } from '~/model/product'
import { Color } from '~/common/utils/color'
import { STYLE_MEDIA_PC, STYLE_MEDIA_SP } from '~/common/app/style'
import { useReviewList } from '~/common/api/review'
import { useAcceptableNextTime, useMakeShopNextAcceptableMessage } from '~/common/api/shop'
import { useFavoriteProductListApi } from '~/common/api/product'
import {
  useCartOperation,
  useConfirmAbuseAbilityCartIn,
  useConfirmMedicineCartIn,
  useProductCartQuantity
} from '~/common/app/cart'
import { Mode, useUserMode } from '~/common/api/user'
import { useFavoriteOperation } from '~/common/app/favorite'
import { productTitle } from '~/common/app/product'
import { pointCampaignPath } from '~/common/app/path'
import { sendCartItemAnalysisData } from '~/common/app/productSearch'
import { setProductRecentBrowsedCookie } from '~/common/app/user'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import HiddenPc from '~/components/utils/hidden/HiddenPc'
import ProductCategoryTags from '~/components/app/module/product/ProductCategoryTags'
import ProductImage from '~/components/app/module/product/ProductImage'
import Text from '~/components/atoms/text/Text'
import HiddenSp from '~/components/utils/hidden/HiddenSp'
import ProductDetail from '~/components/molecules/detail/ProductDetail'
import MarginBottom from '~/components/utils/margin/MarginBottom'

interface Props {
  product: Product
  content: ReactNode
  isModal?: boolean
}

const AppProductDetail = ({ product, content, isModal = false }: Props) => {
  const { className: imageClass, styles: imageStyles } = css.resolve`
    flex-shrink: 1.2;
    @media ${STYLE_MEDIA_SP} {
      width: 100%;
    }
    @media ${STYLE_MEDIA_PC} {
      max-width: ${isModal ? '800px' : '400px'};
    }
  `
  const { className: detailClass, styles: detailStyles } = css.resolve`
    width: 100%;
    overflow: hidden;
    @media ${STYLE_MEDIA_PC} {
      flex: ${isModal ? '' : '1'};
    }
  `

  useEffect(() => {
    setProductRecentBrowsedCookie(product.id)
  }, [])

  const reviews = useReviewList(product.id)
  const mode = useUserMode()
  const { data: favoriteProductList } = useFavoriteProductListApi()
  const favoriteIdList = favoriteProductList?.map((product) => product.id) ?? []
  const isFavorite = favoriteIdList?.includes(product.id) ?? false
  const acceptableNextTime = useAcceptableNextTime()
  const acceptableInfo = acceptableNextTime?.find((item) => item.shop_id == product.shop.id)
  const closeTime = acceptableInfo?.close_time ?? ''
  const makeShopNextAcceptableMessage = useMakeShopNextAcceptableMessage()

  const cartQuantity = useProductCartQuantity(product.product_class_id)
  const cartOperation = useCartOperation(() => {
    sendCartItemAnalysisData(product.searched_product_id)
  })
  const showConfirmMedicine = useConfirmMedicineCartIn(product.medicine_caution)
  const showConfirmAbuseAbility = useConfirmAbuseAbilityCartIn()
  const favoriteOperation = useFavoriteOperation()

  const onClickCartIn = () => {
    const cartIn = () => {
      cartOperation(product.product_class_id, 1)
    }
    product.is_medicine && product.abuse_ability
      ? showConfirmAbuseAbility(cartIn)
      : product.is_medicine
      ? showConfirmMedicine(cartIn)
      : cartIn()
  }
  const onClickChangeQuantity = (quantity: number) => {
    cartOperation(product.product_class_id, quantity, cartQuantity)
  }
  const onClickFavorite = () => {
    favoriteOperation(product.id, isFavorite)
  }

  let canUseCoupon = false
  const pointItems: PointItem[] = [
    {
      name: '基本ポイント',
      point: product.basic_add_point ?? 0,
      ratio: product.basic_point_rate ?? 0,
      link: ''
    }
  ]
  if (product.customer_add_point && product.customer_add_point > 0) {
    pointItems.push({
      name: '毎週利用特典',
      point: product.customer_add_point ?? 0,
      ratio: product.customer_point_rate ?? 0,
      link: pointCampaignPath()
    })
  }
  if (product.shop_bonus_add_point && product.shop_bonus_add_point > 0) {
    pointItems.push({
      name: 'ストアボーナス',
      point: product.shop_bonus_add_point ?? 0,
      ratio: product.shop_bonus_point_rate ?? 0,
      link: ''
    })
  }
  if (product.coupon_add_point && product.coupon_add_point > 0) {
    pointItems.push({
      name: 'クーポン',
      point: product.coupon_add_point ? product.coupon_add_point : 0,
      ratio: product.coupon_point_rate ?? 0,
      link: ''
    })
    canUseCoupon = true
  }

  return (
    <Segments spColumn={SegmentsType.COLUMN} pcColumn={SegmentsType.ROW} spGap={16} pcGap={24}>
      <HiddenPc>
        <MarginBottom marginSp={24}>
          <ProductCategoryTags product={product} />
        </MarginBottom>
        <Text px={12} bold>
          {product.place_of_origin}
        </Text>
        <Text px={20} tag='p' bold>
          {productTitle(product)}
        </Text>
        {product.is_order_item_split && (
          <Text px={12} color={Color.ASSERTION}>
            ※本商品の価格は目安となります。実際の価格は、商品の容量によって価格が変わります。
          </Text>
        )}
      </HiddenPc>
      <div className={imageClass}>
        <ProductImage product={product} expandable={!isModal} />
        <Text px={10} color={Color.SECONDARY}>
          ※実際にお届けする商品は、パッケージや産地などが写真と異なる場合があります。
        </Text>
      </div>
      {imageStyles}
      <div className={detailClass}>
        <HiddenSp>
          <ProductCategoryTags product={product} />
        </HiddenSp>
        <ProductDetail
          key={cartQuantity}
          name={productTitle(product)}
          maker={product.place_of_origin ?? ''}
          price={
            product.on_sale && product.sale_price != undefined ? product.sale_price : product.price
          }
          taxInPrice={
            product.on_sale && product.sale_price_inc_tax != undefined
              ? product.sale_price_inc_tax
              : product.price_inc_tax
          }
          point={product.total_add_point ?? 0}
          pointItems={pointItems}
          canUseCoupon={canUseCoupon}
          shopName={product.shop.name}
          orderTimeTo={closeTime}
          deliveryTimeTo={makeShopNextAcceptableMessage(product.shop) ?? ''}
          temperature={product.temperature_zone ?? ''}
          code={product.item_code ?? ''}
          sku={product.product_class_id}
          description={product.description_detail ?? ''}
          onClickCartIn={onClickCartIn}
          onClickChangeQuantity={onClickChangeQuantity}
          onClickFavorite={onClickFavorite}
          inversion={isModal}
          cartQuantity={cartQuantity}
          reviews={reviews}
          content={content}
          favorite={isFavorite}
          ingredient={product.ingredient}
          bestByDate={product.best_by_date}
          saleLimit={product.sale_limit}
          pickupTimeLimitId={product.pickup_time_limit_id}
          isMedicine={product.is_medicine}
          medicineFiles={product.medicine_files}
          expirationLimit={product.medicine_expiration_limit}
          isOrderItemSplit={product.is_order_item_split}
          isPickup={mode == Mode.PICKUP}
          soldout={!product.is_stock_find}
          outOfSale={product.product_status_id != ProductStatus.SHOW}
          coupons={product.coupons}
        />
        {detailStyles}
      </div>
    </Segments>
  )
}

export default AppProductDetail
