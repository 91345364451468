import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import Text from '~/components/atoms/text/Text'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'

interface Props {
  name: string
  addPointRate?: number
  discountPrice?: number
  expireDate?: string
  themeColor?: string
  bgWhite?: boolean
  onClick?(): void,
}

const SmallCouponCard = ({
  themeColor,
  name,
  addPointRate,
  discountPrice,
  expireDate,
  bgWhite = false,
  onClick = undefined
}: Props) => {
  return (
    <>
      <li className={'p-coupon-card-small' + addClass('p-coupon-card-small--bg-white', bgWhite)
      + addClass('p-coupon-card-small--pointer', onClick !== undefined)} onClick={onClick}>
        <Segments spGap={4} spColumn={SegmentsType.COLUMN} pcColumn={SegmentsType.COLUMN}>
          <Text color={Color.INVERSION} px={10} spOmitTwo tag={'p'}>
            {name}
          </Text>
          <Segments spBetween pcBetween fitWidth spAlignCenter pcAlignCenter>
            {addPointRate && (
              <Text color={Color.INVERSION} px={12} bold>
                ポイント{addPointRate}倍
              </Text>
            )}
            {discountPrice && (
              <Text color={Color.INVERSION} px={12} bold>
                {discountPrice}円OFF
              </Text>
            )}
            <Text color={Color.INVERSION} px={10}>
              〜{expireDate}
            </Text>
          </Segments>
        </Segments>
      </li>
      <style jsx>{`
        .p-coupon-card-small {
          background-color: ${themeColor};
        }
      `}</style>
    </>
  )
}

export default SmallCouponCard
