import { Store, useStoreSWR } from '~/common/app/store/store'
import { AppModalProps } from '~/components/app/modal/AppModal'

export const useGetModal = (): AppModalProps | null => {
  const { data } = useStoreSWR<AppModalProps | null, Error>(Store.MODAL, null)
  return data as AppModalProps | null
}

export const useShowModal = (): ((modal: AppModalProps) => void) => {
  const { mutate } = useStoreSWR<AppModalProps | null, Error>(Store.MODAL)
  return (modal: AppModalProps) => mutate(modal)
}

export const useCloseModal = (): (() => void) => {
  const { mutate } = useStoreSWR<AppModalProps | null, Error>(Store.MODAL)
  return () => mutate(null)
}

export const useModalShowing = (): boolean => {
  const data = useGetModal()
  return !!data
}
