import { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'

import Product from '~/model/product'

import { definedFilter } from '~/common/utils/array'
import { ApiPath, apiPutFetcher, apiPostFetcher } from '~/common/api/base'
import { useWriteNotice } from '~/common/app/store/notice'
import { useHideLoading, useShowLoading } from '~/common/app/store/loading'
import { useFavoriteProductListApi } from '~/common/api/product'
import { convertToFormError, useRequestErrored } from '~/common/form/error'

export const Operation = {
  ADD: 'add',
  DELETE: 'delete'
} as const
export type Operation = (typeof Operation)[keyof typeof Operation]

export interface FavoriteOperationProps {
  operation: Operation
  id: number
}

export const useFavoriteOperationApi = () => {
  const { mutate } = useSWRConfig()
  const { data: products } = useFavoriteProductListApi()
  const showLoading = useShowLoading()
  const hideLoading = useHideLoading()
  const errored = useRequestErrored()

  const { trigger } = useSWRMutation(ApiPath.FAVORITE_OPERATION, apiPutFetcher)
  return (props: FavoriteOperationProps) => {
    const productData = { id: props.id } as Product
    const mergedProducts = products ? props.operation == Operation.ADD ?
      [...products, productData] :
      products.filter(product => product.id != props.id) : [productData]
    const options = {
      onSuccess: () => {
        mutate(ApiPath.PRODUCT_FAVORITE_LIST, mergedProducts)
        mutate(ApiPath.CP_POINT_FAVORITE)
        hideLoading()
      },
      onError: (error: Response) => {
        hideLoading()
        errored(error)
      }
    }
    showLoading()
    trigger({
      operation: props.operation,
      id: props.id
    }, options)
  }
}

export const useSortFavoriteOperationApi = (favoriteProductIds: number[]) => {
  const writeNotice = useWriteNotice()
  const showLoading = useShowLoading()
  const hideLoading = useHideLoading()
  const errored = useRequestErrored()
  const { trigger } = useSWRMutation(ApiPath.FAVORITE_SORT_OPERATION, apiPostFetcher)

  const sortFavoriteOperation = () => {
    const options = {
      onSuccess: () => {
        hideLoading()
        writeNotice('並び替えを完了しました。')
      },
      onError: (error: Response) => {
        hideLoading()
        errored(error)
      }
    }
    showLoading()
    trigger(
      {
        favorite_product_ids: favoriteProductIds
      },
      options
    )
  }

  return sortFavoriteOperation
}