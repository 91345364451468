import React, { ChangeEvent } from 'react'

import { WidgetChoice } from '~/common/form/choice'
import { addClass } from '~/common/utils/html'

interface Props {
  name: string
  choices: WidgetChoice<string | number>[]
  value: string | number

  onChange(e: ChangeEvent<HTMLSelectElement>): void

  error?: boolean
}

const PullDown = ({ name, choices, value, onChange, error = false }: Props) => {
  return (
    <div className={'c-pull-down'}>
      <select
        name={name}
        className={'c-pull-down__select' + addClass('c-pull-down__select--error', error)}
        onChange={onChange} value={value || ''}
      >
        {choices.map((choice: WidgetChoice<string | number>, index: number) =>
          <option key={index} value={choice.value}>{choice.name}</option>
        )}
      </select>
    </div>
  )
}

export default PullDown
