import { useShop } from '~/common/api/shop'
import {
  contactPath,
  guidePath,
  helpAgreementPath,
  helpCouponPath,
  helpDeliveryAreaPath,
  helpTradeLawPath
} from '~/common/app/path'
import FooterFrame from '~/components/layouts/footer/FooterFrame'
import FooterCenter from '~/components/layouts/footer/FooterCenter'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import FooterContentWrap from '~/components/layouts/footer/FooterContentWrap'
import OpinionForm from '~/components/app/form/opinion/OpinionForm'
import LinkList from '~/components/molecules/link/LinkList'

const FooterContent = () => {
  const shop = useShop()

  return (
    <FooterFrame>
      <FooterCenter>
        <Segments pcColumn={SegmentsType.ROW_REVERSE} spColumn={SegmentsType.COLUMN} pcGap={32}
                  spAlignCenter>
          <FooterContentWrap>
            <OpinionForm />
          </FooterContentWrap>
          <FooterContentWrap>
            <Segments
              pcColumn={SegmentsType.COLUMN}
              spColumn={SegmentsType.COLUMN}
            >
              <LinkList
                linkItemSets={[
                  {
                    linkItems: [
                      { link: guidePath(), text: 'よくあるご質問', external: false, noInternal: true },
                      {
                        link: helpDeliveryAreaPath(),
                        text: '店舗別お届けエリア',
                        external: false
                      },
                      {
                        link: guidePath() + '#delivery',
                        text: '配送料・利用料',
                        external: false,
                        noInternal: true
                      },
                      {
                        link: guidePath() + '#delivery',
                        text: 'お届け時間',
                        external: false,
                        noInternal: true
                      }
                    ]
                  },
                  {
                    linkItems: [
                      {
                        link: guidePath() + '#payment',
                        text: 'お支払い方法',
                        external: false,
                        noInternal: true
                      },
                      {
                        link: guidePath() + '#point',
                        text: 'ポイントの貯め方',
                        external: false,
                        noInternal: true
                      },
                      {
                        link: guidePath() + '#point',
                        text: 'ポイントの使い方',
                        external: false,
                        noInternal: true
                      },
                      {
                        link: helpCouponPath(),
                        text: 'クーポンの使い方',
                        external: false,
                        noInternal: true
                      }
                    ]
                  },
                  {
                    linkItems: [
                      {
                        link: helpTradeLawPath(),
                        text: '特定商取引法に基づく表示',
                        external: false,
                        noInternal: true
                      },
                      {
                        link: helpAgreementPath(),
                        text: '利用規約',
                        external: false
                      },
                      {
                        link: '/help/privacy',
                        text: 'プライバシーポリシー',
                        external: false
                      },
                      {
                        link: contactPath(shop?.baseInfo),
                        text: 'お問い合わせ',
                        external: false,
                        noInternal: true
                      }
                    ]
                  }
                ]}
              />
            </Segments>
          </FooterContentWrap>
        </Segments>
      </FooterCenter>
    </FooterFrame>
  )
}

export default FooterContent
