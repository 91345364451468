import { ChildProps } from '~/common/utils/props'

const DrawerArea = ({ children }: ChildProps) => {
  return (
    <div className={'l-drawer-area'}>
      {children}
    </div>
  )
}

export default DrawerArea
