import Text from '~/components/atoms/text/Text'
import { ClickProps } from '~/common/utils/props'

const Close = ({ onClick }: ClickProps) => {
  return (
    <button className={'c-icon-close'} onClick={onClick}>
      <span className={'c-icon-close__icon'} />
      <Text px={8}>閉じる</Text>
    </button>
  )
}

export default Close
