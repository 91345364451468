import { ChangeEvent, MouseEvent, useCallback, useState } from 'react'
import { Type } from '~/common/form/type'
import { validate, Validations } from '~/common/form/validation'
import { FormError, formErrorExists, FormWidgetError, FormWidgetErrors } from '~/common/form/error'

type HandleChange = (params: { [name: string]: any }) => void

export interface FormPartsProps<T> {
  name: string
  value: T

  onChange(param: { [name: string]: T }): void

  label?: string
  required?: boolean
  errors?: FormWidgetError[]

  onError?(param: { [name: string]: any }): void
}

export const useFormState = <T extends Type | FormWidgetErrors>(initialState: T): [T, HandleChange] => {
  const [state, setState] = useState<T>(initialState)

  const handleChange: HandleChange = useCallback<HandleChange>(params => {
    setState(state => ({
      ...state,
      ...params
    }))
  }, [])

  return [state, handleChange]
}

export const useFormErrorState = <T extends Type>() => {
  const formError: FormError<T> = {
    formWidgetErrors: {} as T
  }
  return useFormState<FormError<T>>(formError)
}

export const handleFormSubmit = <T extends FormWidgetErrors>(formData: Type, validations: Validations,
  call: (type: Type) => void, setError: (formError: FormError<T>) => void, e: MouseEvent<HTMLFormElement>) => {
  e && e.preventDefault()
  const formWidgetErrors: FormWidgetErrors = validate(formData, validations)
  const validatedFormError: FormError<T> = { formWidgetErrors: formWidgetErrors as T }
  setError(validatedFormError)
  if (!formErrorExists(validatedFormError)) {
    call(formData)
  }
}

export const useInputHandleChange = <T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(name: string, onChange: HandleChange) => useCallback(
  (e: ChangeEvent<T>) => {
    onChange({ [name]: e.target.value })
  },
  [name, onChange]
)

export const useValueHandleChange = (name: string, onChange: HandleChange) => useCallback(
  (value: number | string) => {
    onChange({ [name]: value })
  },
  [name, onChange]
)
