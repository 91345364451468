import { Color } from '~/common/utils/color'
import { myPageLoginPath, entryPath } from '~/common/app/path'
import Text from '~/components/atoms/text/Text'
import SpFooter from '~/components/layouts/footer/SpFooter'
import LinkText from '~/components/atoms/text/LinkText'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import { IconType } from '~/components/atoms/icon/Icon'

const LoginFooter = () => {
  return (
    <SpFooter frame horizon>
      <Segments spColumn={SegmentsType.COLUMN}>
        <p>
          <Text px={12} bold>
            ようこそ、マルクトへ！
          </Text>
          <Text px={12} bold color={Color.THIRD}>
            初回送料無料！
          </Text>
        </p>
        <LinkText px={12} arrow={Color.LINK} noInternal link={entryPath()}>
          新規会員登録はこちら
        </LinkText>
      </Segments>
      <Button role={ButtonRole.HIGH_PRIORITY_DEEP_GREEN} iconType={IconType.KEY}
              link={myPageLoginPath()} size={ButtonSize.LARGE} noShrink>
        ログイン
      </Button>
    </SpFooter>
  )
}

export default LoginFooter
