import { STYLE_MEDIA_PC } from '~/common/app/style'
import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

export type TextPx = 8 | 10 | 12 | 13 | 14 | 16 | 18 | 20 | 22 | 24 | 28 | 40

interface Props extends ChildProps {
  px?: TextPx
  pcPx?: TextPx
  color?: Color
  /* Use only
  Color.PRIMARY
  or Color.SECONDARY
  or Color.THIRD
  or Color.ASSERTION
  or Color.SUPPORT
  or Color.SUPPORT_SUB
  or Color.LINK
  or Color.INVERSION
  or Color.EMPHASIS
  */
  tag?: 'span' | 'p'
  bold?: boolean
  vivid?: boolean
  en?: boolean
  asterisk?: boolean
  noShrink?: boolean
  omit?: boolean
  spOmitTwo?: boolean
  wide?: boolean
  center?: boolean
  isHtml?: boolean
  nowrap?: boolean
  lineHeight?: 120 | 150
}

const Text = ({
  children,
  px,
  pcPx,
  color = Color.PRIMARY,
  tag = 'span',
  bold = false,
  vivid = false,
  en = false,
  asterisk = false,
  noShrink = false,
  omit = false,
  spOmitTwo = false,
  wide = false,
  center = false,
  isHtml = false,
  nowrap = false,
  lineHeight = 150
}: Props) => {
  const Tag = tag
  const className =
    'c-text-text' +
    addClass('c-text-text--vivid', vivid) +
    addClass('c-text-text--en', en) +
    addClass('c-text-text--bold', bold) +
    addClass('c-text-text--asterisk', asterisk) +
    addClass('c-text-text--no-shrink', noShrink) +
    addClass('c-text-text--omit', omit) +
    addClass('c-text-text--omit-02line-sp', spOmitTwo) +
    addClass('c-text-text--center', center) +
    addClass('c-text-text--wide', wide) +
    addClass('c-text-text--nowrap', nowrap)

  return (
    <>
      {isHtml ? (
        <Tag className={className} dangerouslySetInnerHTML={{ __html: children as string }} />
      ) : (
        <Tag className={className}>{children}</Tag>
      )}
      <style jsx>{`
        ${tag} {
          color: ${color};
          font-size: ${px ? px + 'px' : ''};
          line-height: ${lineHeight}%;
        }

        @media ${STYLE_MEDIA_PC} {
          ${tag} {
            font-size: ${pcPx ?? px ? (pcPx ?? px) + 'px' : ''};
          }
        }
      `}</style>
    </>
  )
}

export default Text
