import { ChangeEvent, MouseEvent, KeyboardEvent, FocusEvent } from 'react'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import { IconType } from '~/components/atoms/icon/Icon'
import TextBox, { TextBoxVisual } from '~/components/atoms/textbox/TextBox'

interface Props {
  value: string

  onChange(event: ChangeEvent<HTMLInputElement>): void

  onClick(e: MouseEvent<HTMLElement>): void

  onKeyDown(e: KeyboardEvent<HTMLInputElement>): void

  onFocus(e: FocusEvent<HTMLInputElement>): void

  placeholder?: string
}

const SearchTextBox = ({ value, onChange, onClick, onKeyDown, onFocus, placeholder = '' }: Props) => {
  return (
    <div className='p-search-textbox'>
      <TextBox
        visual={TextBoxVisual.INVERSION}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        placeholder={placeholder}
      />{' '}
      <div className='p-search-textbox__button'>
        <Button
          size={ButtonSize.WIDE_MEDIUM}
          role={ButtonRole.HIGH_PRIORITY_YELLOW}
          iconType={IconType.SEARCH}
          onClick={onClick}
          pcChildren='検索'
          spTextNone
          px={14}
          iconSize={16}
        />
      </div>
    </div>
  )
}

export default SearchTextBox
