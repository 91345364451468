import React, { AnchorHTMLAttributes, MutableRefObject } from 'react'
import { ClassProps } from '~/common/utils/props'

interface Props extends ClassProps, AnchorHTMLAttributes<HTMLAnchorElement> {
  link: string
  anchorRef?: MutableRefObject<HTMLAnchorElement>
}

const ExternalLink = ({ children, link, className, anchorRef, ...props }: Props) => {
  return (
    <a href={link} className={className} target='_blank' rel='noopener noreferrer' {...props}>
      {children}
    </a>
  )
}

export default ExternalLink
