import Image from 'next/image'

import { useHideNavi, useNaviOpening } from '~/common/app/store/navi'
import { useUserApi, useUserCustomer } from '~/common/api/user'
import { useCartApi } from '~/common/api/cart'
import { useEnabledAreaCouponsOwnedCount } from '~/common/api/coupon'
import { useShowModal } from '~/common/app/store/modal'
import { convertToPrice } from '~/common/utils/price'
import { useCartMessage } from '~/common/app/cart'
import { Color } from '~/common/utils/color'
import {
  cartPath,
  contactPath,
  guidePath,
  helpAboutPath,
  helpAgreementPath,
  helpTradeLawPath,
  logoutPath,
  myPageCouponPath,
  myPageFavoritePath,
  myPagePath,
  myPagePointLogPath,
  myPagePurchasedProductPath
} from '~/common/app/path'
import { useRouteChanged } from '~/common/utils/route'
import Background from '~/components/layouts/background/Background'
import Drawer from '~/components/layouts/drawer/Drawer'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import Text from '~/components/atoms/text/Text'
import LinkText from '~/components/atoms/text/LinkText'
import { Modal } from '~/components/app/modal/AppModal'
import InternalLink from '~/components/utils/link/InternalLink'
import CartIcon from '~/components/molecules/icon/CartIcon'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import RowButtonList from '~/components/atoms/button/RowButtonList'
import RowButton from '~/components/atoms/button/RowButton'
import { IconType } from '~/components/atoms/icon/Icon'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import HiddenPc from '~/components/utils/hidden/HiddenPc'
import DrawerScroll from '~/components/layouts/drawer/DrawerScroll'
import ShopList from '~/components/app/module/shop/ShopList'
import CategoryNavList from '~/components/app/module/category/CategoryNavList'
import DrawerArea from '~/components/layouts/drawer/DrawerArea'
import SimpleLinkList from '~/components/atoms/link/SimpleLinkList'
import SimpleLinkItem from '~/components/atoms/link/SimpleLinkItem'
import ExternalLink from '~/components/utils/link/ExternalLink'
import Loading from '~/components/atoms/loading/Loading'

const NaviLinks = () => {
  return (
    <SimpleLinkList>
      <SimpleLinkItem link={helpAboutPath()} text={'マルクトとは'} />
      <SimpleLinkItem link={contactPath()} text={'お問い合わせ'} noInternal />
      <SimpleLinkItem link={guidePath()} text={'よくあるご質問'} noInternal />
      <SimpleLinkItem link={helpAgreementPath()} text={'利用規約'} />
      <SimpleLinkItem link={helpTradeLawPath()} text={'特定商取引法'} />
      <SimpleLinkItem link={logoutPath()} text={'ログアウト'} noInternal />
    </SimpleLinkList>
  )
}

const DrawerContent = () => {
  const { data: user } = useUserApi()
  const customer = useUserCustomer()
  const { data: cart, isLoading } = useCartApi()
  const hideNavi = useHideNavi()
  const show = useShowModal()
  const message = useCartMessage()
  const couponCount = useEnabledAreaCouponsOwnedCount()

  const showAreaSettingModal = () => {
    show({ type: Modal.AREA_SETTING })
    hideNavi()
  }
  const showLoginModal = () => {
    show({ type: Modal.LOGIN })
    hideNavi()
  }

  useRouteChanged(() => {
    hideNavi()
  })

  return (
    <>
      <Segments spGap={8} spColumn={SegmentsType.COLUMN} fitHeight>
        <Segments spGap={4}>
          <Text px={12} color={Color.SECONDARY}>
            ようこそ、
          </Text>
          <Text px={14} bold>
            {customer ? customer?.name01 + customer?.name02 : 'ゲスト'}
          </Text>
          さん
        </Segments>
        <Segments spGap={4}>
          <Text px={12} color={Color.SECONDARY}>
            ご利用エリア
          </Text>
          <LinkText px={12} underline onClick={user ? showAreaSettingModal : showLoginModal}>
            〒{user?.postal_code ?? '未設定'}
          </LinkText>
        </Segments>

        <DrawerScroll>
          <Segments spGap={16} spColumn={SegmentsType.COLUMN} fitWidth>
            <Segments spGap={8} spColumn={SegmentsType.COLUMN} fitWidth>
              <DrawerArea>
                <InternalLink link={myPagePointLogPath()}>
                  <Segments spGap={4} spBetween>
                    <Text px={12} color={Color.SECONDARY} bold>
                      保有ポイント数
                    </Text>
                    <LinkText color={Color.EMPHASIS} arrow={Color.SECONDARY} bold>{`${
                      customer?.point ?? 0
                    }P`}</LinkText>
                  </Segments>
                </InternalLink>
              </DrawerArea>
              {couponCount > 0 && (
                <DrawerArea>
                  <InternalLink link={myPageCouponPath()}>
                    <Segments spGap={4} spBetween>
                      <Text px={12} color={Color.SECONDARY} bold>
                        利用可能クーポン
                      </Text>
                      <LinkText color={Color.EMPHASIS} arrow={Color.SECONDARY} bold>{`${
                        couponCount
                      }枚`}</LinkText>
                    </Segments>
                  </InternalLink>
                </DrawerArea>
              )}
              <DrawerArea>
                <Segments spGap={4} spBetween>
                  <div>
                    <div>
                      {isLoading ?
                        <Loading /> :
                        <>
                          <CartIcon quantity={cart?.cartItems.length ?? 0} />
                          <Text px={12}>¥</Text>
                          <Text px={20} color={Color.ASSERTION} bold en>
                            {convertToPrice(cart?.total_price ?? 0)}
                          </Text>
                          <Text px={12} color={Color.ASSERTION}>
                            (税込)
                          </Text>
                        </>
                      }
                    </div>
                    {message != undefined &&
                      <div>
                        {message}
                      </div>
                    }
                    <style jsx>{`
                      div {
                        flex: 1;
                        line-height: 1.2;
                      }
                    `}</style>
                  </div>
                  <Button
                    role={ButtonRole.HIGH_PRIORITY_YELLOW}
                    size={ButtonSize.LARGE}
                    link={cartPath()}
                  >
                    カートを見る
                  </Button>
                </Segments>
              </DrawerArea>
            </Segments>
            <Segments spGap={24} spColumn={SegmentsType.COLUMN} fitWidth>
              <RowButtonList>
                <RowButton
                  link={myPageFavoritePath()}
                  iconType={IconType.STAR}
                  text={'お気に入り'}
                />
                <RowButton
                  link={myPagePurchasedProductPath()}
                  iconType={IconType.BAG}
                  text={'購入した商品'}
                />
                <RowButton
                  link={myPagePath()}
                  iconType={IconType.USER}
                  text={'マイページ'}
                  noInternal
                />
              </RowButtonList>
              <div>
                <BlockTitle icon={IconType.SHOP} iconColor={Color.SUPPORT_SUB}>
                  お買い物ができる店
                </BlockTitle>
                <ShopList />
              </div>
              <style jsx>{`
                div {
                  width: 100%;
                }
              `}</style>
              <CategoryNavList showChild />
              <NaviLinks />
              <Segments spGap={4}>
                <InternalLink link={helpAboutPath()}>
                  <Image
                    src={'/assets/images/hamburger-banner1.png'}
                    alt={'送料無料条件'}
                    width={100}
                    height={80}
                  />
                </InternalLink>
                <InternalLink link={helpAboutPath()}>
                  <Image
                    src={'/assets/images/hamburger-banner2.png'}
                    alt={'貯まる！使える！ポイント'}
                    width={100}
                    height={80}
                  />
                </InternalLink>
                <ExternalLink link={'https://www.lufi.co.jp/markt/#markt01'}>
                  <Image
                    src={'/assets/images/hamburger-banner3.png'}
                    alt={'加盟店募集'}
                    width={100}
                    height={80}
                  />
                </ExternalLink>
              </Segments>
            </Segments>
          </Segments>
        </DrawerScroll>
      </Segments>
    </>
  )
}
const AppSpNavigation = () => {
  const open = useNaviOpening()
  const hide = useHideNavi()

  return (
    <HiddenPc>
      <Background onClick={hide} show={open}></Background>
      <Drawer open={open} onClickClose={hide}>
        <DrawerContent />
      </Drawer>
    </HiddenPc>
  )
}

export default AppSpNavigation
