import { ChildProps } from '~/common/utils/props'

const HiddenPc = ({ children }: ChildProps) => {
  return (
    <div className={'u-hidden-pc'}>
      {children}
    </div>
  )
}

export default HiddenPc
