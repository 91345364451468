import React from 'react'
import FormTextBoxCommon, { FormTextBoxProps } from '~/components/app/form/widgets/FormTextBoxCommon'

const FormEmailBox = (props: FormTextBoxProps) => {
  return (
    <FormTextBoxCommon
      {
        ...{
          type: 'email',
          placeholder: 'メールアドレス',
          ...props
        }
      }
    />
  )
}

export default FormEmailBox