import React, { Fragment, MouseEvent, ReactNode } from 'react'

import { PICKUP_TIME_LIMIT_MORNING_ONLY, PointItem } from '~/model/product'
import Coupon from '~/model/coupon'
import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import { convertToPrice } from '~/common/utils/price'
import { myPageCouponPath, savedMedicineImagePath } from '~/common/app/path'
import { CouponCardType } from '~/common/app/coupon'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import { IconType } from '~/components/atoms/icon/Icon'
import Stepper from '~/components/atoms/stepper/Stepper'
import LinkText from '~/components/atoms/text/LinkText'
import Text from '~/components/atoms/text/Text'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import ProductPoint from '~/components/molecules/detail/ProductPoint'
import Review from '~/components/molecules/review/Review'
import MarginTop from '~/components/utils/margin/MarginTop'
import CardList from '~/components/molecules/card/CardList'
import Segments from '~/components/layouts/area/Segments'
import AppCouponCard from '~/components/app/module/coupon/AppCouponCard'

interface Props {
  name: string
  maker: string
  price: number
  taxInPrice: number
  point: number
  pointItems: PointItem[]
  canUseCoupon: boolean
  shopName: string
  orderTimeTo: string
  deliveryTimeTo: string
  temperature: string
  code: string
  sku: number
  description: string
  onClickCartIn(e: MouseEvent<HTMLElement>): void
  onClickChangeQuantity(quantity: number): void
  onClickFavorite(e: MouseEvent<HTMLElement>): void
  favorite?: boolean
  important?: string[]
  content?: ReactNode
  inversion?: boolean
  cartQuantity: number
  reviews?: {
    reviewer_name: string
    recommend_level: number
    comment: string
    create_date: string
  }[]
  ingredient?: string
  bestByDate?: string
  saleLimit?: number
  pickupTimeLimitId?: number
  isMedicine: boolean
  medicineFiles?: string[]
  expirationLimit?: string
  isOrderItemSplit?: boolean
  isPickup?: boolean
  soldout?: boolean
  outOfSale?: boolean
  coupons?: Coupon[]
}

const ProductDetail = ({
  name,
  maker,
  price,
  taxInPrice,
  point,
  pointItems,
  canUseCoupon = false,
  shopName,
  orderTimeTo,
  deliveryTimeTo,
  temperature,
  code,
  sku,
  description,
  onClickCartIn,
  onClickChangeQuantity,
  onClickFavorite,
  favorite = false,
  important,
  content,
  inversion = false,
  cartQuantity,
  reviews,
  ingredient,
  bestByDate,
  saleLimit,
  pickupTimeLimitId,
  isMedicine,
  medicineFiles,
  expirationLimit,
  isOrderItemSplit,
  isPickup,
  soldout = false,
  outOfSale = false,
  coupons
}: Props) => {
  const favoriteButtonRole = favorite ? ButtonRole.YELLOW : ButtonRole.UNSELECTED
  const favoriteText = favorite ? '登録済み' : 'お気に入り'

  return (
    <div className='p-product-detail'>
      <div className='p-product-detail__maker'>
        <Text px={12} bold>
          {maker}
        </Text>
        <Text px={20} tag='p' bold>
          {name}
        </Text>
        {isOrderItemSplit &&
          <Text px={12} color={Color.ASSERTION}>
            ※本商品の価格は目安となります。実際の価格は、商品の容量によって価格が変わります。
          </Text>
        }
      </div>
      <div className='p-product-detail__price'>
        <div
          className={
            'p-product-detail__price__main' +
            addClass('p-product-detail__price__main--inversion', inversion)
          }
        >
          <Text px={40} color={Color.ASSERTION} bold en>
            <Text px={24} color={Color.ASSERTION} en>
              ¥
            </Text>
            {convertToPrice(price)}
          </Text>
          <span
            className={
              'p-product-detail__price__main__tax' +
              addClass('p-product-detail__price__main__tax--inversion', inversion)
            }
          >
            <Text px={18} color={Color.PRIMARY} en>
              (税込¥{convertToPrice(taxInPrice)})
            </Text>
          </span>
        </div>
        {point > 0 && pointItems?.length > 0 && (
          <ProductPoint point={point} items={pointItems} inversion={inversion} canUseCoupon={canUseCoupon}/>
        )}
      </div>
      {coupons && (
        <MarginTop marginSp={16}>
          <Segments pcBetween spBetween>
              <Text px={14}>この商品の対象クーポン</Text>
              <LinkText link={myPageCouponPath()} underline external>
                保有クーポン一覧
              </LinkText>
          </Segments>
          <MarginTop marginSp={8}>
            <CardList>
              {coupons?.map((coupon) =>
                <AppCouponCard
                  key={coupon.id}
                  coupon={coupon}
                  type={CouponCardType.SMALL}
                />
              )}
            </CardList>
          </MarginTop>
        </MarginTop>
      )}
      <p className='p-product-detail__delivery'>
        <Text px={14}>{shopName} {isPickup ? 'から配送' : ''}</Text>
      </p>
      {orderTimeTo && deliveryTimeTo && (
        <Text px={14} tag={'p'}>
          <Text px={14} color={Color.ASSERTION} bold>
            {orderTimeTo}
          </Text>
          までにご注文で、
          <Text px={14} color={Color.SUPPORT} bold>
            {deliveryTimeTo}
          </Text>
        </Text>
      )}
      <div className='p-product-detail__buttons'>
        <div className='p-product-detail__button-cart'>
          {cartQuantity > 0 ?
            <Stepper value={cartQuantity} onChange={onClickChangeQuantity} fill /> :
            <Button
              size={ButtonSize.LARGE}
              role={(soldout || outOfSale) ? ButtonRole.DEACTIVE : ButtonRole.DECISION}
              iconType={(soldout || outOfSale) ? undefined : IconType.ADD}
              iconSize={16}
              onClick={onClickCartIn}
              expanded
              disabled={(soldout || outOfSale)}
            >
              {soldout ? '品切れ' : outOfSale ? '現在注文できません' : 'カートに追加'}
            </Button>
          }
        </div>
        <div className='p-product-detail__button-favorite'>
          <Button
            size={ButtonSize.LARGE}
            role={favoriteButtonRole}
            iconType={IconType.STAR}
            pcChildren={favoriteText}
            onClick={onClickFavorite}
            spTextNone
          />
        </div>
      </div>
      {(saleLimit && saleLimit > 0) && (
        <MarginTop marginSp={8}>
          <Text px={14} tag={'p'} color={Color.ASSERTION}>
            お一人様{saleLimit}点まで
          </Text>
        </MarginTop>
      )}
      {isMedicine && (
        <>
          <MarginTop marginSp={8}>
            <Text px={14} tag={'p'} color={Color.ASSERTION}>
              ・薬剤師または登録販売者の指導や相談がある場合、希望時間の締め時間までに相談が完了しない場合には、該当商品をキャンセルさせていただく場合があります。
            </Text>
          </MarginTop>
          <MarginTop marginSp={8}>
            <Text px={14} tag={'p'} color={Color.ASSERTION}>
              ・当サイトでは未成年者への医薬品の販売は行っておりません。
            </Text>
          </MarginTop>
          {medicineFiles &&
            medicineFiles.map((item, index) => {
              return (
                <Fragment key={index}>
                  {index > 0 && <br />}
                  <LinkText key={index} px={12} link={savedMedicineImagePath(item)}
                            external>
                    医薬品説明文書({index + 1})を確認する
                  </LinkText>
                </Fragment>
              )
            })
          }
        </>
      )}
      {pickupTimeLimitId && (
        <MarginTop marginSp={8}>
          <Text px={14} tag={'p'} color={Color.ASSERTION}>
            {pickupTimeLimitId === PICKUP_TIME_LIMIT_MORNING_ONLY ? '午後' : '午前'}
            受取不可
          </Text>
        </MarginTop>
      )}

      <ul className='p-product-detail__note'>
        {important &&
          important.map((item) => {
            return (
              <li key={item}>
                <Text px={14} color={Color.ASSERTION}>
                  {item}
                </Text>
              </li>
            )
          })}
      </ul>
      {temperature && (
        <dl className='p-product-detail__temperature'>
          <dt>
            <Text px={12}>温度帯:</Text>
          </dt>
          <dd>
            <Text px={12}>{temperature}</Text>
          </dd>
        </dl>
      )}
      <div className='p-product-detail__code'>
        <dl className='p-product-detail__code__item'>
          <dt>
            <Text px={12} color={Color.SECONDARY}>
              商品コード:
            </Text>
          </dt>
          <Text px={12} color={Color.SECONDARY}>
            {code}
          </Text>
        </dl>
        <dl className='p-product-detail__code__item'>
          <dt>
            <Text px={12} color={Color.SECONDARY}>
              SKU-ID:
            </Text>
          </dt>
          <dd>
            <Text px={12} color={Color.SECONDARY}>
              {sku}
            </Text>
          </dd>
        </dl>
      </div>
      <div className='p-product-detail__description'>
        <BlockTitle verticalBar>商品説明</BlockTitle>
        {description && (
          <Text px={12} tag={'p'} isHtml>
            {description}
          </Text>
        )}
        {expirationLimit && (
          <Text px={12} tag={'p'}>
            【使用期限】{expirationLimit}
          </Text>
        )}
        {ingredient && (
          <Text px={12} tag={'p'}>
            【原材料】 {ingredient}
          </Text>
        )}
        {maker && (
          <Text px={12} tag={'p'}>
            【産地・メーカー】{maker}
          </Text>
        )}
        {bestByDate && (
          <Text px={12} tag={'p'}>
            【賞味期限目安】{bestByDate}
          </Text>
        )}
      </div>
      {!isMedicine && (
        <div className='p-product-detail__review'>
          <BlockTitle icon={IconType.REVIEW} iconColor={Color.SUPPORT_SUB}>
            商品レビュー
          </BlockTitle>
          <ul
            className={
              'p-product-detail__review__list' +
              addClass('p-product-detail__review__list--scroll', inversion)
            }
          >
            {reviews ? (
              reviews.map((review) => {
                return (
                  <Review
                    key={review.create_date}
                    reviewer_name={review.reviewer_name}
                    recommend_level={review.recommend_level}
                    comment={review.comment}
                    date={review.create_date}
                    scroll={inversion}
                  />
                )
              })
            ) : (
              <Text px={12}>最初のレビューを投稿しましょう</Text>
            )}
          </ul>
          {content && content}
        </div>
      )}
    </div>
  )
}
export default ProductDetail
