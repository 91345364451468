import { MouseEvent } from 'react'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  tag?: 'span' | 'div'

  onClick?(e: MouseEvent<HTMLElement>): void
}

const IconTextColumn = ({ children, tag = 'span', onClick }: Props) => {
  const Tag = tag
  return (
    <>
      <Tag onClick={onClick}>{children}</Tag>
      <style jsx>{`
        ${tag} {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          row-gap: 4px;
          flex-direction: column;
          min-width: 48px;
          transform: scale(0.92);
        }
      `}</style>
    </>
  )
}
export default IconTextColumn
