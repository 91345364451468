import Image from 'next/image'

interface Props {
  src: string
  alt?: string
}

const ImageFitContain = ({ src, alt = '' }: Props) => {
  return (
    <span className='u-image-fit-cover'>
      <Image src={src} alt={alt} fill className='u-image-fit-cover__item' />
    </span>
  )
}

export default ImageFitContain
