import { DependencyList, Dispatch, SetStateAction, useEffect, useState } from 'react'

export const INTERVAL_MILI_NORMAL = 1000
export const INTERVAL_MILI_SHORT = 100
export const DELAY_INPUT_PC = 300
export const DELAY_INPUT_SP = 500

export const onceTimer = (onCompleted: () => void, interval: number) => {
  const timerId = setTimeout(() => {
    onCompleted()
  }, interval)

  return () => {
    clearTimeout(timerId)
  }
}

export const useOnceTimer = (onCompleted: () => void, interval: number, deps: DependencyList) => {
  useEffect(() => {
    onceTimer(onCompleted, interval)
  }, deps)
}

export const useTimerState = <T extends any>(
  inputValue: T,
  onCompleted?: (v: T) => void,
  delay?: number
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(inputValue)

  useEffect(() => {
    if (delay) {
      const timer = setTimeout(() => {
        setValue(inputValue)
      }, delay)

      return () => clearTimeout(timer)
    } else {
      setValue(inputValue)
    }
  }, [inputValue, delay])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof onCompleted != 'undefined') {
        onCompleted(value)
      }
    }, INTERVAL_MILI_SHORT)

    return () => clearTimeout(timer)
  }, [value])

  return [value, setValue]
}