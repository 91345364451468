import InternalLink from '~/components/utils/link/InternalLink'

interface Props {
  link: string
  text: string
}

const TagLink = ({ link, text }: Props) => {
  return (
    <li className={'c-link-tag'}>
      <InternalLink link={link} className={'c-link-tag__link'}>
        {text}
      </InternalLink>
    </li>
  )
}

export default TagLink
