import React, { ChangeEventHandler, ReactNode } from 'react'
import { addClass } from '~/common/utils/html'

interface Props {
  name: string
  value: number | string
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  label?: ReactNode
  description?: string | ReactNode
  pcFitContent?: boolean
}

const RadioButtonElement = ({
  name,
  value,
  checked,
  onChange,
  disabled = false,
  label,
  description,
  pcFitContent = false
}: Props) => {
  const id = name + '_' + value
  return (
    <label
      className={
        'c-radio' +
        addClass('c-radio--checked', checked) +
        addClass('c-radio--pc-fit-content', pcFitContent)
      }
      htmlFor={id}
    >
      <input
        id={id}
        type={'radio'}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={'c-radio__element'}>
        <span className={'c-radio__indicator'} />
        {label}
      </span>
      {description && <span className={'c-radio__description'}>{description}</span>}
    </label>
  )
}

export default RadioButtonElement
