import { Store, useStoreSWR } from '~/common/app/store/store'

export const useCartFooterOpening = (): boolean => {
  const { data } = useStoreSWR<boolean, Error>(Store.CART_FOOTER_OPEN, false)
  return data ?? false
}

export const useShowCartFooter = (): () => void => {
  const { mutate } = useStoreSWR<boolean, Error>(Store.CART_FOOTER_OPEN)
  return () => mutate(true)
}

export const useHideCartFooter = (): () => void => {
  const { mutate } = useStoreSWR<boolean, Error>(Store.CART_FOOTER_OPEN)
  return () => mutate(false)
}
