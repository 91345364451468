import React from 'react'
import { useCart } from '~/common/api/cart'
import {
  useCartOperation,
  useCartTotalQuantity,
  useMakeCartProductCardPropsList, useCartMessage
} from '~/common/app/cart'
import { useUserMode } from '~/common/api/user'
import { useEnabledAreaCouponsOwnedCount } from '~/common/api/coupon'
import { useArea } from '~/common/api/area'
import { cartPath } from '~/common/app/path'
import { modeLabel } from '~/common/app/delivery'
import CartHeader from '~/components/molecules/cart/CartHeader'

const AppCartHeader = () => {
  const cart = useCart()
  const mode = useUserMode()
  const cartOperation = useCartOperation()
  const cartProductCardProps = useMakeCartProductCardPropsList(cart)
  const message = useCartMessage()
  const area = useArea()
  const couponCount = useEnabledAreaCouponsOwnedCount()

  const onDelete = (productClassId: number) => {
    cartOperation(productClassId, 0)
  }

  return (
    <CartHeader
      total={cart?.total_price ?? 0}
      cartTotalQuantity={useCartTotalQuantity()}
      message={message}
      cartLink={cartPath()}
      items={cartProductCardProps}
      modeLabel={modeLabel(mode)}
      onDelete={onDelete}
      couponCount={couponCount}
    />
  )
}

export default AppCartHeader
