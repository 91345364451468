import { ChildProps } from '~/common/utils/props'

const AreaPcSide = ({ children }: ChildProps) => {
  return (
    <>
      <div className='l-area-pc-side'>{children}</div>
    </>
  )
}

export default AreaPcSide
