import Category from '~/model/category'
import Shop from '~/model/shop'
import Coupon from '~/model/coupon'

import { CouponTagContents } from '~/common/app/coupon'

export const PICKUP_TIME_LIMIT_MORNING_ONLY = 1
export const PICKUP_TIME_LIMIT_AFTERNOON_ONLY = 2

export const ProductStatus = {
  SHOW: 1,
  HIDE: 2,
  ABOLISHED: 3
}

export type ProductStatus = (typeof ProductStatus)[keyof typeof ProductStatus]

export type PointItem = {
  name: string
  point: number
  ratio: number
  link?: string
}

export default interface Product {
  // 共通パラメーター
  is_medicine: boolean
  medicine_risk_name?: string
  is_tokubai: boolean
  is_season: boolean
  is_sale: boolean
  is_stock_find: boolean
  price: number
  price_inc_tax: number
  name: string
  amount?: string
  product_class_id: number
  pickup_time_limit_id?: number
  shop: Shop
  buyable_quantity: number
  sale_tag?: string
  sale_price?: number
  sale_price_inc_tax?: number
  on_sale: boolean
  id: number
  is_order_item_split?: boolean
  // 基本パラメーター
  add_point?: string
  product_status_id: ProductStatus
  product_image?: string
  inactive_sale_tag?: string
  coupon_tag_contents?: CouponTagContents
  category_ids?: number[]
  // 詳細パラメーター
  product_images?: string[]
  medicine_files?: string[]
  medicine_expiration_limit?: string
  medicine_caution?: string
  abuse_ability?: boolean
  place_of_origin?: string
  total_point_rate?: number
  total_add_point?: number
  basic_point_rate?: number
  basic_add_point?: number
  customer_point_rate?: number
  customer_add_point?: number
  shop_bonus_point_rate?: number
  shop_bonus_add_point?: number
  coupon_point_rate?: number
  coupon_add_point?: number
  sale_limit?: number
  middleCategories?: Category[]
  temperature_zone?: string
  item_code?: string
  description_detail?: string
  ingredient?: string
  best_by_date?: string
  is_deliverable?: boolean
  disable_am?: boolean
  disable_pm?: boolean
  searched_product_id?: string
  coupons?: Coupon[]
}
