import React from 'react'
import { ChildProps } from '~/common/utils/props'
import { addClass } from '~/common/utils/html'
import Close from '~/components/atoms/icon/Close'

interface Props extends ChildProps {
  open: boolean
  onClickClose(e: React.MouseEvent<HTMLElement>): void
}

const Drawer = ({ children, open, onClickClose }: Props) => {
  return (
    <div className={'l-drawer' + addClass('l-drawer--open', open)}>
      <span className={'l-drawer__close'}>
        <Close onClick={onClickClose} />
      </span>
      {children}
    </div>
  )
}

export default Drawer
