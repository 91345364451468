import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { SuggestItem } from '~/model/suggest'
import { useSuggestList } from '~/common/api/suggest'

import { productListPath, usePathKey } from '~/common/app/path'
import { convertSuggests, convertCategorySuggests } from '~/common/app/productSearch'
import { useIsSpDevice } from '~/components/utils/window'
import {
  DELAY_INPUT_PC,
  DELAY_INPUT_SP,
  INTERVAL_MILI_SHORT,
  useTimerState
} from '~/common/utils/timer'
import { isKanji } from '~/common/utils/string'
import SuggestRow from '~/components/atoms/suggest/SuggestRow'
import Text from '~/components/atoms/text/Text'
import SearchSuggest from '~/components/molecules/suggest/SearchSuggest'

interface Props {
  keyword: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const REQUEST_MIN_LENGTH = 2

const AppSuggestCore = ({keyword, open, setOpen}: Props) => {
  const router = useRouter()
  const key = usePathKey()
  const isSp = useIsSpDevice()
  const [searchWord, setSearchWord] = useState<string>(keyword)
  const suggestResponse = useSuggestList(searchWord)
  const categories = convertCategorySuggests(searchWord, suggestResponse.data?.facet_fields, key)
  const keys = convertSuggests(searchWord, suggestResponse.data?.suggestions, key)
  const suggestItems = categories.concat(keys)
  const SUBMIT_ENTER_CODE = 13


  useEffect(() => {
    setSearchWord(keyword)
    setOpen(open)
  }, [keyword, open])

  return (
      <>{
          open &&
          <SearchSuggest
              outSideClick={() => setOpen(false)}
              text={searchWord}
              onClick={() => {
                router.push(productListPath({ key, word: searchWord }))
                setOpen(false)
                setSearchWord('')
              }}
              onKeyDown={(e) => {
                if (isSp && e.keyCode === SUBMIT_ENTER_CODE) {
                  router.push(productListPath({ key, word: searchWord }))
                }
              }}
              onCancelClick={() => setOpen(false)}
              onChange={(e) => {
                if (isSp) {
                  setSearchWord(e.target.value)
                }
              }}
          >
              {suggestItems.map((suggest: SuggestItem, index) =>
                  <SuggestRow
                      key={index} link={suggest.url} category={suggest.category ?? undefined}
                      onClick={()=> {
                        setTimeout(() =>
                            setOpen(false), isSp ? INTERVAL_MILI_SHORT : DELAY_INPUT_SP)
                      }}
                  >
                    <>
                      {suggest.beforeWord && <Text>
                        {suggest.beforeWord}
                      </Text>}
                      {suggest.keyword && <Text bold>
                        {suggest.keyword}
                      </Text>}
                      {suggest.afterWord && <Text>
                        {suggest.afterWord}
                      </Text>}
                    </>
                  </SuggestRow>
              )}
          </SearchSuggest>
      }
      </>
  )
}

const AppSuggest = ({keyword, open, setOpen}: Props) => {
  const isSp = useIsSpDevice()
  // for input delay PC
  const [searchWord, setSearchWord] = useState<string>(keyword)
  const [inputText, setInput] = useTimerState<string>(keyword, setSearchWord, DELAY_INPUT_PC)

  return (
      <>
        {(REQUEST_MIN_LENGTH <= inputText.length || isKanji(inputText) || (isSp && !keyword)) &&
            <AppSuggestCore keyword={inputText} open={open} setOpen={setOpen} />
        }
      </>
  )
}

export default AppSuggest
