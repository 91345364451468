import React, { HTMLInputTypeAttribute, ChangeEvent, MutableRefObject, KeyboardEvent } from 'react'

import { addClass } from '~/common/utils/html'

const NORMAL_STRING_MAX_LENGTH = 255

export const TextBoxVisual = {
  NORMAL: 'NORMAL',
  INVERSION: 'INVERSION'
} as const

export type TextBoxVisual = (typeof TextBoxVisual)[keyof typeof TextBoxVisual]

export const TextBoxSize = {
  NORMAL: 'NORMAL',
  SHORT: 'SHORT',
  WIDE: 'WIDE'
} as const

export type TextBoxSize = (typeof TextBoxSize)[keyof typeof TextBoxSize]

interface Props {
  px?: 14 | 16
  type?: HTMLInputTypeAttribute
  value: string

  onChange(event: ChangeEvent<HTMLInputElement>): void

  onKeyDown?(event: KeyboardEvent<HTMLInputElement>): void

  onBlur?(event: ChangeEvent<HTMLInputElement>): void

  onFocus?(event: ChangeEvent<HTMLInputElement>): void

  maxLength?: number
  placeholder?: string
  autoComplete?: string
  error?: boolean
  disabled?: boolean
  visual?: TextBoxVisual
  size?: TextBoxSize
  high?: boolean
  autoFocus?: boolean
  inputRef?: MutableRefObject<HTMLInputElement>
}

const TextBox = ({
  px,
  type = 'text',
  value,
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
  maxLength = NORMAL_STRING_MAX_LENGTH,
  placeholder = '',
  autoComplete = 'off',
  error = false,
  disabled = false,
  visual = TextBoxVisual.NORMAL,
  size = TextBoxSize.NORMAL,
  high = false,
  autoFocus = false,
  inputRef
}: Props) => {
  return (
    <>
      <input
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        maxLength={maxLength}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className={
          'c-text-box' +
          addClass('c-text-box--visual-normal', visual == TextBoxVisual.NORMAL) +
          addClass('c-text-box--size-normal', size == TextBoxSize.NORMAL) +
          addClass('c-text-box--size-short', size == TextBoxSize.SHORT) +
          addClass('c-text-box--size-wide', size == TextBoxSize.WIDE) +
          addClass('c-text-box--high', high) +
          addClass('c-text-box--error', error)
        }
        disabled={disabled}
        autoFocus={autoFocus}
        ref={inputRef}
      />
      <style jsx>{`
        input {
          font-size: ${px ? px + 'px' : ''};
        }
      `}</style>
    </>
  )
}

export default TextBox
