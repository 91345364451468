import Address from '~/model/address'

import { WidgetChoice } from '~/common/form/choice'
import { AddressListResponse } from '~/common/api/user'
import { getCookieValue, setCookieValue } from '~/common/utils/cookie'

export const CP_FAVORITE_PRODUCT_THRESHOLD = 20
export const CP_GRANT_POINT = 500
export const PRODUCT_RECENT_BROWSED_COOKIE_NAME = 'CheckedItemIds'
export const PRODUCT_RECENT_BROWSED_EXPIRE_DAYS = 7
export const PRODUCT_RECENT_BROWSED_SET_COUNT = 10

export const filterAreaAddress = (data?: AddressListResponse): Address[] => {
  return data?.addresses.filter((address) => {
    return address.area
  }) ?? []
}

export const convertAddressToAreaChoices = (addresses: Address[]): WidgetChoice<string>[] => {
  return addresses.filter((address) => {
    return address.area
  }).reduce((choices: WidgetChoice<string>[], address) => {
    const duplicated = choices.some((choice) => {
      return choice.name == address.area.name
    })

    const shops = address.area.shops ?? []
    return duplicated
      ? choices
      : [
        ...choices,
        {
          name: address.area.name,
          value: address.postal_code,
          description: shops.map((shop, index) => (
            <>
              {shop.name}
              {index + 1 < shops.length && <br />}
            </>
          ))
        }
      ]
  }, []) ?? []
}

export const getProductRecentBrowsedIds = (): number[] | undefined => {
  const productStringIds = getCookieValue(PRODUCT_RECENT_BROWSED_COOKIE_NAME)
  return productStringIds ? productStringIds.split('_').map(Number) : undefined
}

export const setProductRecentBrowsedCookie = (id: number): void => {
  const productIds = getProductRecentBrowsedIds() ?? []
  const index = productIds.indexOf(id)
  if (index !== -1) {
    productIds.splice(index, 1)
  }
  productIds.push(id)
  while (productIds.length > PRODUCT_RECENT_BROWSED_SET_COUNT) {
    productIds.shift()
  }

  const value = productIds.join('_')
  setCookieValue(PRODUCT_RECENT_BROWSED_COOKIE_NAME, value, PRODUCT_RECENT_BROWSED_EXPIRE_DAYS, 'Strict')
}
