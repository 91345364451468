import { useRouter } from 'next/router'
import Script from 'next/script'

import { STYLE_MEDIA_SP } from '~/common/app/style'
import { useLoggedInApi, useUserApi } from '~/common/api/user'
import { useShop } from '~/common/api/shop'
import { useModalShowing } from '~/common/app/store/modal'
import { ChildProps } from '~/common/utils/props'
import { myPageLoginPath } from '~/common/app/path'
import { useNaviOpening } from '~/common/app/store/navi'
import AppHeader from '~/components/app/layout/header/AppHeader'
import AppPcNavigation from '~/components/app/layout/navi/AppPcNavigation'
import AppSpNavigation from '~/components/app/layout/navi/AppSpNavigation'
import AppFooter from '~/components/app/layout/footer/AppFooter'
import AppLoading from '~/components/app/layout/AppLoading'
import AppNotice from '~/components/app/layout/AppNotice'
import AppModal from '~/components/app/modal/AppModal'
import PageNaviFrame from '~/components/layouts/screen/PageNaviFrame'
import Main from '~/components/layouts/screen/Main'
import UserSettingModal from '~/components/app/modal/UserSettingModal'
import AppCartFooter from '~/components/app/layout/footer/AppCartFooter'
import LoginFooter from '~/components/app/layout/footer/LoginFooter'
import GoogleTagManager, { GoogleTagManagerId } from '~/components/app/layout/GoogleTagManager'

export const Layout = {
  MAIN: 'MAIN',
  SINGLE: 'SINGLE',
  LITE: 'LITE'
} as const

export type Layout = (typeof Layout)[keyof typeof Layout]

export const FixedFooter = {
  CART: 'CART',
  SELECT_CART: 'SELECT_CART',
  NO: 'NO'
} as const

export type FixedFooter = (typeof FixedFooter)[keyof typeof FixedFooter]

export interface AppLayoutProps extends ChildProps {
  layout: Layout
  loginRequired?: boolean
  fixedFooter?: FixedFooter
}

const AppLayout = ({
  children,
  layout,
  loginRequired = false,
  fixedFooter = FixedFooter.CART
}: AppLayoutProps) => {
  const router = useRouter()
  const { loggedIn, isLoading: isLoadingLoggedInApi } = useLoggedInApi()
  const { data: user, isLoading } = useUserApi()
  const shop = useShop()
  const modalShowing = useModalShowing()
  const naviOpening = useNaviOpening()
  const isReady = router.isReady

  // wait for user api and ready of rouer
  if (loginRequired && isReady && !isLoadingLoggedInApi && !loggedIn) {
    router.push(myPageLoginPath())
  }

  return (
    <>
      {process.env.GOOGLE_TAG_MANAGER_ID && (
        <GoogleTagManager
          user={user}
          googleTagManagerId={process.env.GOOGLE_TAG_MANAGER_ID as GoogleTagManagerId}
        />
      )}
      <AppHeader layout={layout} isShop={!!shop} />
      {layout == Layout.MAIN ? (
        <PageNaviFrame>
          <AppPcNavigation />
          <Main>{children}</Main>
        </PageNaviFrame>
      ) : (
        <Main>{children}</Main>
      )}
      <AppFooter layout={layout} shop={shop} />
      <AppSpNavigation />
      {layout == Layout.MAIN && (
        <>
          {user != undefined && (
            <>{user?.postal_code || user.customer ? <AppCartFooter /> : <LoginFooter />}</>
          )}
        </>
      )}
      {!isLoading && !user?.access_area_key && !user?.customer && <UserSettingModal />}
      <AppModal />
      <AppLoading />
      <AppNotice />
      <style jsx global>{`
        body {
          overflow: ${modalShowing || naviOpening ? 'hidden' : 'auto'};
        }

        #__next {
          min-height: 100vh;
          min-height: 100svh;
        }

        @media ${STYLE_MEDIA_SP} {
          #__next {
            padding-bottom: ${fixedFooter == FixedFooter.CART
              ? '64px'
              : fixedFooter == FixedFooter.SELECT_CART
              ? '118px'
              : '0'};
          }
        }
      `}</style>
      <Script
        src={process.env.PRODUCT_ANALYTICS_SCRIPT_ENDPOINT}
        strategy='beforeInteractive'
        defer
      />
    </>
  )
}

export default AppLayout
