import React, { useCallback } from 'react'
import { useRouter } from 'next/router'

import { useShop } from '~/common/api/shop'
import { ApiPath } from '~/common/api/base'
import { areaTopPath, helpDeliveryAreaPath } from '~/common/app/path'
import { useWriteNotice } from '~/common/app/store/notice'
import { useFormErrorState, useFormState } from '~/common/form/handle'
import { RequestError } from '~/common/app/requestError'
import { SwitchAreaResponse } from '~/common/api/user'
import { Color } from '~/common/utils/color'
import {
  initialData,
  PostalCodeType,
  PostalCodeTypeError,
  postalCodeValidation
} from '~/components/app/form/postalCode/postalCodeType'
import Form from '~/components/app/form/base/Form'
import FormRow from '~/components/layouts/form/FormRow'
import { TextBoxSize } from '~/components/atoms/textbox/TextBox'
import SecondaryTitle from '~/components/atoms/title/SecondaryTitle'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import ErrorText from '~/components/atoms/text/ErrorText'
import LinkText from '~/components/atoms/text/LinkText'
import FormTextBox from '~/components/app/form/widgets/FormTextBox'
import FormWidgetErrorMessages from '~/components/app/form/widgets/FormWidgetErrorMessages'

interface Props {
  complete?(): void
}

const PostalCodeForm = ({ complete }: Props) => {
  const shop = useShop()
  const writeNotice = useWriteNotice()
  const router = useRouter()
  const [formData, setData] = useFormState<PostalCodeType>(initialData)
  const [formError, setError] = useFormErrorState<PostalCodeTypeError>()

  const onComplete = useCallback((response: SwitchAreaResponse) => {
    writeNotice('お住まいの地域を設定しました')
    !(shop && shop.area.area_key == response.area.area_key) && router.push(areaTopPath(response.area))
    complete && complete()
  }, [])

  return (
    <Form
      apiPath={ApiPath.AREA_SWITCH}
      complete={onComplete}
      formData={formData}
      formError={formError}
      setData={setData}
      setError={setError}
      validations={postalCodeValidation}
      refetchApiPath={[ApiPath.USER]}
      method={'PUT'}
    >
      <SecondaryTitle inversion>郵便番号から設定する</SecondaryTitle>
      <FormRow>
        <span>〒</span>
        <FormTextBox
          label='郵便番号'
          name={'postal_code'}
          value={formData.postal_code}
          onChange={setData}
          placeholder={'123-4567'}
          size={TextBoxSize.SHORT}
          high
          shrink
        />
        <Button role={ButtonRole.HIGH_PRIORITY_YELLOW} size={ButtonSize.LARGE} noShrink>
          確認する
        </Button>
      </FormRow>
      <FormRow>
        <LinkText arrow={Color.LINK} link={helpDeliveryAreaPath()} px={12} noInternal>
          マルクトのお届け対象エリアを確認する
        </LinkText>
      </FormRow>
      {formError.formWidgetErrors.postal_code && (
        <FormWidgetErrorMessages
          label={'郵便番号'}
          errors={formError.formWidgetErrors.postal_code}
        />
      )}
      {formError.requestError == RequestError.NOT_FOUND && (
        <ErrorText>※ ご入力いただいたエリアは、お届け対象エリアではありません。</ErrorText>
      )}
    </Form>
  )
}

export default PostalCodeForm
