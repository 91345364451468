import { ShopDetail } from '~/model/shop'
import { SHOP_INFO_TAG_ID } from '~/common/app/shop'
import { Layout } from '~/components/app/layout/AppLayout'
import FooterCopyRight from '~/components/layouts/footer/FooterCopyRight'
import ShopInfo from '~/components/app/module/shop/ShopInfo'
import ShopDeliveryInfo from '~/components/app/module/shop/ShopDeliveryInfo'
import ShopMedicineInfo from '~/components/app/module/shop/ShopMedicineInfo'
import FooterContent from '~/components/app/layout/footer/FooterContent'
import FooterFrame from '~/components/layouts/footer/FooterFrame'

interface Props {
  layout: Layout
  shop: ShopDetail | undefined
}

const AppFooter = ({ layout, shop }: Props) => {
  return (
    <footer id={SHOP_INFO_TAG_ID} className='l-footer-frame-wrap'>
      {layout != Layout.LITE && (
        <>
          {shop && (
            <FooterFrame>
              <ShopInfo shop={shop} />
              <ShopDeliveryInfo shop={shop} />
              <ShopMedicineInfo shop={shop} />
            </FooterFrame>
          )}
        </>
      )}
      <FooterContent />
      {/*TODO: reCaptcha with login */}
      {/*<FooterReCaptcha/>*/}
      <FooterCopyRight>@2022 マルクトモール</FooterCopyRight>
    </footer>
  )
}

export default AppFooter
