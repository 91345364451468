import { useEffect, useState } from 'react'
import { useCloseModal, useGetModal } from '~/common/app/store/modal'
import { useRouteChanged } from '~/common/utils/route'
import { onceTimer, INTERVAL_MILI_NORMAL } from '~/common/utils/timer'
import ImageModal, { ModalImageProps } from '~/components/app/modal/ImageModal'
import CategoryModal from '~/components/app/modal/CategoryModal'
import LoginModal from '~/components/app/modal/LoginModal'
import AreaSettingModal from '~/components/app/modal/AreaSettingModal'
import ProductModal from '~/components/app/modal/ProductModal'
import Background from '~/components/layouts/background/Background'
import { ModalProductProps } from '~/components/app/modal/ProductModal'
import ConfirmModal, { ConfirmModalProps } from '~/components/app/modal/ConfirmModal'
import MedicineModal, { MedicineModalProps } from '~/components/app/modal/MedicineModal'
import CouponModal, { CouponModalProps } from '~/components/app/modal/CouponModal'

export const Modal = {
  IMAGE: 'IMAGE',
  LOGIN: 'LOGIN',
  PRODUCT: 'PRODUCT',
  CATEGORY: 'CATEGORY',
  AREA_SETTING: 'AREA_SETTING',
  USER_SETTING: 'USER_SETTING',
  CONFIRM: 'CONFIRM',
  MEDICINE: 'MEDICINE',
  COUPON: 'COUPON'
} as const

export type Modal = (typeof Modal)[keyof typeof Modal]

interface ModalCoreProps extends ModalImageProps, ModalProductProps, ConfirmModalProps, MedicineModalProps,  CouponModalProps{
  onClickClose(): void
}

export interface AppModalProps {
  type: Modal
  childProps?: ModalImageProps | ModalProductProps | ConfirmModalProps | MedicineModalProps | CouponModalProps
}

const AppModal = () => {
  const modal = useGetModal()
  const close = useCloseModal()
  const [show, setShow] = useState<boolean>(false)
  const onClickClose = () => {
    setShow(false)
    onceTimer(() => {
      close()
    }, INTERVAL_MILI_NORMAL / 2)
  }
  const props = { ...modal?.childProps, onClickClose: onClickClose } as ModalCoreProps

  useEffect(() => {
    setShow(!!modal)
  }, [modal])

  return (
    <Background show={show} onClick={onClickClose}>
      {modal?.type == Modal.IMAGE && <ImageModal {...props} />}
      {modal?.type == Modal.LOGIN && <LoginModal {...props} />}
      {modal?.type == Modal.CATEGORY && <CategoryModal {...props} />}
      {modal?.type == Modal.AREA_SETTING && <AreaSettingModal {...props} />}
      {modal?.type == Modal.PRODUCT && <ProductModal {...props} />}
      {modal?.type == Modal.CONFIRM && <ConfirmModal {...props} />}
      {modal?.type == Modal.MEDICINE && <MedicineModal {...props} />}
      {modal?.type == Modal.COUPON && <CouponModal {...props} />}
    </Background>
  )
}

export default AppModal
