import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  horizon?: boolean
  pcFitContent?: boolean
}

const FormWidgetList = ({ children, horizon = false, pcFitContent = false }: Props) => {
  return (
    <>
      <div
        className={
          'l-form-widget-list' +
          addClass('l-form-widget-list--vertical', !horizon) +
          addClass('l-form-widget-list--pc-fit-content', pcFitContent)
        }
      >
        {children}
      </div>
    </>
  )
}

export default FormWidgetList
