import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  noScroll?: boolean
  vertical?: boolean
  shop?: boolean
}

const CardList = ({ children, noScroll = false, vertical = false, shop = false }: Props) => {
  const className =
    'p-card-list__in' +
    addClass(
      noScroll
        ? 'p-card-list__in--none'
        : vertical
        ? 'p-card-list__in--vertical'
        : 'p-card-list__in--horizontal'
    ) +
    addClass('p-card-list__in--shop', shop)

  return noScroll ? (
    <ul className={'p-card-list-no-scroll' + addClass('p-card-list-no-scroll--vertical', vertical)}>
      {children}
    </ul>
  ) : (
    <div className={'p-card-list'}>
      <ul className={className}>{children}</ul>
    </div>
  )
}

export default CardList
