import React, { useCallback, useMemo, useState } from 'react'

import Product, { ProductStatus } from '~/model/product'

import { useShowModal } from '~/common/app/store/modal'
import { useCartOperation, useProductCartQuantity } from '~/common/app/cart'
import { useFavoriteOperation } from '~/common/app/favorite'
import { productDetailPath, productListPath, thumbImagePath } from '~/common/app/path'
import { productTitle } from '~/common/app/product'
import { sendCartItemAnalysisData, sendProductAnalysisData } from '~/common/app/productSearch'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'
import MediumProductCard from '~/components/molecules/card/MediumProductCard'
import SmallProductCard from '~/components/molecules/card/SmallProductCard'
import MicroProductCard from '~/components/molecules/card/MicroProductCard'
import LargeProductCard from '~/components/molecules/card/LargeProductCard'
import { Modal } from '~/components/app/modal/AppModal'
import { ModalProductProps } from '~/components/app/modal/ProductModal'

export interface AppProductCardProps {
  product: Product
  type: ProductCardType
  isFavorite: boolean
  rank?: number
  addPoint?: string
  acceptableNextTime?: string
  onClickSelect?(productClassId: number, selected: boolean): void
}

const AppProductCard = ({
  product,
  type,
  isFavorite,
  rank,
  addPoint,
  acceptableNextTime,
  onClickSelect
}: AppProductCardProps) => {
  const [selected, setSelected] = useState<boolean>(false)
  const showModal = useShowModal()
  const cartQuantity = useProductCartQuantity(product.product_class_id)
  const cartOperation = useCartOperation(() => {
    sendProductAnalysisData(product.searched_product_id)
    sendCartItemAnalysisData(product.searched_product_id)
  })
  const favoriteOperation = useFavoriteOperation()

  const onClickChangeQuantity = (quantity: number) => {
    cartOperation(product.product_class_id, quantity, cartQuantity)
  }
  const onClickCartIn = () => cartOperation(product.product_class_id, 1)

  const showProduct = useCallback(
    () =>
      showModal({
        type: Modal.PRODUCT,
        childProps: {
          product: product
        } as ModalProductProps
      }),
    [product]
  )
  const onClick = product.is_medicine ? undefined : showProduct

  const onClickFavorite = () => favoriteOperation(product.id, isFavorite)

  const imagePath = thumbImagePath(product.product_image)

  const link =
    product.product_status_id != ProductStatus.SHOW
      ? productListPath({
          key: product.shop.shop_key,
          category_id: product?.middleCategories ? product.middleCategories[0].id : undefined
        })
      : product.is_medicine
      ? productDetailPath(product)
      : undefined

  const noSelectableText = useMemo(
    () =>
      onClickSelect
        ? product.product_status_id != ProductStatus.SHOW
          ? '※現在注文できません'
          : product.is_medicine
          ? '※医薬品は一括選択できません'
          : product.is_stock_find
          ? undefined
          : ' '
        : undefined,
    [onClickSelect, product.product_status_id, product.is_medicine, product.is_stock_find]
  )

  const price = useMemo(
    () =>
      (product.on_sale || product.inactive_sale_tag) && product.sale_price != undefined
        ? product.sale_price
        : product.price,
    [product.on_sale, product.inactive_sale_tag, product.sale_price, product.price]
  )
  const tax = useMemo(
    () =>
      (product.on_sale || product.inactive_sale_tag) && product.sale_price_inc_tax != undefined
        ? product.sale_price_inc_tax
        : product.price_inc_tax,
    [product.on_sale, product.inactive_sale_tag, product.sale_price_inc_tax, product.price_inc_tax]
  )
  const title = useMemo(
    () => productTitle(product),
    [product.name, product.amount, product.medicine_risk_name]
  )

  return (
    <>
      {type == ProductCardType.LARGE && (
        <LargeProductCard
          key={cartQuantity}
          image={imagePath}
          name={title}
          price={price}
          tax={tax}
          favorite={isFavorite}
          point={addPoint}
          seasonal={Boolean(product.is_season)}
          tokubai={product.is_tokubai}
          saleText={product.sale_tag}
          disableAm={product.disable_am}
          disablePm={product.disable_pm}
          shopName={product.shop.name}
          deliveryText={acceptableNextTime}
          cartQuantity={cartQuantity}
          link={link}
          soldout={!product.is_stock_find}
          outOfSale={product.product_status_id != ProductStatus.SHOW}
          selected={selected}
          noSelectableText={noSelectableText}
          couponTagContents={product.coupon_tag_contents}
          onClick={onClick}
          onClickChangeQuantity={onClickChangeQuantity}
          onClickCartIn={onClickCartIn}
          onClickFavorite={onClickFavorite}
          onClickSelect={
            onClickSelect &&
            product.product_status_id == ProductStatus.SHOW &&
            !product.is_medicine &&
            product.is_stock_find
              ? () => {
                  setSelected(!selected)
                  onClickSelect(product.product_class_id, !selected)
                }
              : undefined
          }
        />
      )}
      {type == ProductCardType.MEDIUM && (
        <MediumProductCard
          key={cartQuantity}
          image={imagePath}
          name={title}
          price={price}
          tax={tax}
          favorite={isFavorite}
          point={addPoint}
          shopName={product.shop.name}
          tokubai={product.is_tokubai}
          saleText={product.sale_tag}
          inactiveSaleText={product.inactive_sale_tag}
          cartQuantity={cartQuantity}
          link={link}
          soldout={!product.is_stock_find}
          couponTagContents={product.coupon_tag_contents}
          onClick={onClick}
          onClickChangeQuantity={onClickChangeQuantity}
          onClickCartIn={onClickCartIn}
        />
      )}
      {type == ProductCardType.SMALL && (
        <SmallProductCard
          key={cartQuantity}
          image={imagePath}
          name={title}
          price={price}
          tax={tax}
          favorite={isFavorite}
          point={addPoint}
          cartQuantity={cartQuantity}
          link={link}
          soldout={!product.is_stock_find}
          outOfSale={product.product_status_id != ProductStatus.SHOW}
          couponTagContents={product.coupon_tag_contents}
          onClick={onClick}
          onClickChangeQuantity={onClickChangeQuantity}
          onClickCartIn={onClickCartIn}
          rank={rank}
        />
      )}
      {type == ProductCardType.MICRO && (
        <MicroProductCard
          image={imagePath}
          onClick={onClick}
          link={link}
          soldout={!product.is_stock_find}
          favorite={isFavorite}
        />
      )}
    </>
  )
}

export default AppProductCard
