import {
  useAreaShopListDeliverableNextTimeApi,
  useShopList
} from '~/common/api/shop'
import {
  AcceptableMessageType,
  isNextDeliverable, nextAcceptableMessage
} from '~/common/app/delivery'
import { useUserApi, useUserMode } from '~/common/api/user'
import { areaTopPath, savedImagePath, shopTopPath, usePathKey } from '~/common/app/path'
import CardList from '~/components/molecules/card/CardList'
import ShopCard from '~/components/molecules/card/ShopCard'

interface Props {
  vertical?: boolean
  areaKey?: string
}

const ShopList = ({ vertical = false, areaKey }: Props) => {
  const key = usePathKey()
  const shops = useShopList(true, areaKey)
  const mode = useUserMode()
  const { data: nextTimeList, isLoading } = useAreaShopListDeliverableNextTimeApi()

  return (
    <CardList vertical={vertical} noScroll={vertical}>
      {shops.map((shop, index) => {
        const nextTime = nextTimeList?.find((nextTime) => {
          return nextTime.shop_id == shop.baseInfo.id
        })
        const active = !!key && shop.baseInfo.shop_key == key
        return (
          <ShopCard
            key={index}
            imageSrc={savedImagePath(shop.shop_image_file)}
            imageAlt={shop.baseInfo.name}
            logoSrc={savedImagePath(shop.logo_image_file)}
            logoAlt={shop.baseInfo.name}
            link={active ? areaTopPath(shop.area) : shopTopPath(shop.baseInfo)}
            title={shop.baseInfo.name}
            tagTitle={shop.product_genre}
            color={'#' + shop.theme_color}
            message={isLoading ? '' : nextAcceptableMessage(nextTime, mode, AcceptableMessageType.MIDDLE)}
            recently={shop.new}
            delivery={isNextDeliverable(nextTime)}
            active={active}
          />
        )
      })}
    </CardList>
  )
}

export default ShopList
