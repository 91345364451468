import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  radius?: boolean
}

const HeaderSupport = ({ children, radius = false }: Props) => {
  return (
    <>
      <div className={'l-header-support' + addClass('l-header-support--radius', radius)}>
        {children}
      </div>
    </>
  )
}

export default HeaderSupport
