import React from 'react'
import { ChildProps } from '~/common/utils/props'
import { addClass } from '~/common/utils/html'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'
import ExternalLink from '~/components/utils/link/ExternalLink'
import InternalLink from '~/components/utils/link/InternalLink'
import { STYLE_MEDIA_SP } from '~/common/app/style'

export const cardButtonTypeClass = (type: ProductCardType): string => {
  return 'c-card-button--' + type
}

interface Props extends ChildProps {
  type: ProductCardType
  arrow?: boolean
  attention?: boolean
  external?: boolean
  link?: string
  onClick?(e: React.MouseEvent<HTMLElement>): void
  spExpanded?: boolean
}

const CardButton = ({
  children,
  type,
  arrow = false,
  attention = false,
  external = false,
  link,
  onClick,
  spExpanded = false
}: Props) => {
  const Tag = external ? ExternalLink : InternalLink
  const className =
    'c-card-button ' +
    addClass(cardButtonTypeClass(type)) +
    addClass('c-card-button--arrow', arrow) +
    addClass('c-card-button--link', typeof link === 'string') +
    addClass('c-card-button--attention', attention)

  return (
    <li className={spExpanded ? 'c-card-button__wrap-sp-expanded' : ''}>
      {link ? (
        <Tag className={className} link={link}>
          {children}
        </Tag>
      ) : (
        <button className={className} onClick={onClick}>
          {children}
        </button>
      )}
    </li>
  )
}

export default CardButton
