import React, { useEffect } from 'react'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { setContent, updateReference } from 'viewport-extra'
import '~/styles/main.scss'

import { DefaultSWRConfig } from '~/common/api/base'
import AppLayout, { AppLayoutProps, FixedFooter, Layout } from '~/components/app/layout/AppLayout'

export type NextPageWithLayout = NextPage & AppLayoutProps

const APP_DEVICE_MIN_WIDTH = 375

interface AppSyncMergeProps extends AppProps {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppSyncMergeProps) => {
  const fallback = pageProps?.fallback ?? {}

  useEffect(() => {
    updateReference()
    setContent({ minWidth: APP_DEVICE_MIN_WIDTH })
  }, [])

  return (
    <DefaultSWRConfig fallback={fallback}>
      <Head>
        {/* For viewport-extra */}
        <meta name='viewport' />
      </Head>
      <AppLayout layout={Component.layout ?? Layout.MAIN}
                 loginRequired={Component.loginRequired ?? false}
                 fixedFooter={Component.fixedFooter ?? FixedFooter.CART}>
        <Component {...pageProps} />
      </AppLayout>
    </DefaultSWRConfig>
  )
}

export default App
