import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'
import { STYLE_MEDIA_PC } from '~/common/app/style'

interface Props extends ChildProps {
  color?: string
  noRadius?: boolean
  spPaddingBlock?: 16 | 24
  spPaddingInline?: 16 | 24
  pcPaddingBlock?: 16 | 24
  pcPaddingInline?: 16 | 24
}

const AreaBox = ({
  children,
  color,
  noRadius = false,
  spPaddingBlock = 16,
  spPaddingInline = 16,
  pcPaddingBlock = 16,
  pcPaddingInline = 16
}: Props) => {
  return (
    <>
      <div className={'l-area-box' + addClass('l-area-box--radius', !noRadius)}>{children}</div>
      <style jsx>{`
        .l-area-box {
          background: ${color};
          padding: ${spPaddingBlock}px ${spPaddingInline}px;
        }
        @media ${STYLE_MEDIA_PC} {
          .l-area-box {
            padding: ${pcPaddingBlock}px ${pcPaddingInline}px;
          }
        }
      `}</style>
    </>
  )
}
export default AreaBox
