import { FormWidgetError } from '~/common/form/error'
import { Validations } from '~/common/form/validation'

export interface OpinionType {
  shop_id: number | string,
  text: string
}

export interface OpinionTypeError {
  shop_id: FormWidgetError[]
  text: FormWidgetError[]
}

export const initialData: OpinionType = {
  shop_id: '',
  text: ''
}

export const opinionValidation: Validations = {
  shop_id: [FormWidgetError.IS_EMPTY],
  text: [FormWidgetError.IS_EMPTY]
}
