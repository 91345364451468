import React from 'react'

import Coupon from '~/model/coupon'

import { CouponCardType } from '~/common/app/coupon'
import ModalFrame, { ModalFrameSize } from '~/components/layouts/modal/ModalFrame'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import AppCouponCard from '~/components/app/module/coupon/AppCouponCard'
import HiddenPc from '~/components/utils/hidden/HiddenPc'

export interface CouponModalProps {
  coupon: Coupon
}

interface Props extends CouponModalProps {
  onClickClose(): void
}

const CouponModal = ({ coupon, onClickClose }: Props) => {

  return (
    <ModalFrame onClickClose={onClickClose} size={ModalFrameSize.AUTO}>
      <Segments spAlignCenter spColumn={SegmentsType.COLUMN} spGap={8}>
        <AppCouponCard
          key={coupon.id}
          coupon={coupon}
          type={CouponCardType.LARGE}
        />
        <HiddenPc>
          <Button
            size={ButtonSize.LARGE}
            role={ButtonRole.LOW_PRIORITY}
            onClick={onClickClose}
          >
            閉じる
          </Button>
        </HiddenPc>
      </Segments>
    </ModalFrame>
  )
}

export default CouponModal
