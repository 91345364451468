import css from 'styled-jsx/css'
import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import ImageFitContain from '~/components/utils/image/ImageFitContain'
import ImageFitCover from '~/components/utils/image/ImageFitCover'
import InternalLink from '~/components/utils/link/InternalLink'

interface Props {
  imageSrc: string
  imageAlt: string
  logoSrc: string
  logoAlt: string
  link: string
  title: string
  tagTitle: string
  color: string
  recently?: boolean
  delivery?: boolean
  message: string
  active?: boolean
}

const ShopCard = ({
  imageSrc,
  imageAlt,
  logoSrc,
  logoAlt,
  link,
  title,
  tagTitle,
  color,
  recently = false,
  delivery = true,
  message,
  active = false
}: Props) => {
  const { className, styles: cardBgStyles } = css.resolve`
    background: ${active ? color : recently ? '#ffebb3' : '#ffffff'};
  `
  return (
    <>
      <li className='p-shop-card'>
        <InternalLink
          link={link}
          className={
            'p-shop-card__link' +
            addClass('is-active', active) +
            addClass('is-new', recently) +
            addClass(className)
          }
        >
          <div className='p-shop-card__in'>
            <div className='p-shop-card__main'>
              <div className='p-shop-card__image-wrap'>
                <ImageFitCover src={imageSrc} alt={imageAlt} />
              </div>
              <div className='p-shop-card__logo'>
                <ImageFitContain src={logoSrc} alt={logoAlt} />
              </div>
              <div className='p-shop-card__tag'>{tagTitle}</div>
              {recently && (
                <div className='p-shop-card__new'>
                  <Icon color={Color.THIRD} size={24} type={IconType.NEW} />
                </div>
              )}
            </div>
            <div className='p-shop-card__name'>{title}</div>
            <div className='p-shop-card__time'>
              最短配送時間
              <span className={addClass('is-accent', !active && delivery)}>{message}</span>
            </div>
          </div>
        </InternalLink>
      </li>

      <style jsx>{`
        .p-shop-card__tag {
          background: ${color};
        }
      `}</style>
      {cardBgStyles}
    </>
  )
}

export default ShopCard
