import ModalFrame from '~/components/layouts/modal/ModalFrame'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import Segments from '~/components/layouts/area/Segments'
import Text from '~/components/atoms/text/Text'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import MarginBottom from '~/components/utils/margin/MarginBottom'

export interface ConfirmModalProps {
  title: string
  message: string
  onClickYes?: () => void
  onClickNo?: () => void
  forAgree?: boolean
  noButtonText?: string
}

interface Props extends ConfirmModalProps {
  onClickClose(): void
}

const ConfirmModal = ({ title, message, onClickClose, onClickYes, onClickNo, forAgree = false, noButtonText}: Props) => {
  return (
    <ModalFrame onClickClose={onClickClose}>
      <BlockTitle verticalBar inModal>
        {title}
      </BlockTitle>
      <MarginBottom marginSp={12}>
        <Text tag={'p'}>{message}</Text>
      </MarginBottom>
      <Segments spGap={8} pcGap={8} spCenter pcCenter>
        <Button role={ButtonRole.LOW_PRIORITY} size={ButtonSize.LARGE}
                onClick={onClickNo ?? onClickClose}>
          {noButtonText ?? (forAgree ? 'キャンセル' : 'いいえ')}
        </Button>
        <Button role={ButtonRole.HIGH_PRIORITY_YELLOW}
                size={ButtonSize.LARGE} onClick={onClickYes ?? onClickClose}>
          {forAgree ? '確認しました' : 'はい'}
        </Button>
      </Segments>
    </ModalFrame>
  )
}

export default ConfirmModal
