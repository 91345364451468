import { ChildProps } from '~/common/utils/props'
import { addClass } from '~/common/utils/html'

interface Props extends ChildProps {
  inversion?: boolean
  noMargin?: boolean
}

const SecondaryTitle = ({ children, inversion = false, noMargin = false }: Props) => {
  return <h3
    className={'c-secondary-title' +
      addClass('c-secondary-title--inversion', inversion) +
      addClass('c-secondary-title--space', !noMargin)}>
    {children}
  </h3>
}

export default SecondaryTitle
