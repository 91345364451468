import { ChildProps } from '~/common/utils/props'
import React from 'react'
import { addClass } from '~/common/utils/html'

interface Props extends ChildProps {
  onClick?(e: React.MouseEvent<HTMLElement>): void
  frame?: boolean
  horizon?: boolean
}

const SpFooter = ({ children, onClick, frame = false, horizon = false }: Props) => {
  return (
    <div
      className={'l-footer-sp u-hidden-pc' + addClass('l-footer-sp--frame', frame) + addClass('l-footer-sp--horizon', horizon)}
      onClick={onClick}>
      {children}
    </div>
  )
}

export default SpFooter
