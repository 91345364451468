import React, { useEffect, useState } from 'react'

import { useTimerState } from '~/common/utils/timer'
import { fixByLimit } from '~/common/utils/number'

export const QUANTITY_MIN = 1
export const QUANTITY_MAX = 100

interface QuantityInputProps {
  initialValue: number
  onChange?(value: number): void
}

export const useQuantityInput = ({ initialValue, onChange }: QuantityInputProps) => {
  const [quantity, setQuantity] = useTimerState<number>(initialValue, onChange)
  const [inputValue, setInputValue] = useState<string>(String(quantity))

  const onChangeValue = (inputValue: number) => {
    setQuantity(fixByLimit(inputValue, QUANTITY_MAX))
    setInputValue(String(fixByLimit(inputValue, QUANTITY_MAX)))
  }

  const onBlur = () => {
    onChangeValue(Number(inputValue))
  }

  const onEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onChangeValue(Number(inputValue))
    }
  }

  useEffect(() => {
    setInputValue(String(quantity))
  }, [quantity])

  return {
    quantity,
    inputValue,
    setInputValue,
    onChangeValue,
    onBlur,
    onEnterKeyDown,
  }
}