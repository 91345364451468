import React from 'react'

import { CouponTagContents } from '~/common/app/coupon'
import BaseProductCard, { ProductCardType } from './BaseProductCard'

interface Props {
  image: string
  name: string
  price: number
  tax: number
  tokubai?: boolean
  saleText?: string
  seasonal?: boolean
  point?: string
  string?: number,
  link?: string
  soldout?: boolean
  outOfSale?: boolean
  favorite?: boolean
  rank?: number
  cartQuantity: number
  couponTagContents? : CouponTagContents
  onClick?(): void
  onClickChangeQuantity(quantty: number): void
  onClickCartIn(): void
}

const SmallProductCard = ({
  image,
  name,
  price,
  tax,
  tokubai = false,
  saleText = undefined,
  seasonal = false,
  point,
  link,
  soldout = false,
  outOfSale = false,
  favorite = false,
  rank,
  cartQuantity = 0,
  couponTagContents,
  onClick,
  onClickChangeQuantity,
  onClickCartIn
}: Props) => {
  return (
    <BaseProductCard
      type={ProductCardType.SMALL}
      image={image}
      name={name}
      price={price}
      tax={tax}
      tokubai={tokubai}
      saleText={saleText}
      seasonal={seasonal}
      point={point}
      link={link}
      soldout={soldout}
      outOfSale={outOfSale}
      favorite={favorite}
      rank={rank}
      cartQuantity={cartQuantity}
      couponTagContents={couponTagContents}
      onClick={onClick}
      onClickChangeQuantity={onClickChangeQuantity}
      onClickCartIn={onClickCartIn}
    />
  )
}

export default SmallProductCard
