import useSWR, { SWRResponse, Fetcher, useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'

export const Store = {
  LOADING: 'LOADING',
  NOTICE: 'NOTICE',
  MODAL: 'MODAL',
  NAVI_OPEN: 'NAVI_OPEN',
  CART_FOOTER_OPEN: 'CART_FOOTER_OPEN'
} as const

export type Store = typeof Store[keyof typeof Store]

export const useStoreSWR = <Data, Error>(key: Store, initialData?: Data | Fetcher<Data>): SWRResponse<Data, Error> => {
  const { cache } = useSWRConfig()
  return useSWRImmutable<Data, Error>(key, () => cache.get(key)?.data ?? initialData, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
    fallback: { [key]: initialData }
  })
}
