import SearchedProduct from '~/model/searched_product'
import Product, { ProductStatus } from '~/model/product'
import Category from '~/model/category'
import Shop, { ShopDetail } from '~/model/shop'
import Area from '~/model/area'
import { SuggestCategory, SuggestItem } from '~/model/suggest'

import { useShop } from '~/common/api/shop'
import { useArea } from '~/common/api/area'
import { useUserCustomer } from '~/common/api/user'
import { useRouter } from 'next/router'
import { productListPath } from '~/common/app/path'
import { PRODUCT_LIST_DISPLAY_MAX, SIMILAR_PRODUCT_LIST_DISPLAY_MAX } from '~/common/app/product'

const extractValueFromMultiShops = (origins: string[], area?: Area): string => {
  const onlyFirst = !area?.area_key || origins.length == 1

  let extractedValue = ''
  origins.some((origin) => {
    const [areaId, shopId, value] = origin.split('_')
    if (onlyFirst || Number(areaId) == area?.id) {
      extractedValue = value ?? shopId
      return true
    }
    return false
  })
  return extractedValue
}

export const convertSearchedProduct = (product: SearchedProduct, area?: Area): Product => {
  return {
    name: product.title,
    disable_am: product.a_only,
    disable_pm: product.m_only,
    id: Number(extractValueFromMultiShops(product.p_id, area)),
    searched_product_id: product.id,
    product_status_id: ProductStatus.SHOW,
    item_code: product.item_code,
    is_medicine: product.mdi_f || false,
    is_season: product.season_f || false,
    is_sale: product.in_sl_f || false,
    sale_tag: product.tag,
    place_of_origin: product.p_of_org,
    price: product.p_t_ex ?? 0,
    price_inc_tax: product.p_t_in ?? 0,
    product_class_id: Number(extractValueFromMultiShops(product.sku_id, area)),
    product_image: product.img_file_n ? product.img_file_n[0] : undefined,
    shop: {
      id: Number(extractValueFromMultiShops(product.shop_id, area)),
      name: extractValueFromMultiShops(product.shop_n, area),
      shop_key: extractValueFromMultiShops(product.shop_k, area),
      postal_code: ''
    },
    is_stock_find: true,
    is_tokubai: false,
    buyable_quantity: Number(product.sl_limit),
    on_sale: false,
    category_ids: product.c_id?.map((id) => Number(id))
  }
}

export const extractSearchedCategories = (
  facetCategories: string[]
): { value: number; name: string }[] => {
  return facetCategories
    .filter((category: string) => isNaN(Number(category)))
    .map((category: string) => convertSearchedCategory(category))
}

export const extractChildCategories = (
  facetBreadCrumbCategories: { value: number; name: string }[],
  selectCategoryId: number
): { value: number; name: string }[] => {
  return facetBreadCrumbCategories
    .filter((category) => category.value.toString().startsWith(selectCategoryId.toString()))
    .sort((a, b) => a.value - b.value)
}

const convertSearchedCategory = (category: string): { value: number; name: string } => {
  const [id, name] = category.split('_')
  return {
    value: parseInt(id),
    name: name
  }
}

export const convertProductSearchSortToApiValue = (sortValue?: number) => {
  if (!sortValue) {
    return undefined
  }

  switch (sortValue) {
    case 1:
      return 'score'
    case 2:
      return 'd_s_no'
    case 3:
      return 'p_t_in'
    case 4:
      return 'p_t_in desc'
    case 5:
      return 'c1_sort'
    default:
      return undefined
  }
}

export const makeQueryShopIdValue = (shop: ShopDetail) => {
  return shop ? `${shop.area.id}_${shop.baseInfo.id}` : undefined
}

export const useProductSearchParams = (
  category?: Category,
  categoryIds?: number[],
  queryShopIds?: string[],
  pageNo?: number,
  word?: string,
  sort: string = ''
) => {
  const area = useArea()
  const shop = useShop()
  const user = useUserCustomer()

  return {
    q: word ?? '',
    qf: 'title,text,s_shop_n,srch_w,c1_srch_w,c2_srch_w,c3_srch_w,c4_srch_w,item_code,p_of_org,kw,kmix1,tracking_kr1,tracking_kr2,tracking_kr3,tracking_kr4,tracking_kr5,tracking_kr6,tracking_kr7,tracking_kr8,tracking_kr9,tracking_kr10',
    page: pageNo ?? 1,
    opt_area_id: area?.id ?? '',
    opt_shop_id: shop ? makeQueryShopIdValue(shop) : queryShopIds ?? '',
    sort: sort ?? '',
    limit: PRODUCT_LIST_DISPLAY_MAX,
    opt_c1_id: category?.hierarchy === 1 ? category.id : '',
    opt_c2_id: category?.hierarchy === 2 ? category.id : '',
    opt_c3_id: category?.hierarchy === 3 ? category.id : '',
    opt_c_id: categoryIds,
    fs_c2: 'count',
    fs_c3: 'count',
    c: user ? user.secret_key : 'guest'
  }
}

export const useProductSearchSimilarParams = (category?: Category) => {
  const { query } = useRouter()
  const shop = useShop()
  const area = useArea()

  return {
    q: query?.name ?? '',
    qf: 'title,text,s_shop_n,srch_w,c1_srch_w,c2_srch_w,c3_srch_w,c4_srch_w,item_code,p_of_org,kw,kmix1,tracking_kr1,tracking_kr2,tracking_kr3,tracking_kr4,tracking_kr5,tracking_kr6,tracking_kr7,tracking_kr8,tracking_kr9,tracking_kr10',
    opt_area_id: area?.id ?? '',
    xopt_shop_id: shop ? makeQueryShopIdValue(shop) : '',
    limit: SIMILAR_PRODUCT_LIST_DISPLAY_MAX,
    opt_c1_id: category?.hierarchy === 1 ? category.id : '',
    opt_c2_id: category?.hierarchy === 2 ? category.id : '',
    opt_c3_id: category?.hierarchy === 3 ? category.id : '',
    fs_c2: 'count',
    fs_c3: 'count'
  }
}

export const convertSuggests = (
  keyword: string,
  suggests: string[] | undefined,
  key?: string
): SuggestItem[] => {
  if (suggests) {
    return suggests.map((suggest: string) => {
      const optionValue = suggest.split(keyword)
      const before = optionValue.shift()
      const after = optionValue.pop()
      return {
        beforeWord: before,
        keyword: suggest.includes(keyword) ? keyword : '',
        afterWord: after,
        url: productListPath({ key, word: suggest })
      }
    })
  }
  return []
}

export const convertCategorySuggests = (
  keyword: string,
  facet_fields: SuggestCategory[] | undefined,
  key?: string
): SuggestItem[] => {
  let facets: string[] = []
  Object.values(facet_fields ?? []).map((suggestCategory: SuggestCategory) => {
    return Object.keys(suggestCategory).map((facet_field: string) => {
      facets.push(facet_field)
    })
  })
  if (facets) {
    return facets.map((field: string) => {
      const optionValue = field.split('_')
      const categoryId = optionValue.shift()
      const categoryName = optionValue.pop()
      return {
        keyword: keyword,
        category: categoryName,
        url: productListPath({ category_id: Number(categoryId), key, word: keyword })
      }
    })
  }
  return []
}

export const useSuggestParams = (keyword: string) => {
  const shop = useShop()
  const area = useArea()

  return {
    q: keyword ?? '',
    opt_area_id: area?.id ?? '',
    opt_shop_id: shop ? makeQueryShopIdValue(shop) : ''
  }
}

export const sendProductAnalysisData = (searchProductId: string | undefined) => {
  typeof window === 'object' && (window as any).as_beacon_load('7', searchProductId)
}

export const sendCartItemAnalysisData = (searchProductId: string | undefined) => {
  typeof window === 'object' && (window as any).as_beacon_cart(searchProductId)
}

export const sendSearchedProductAnalysisData = (
  name: string | undefined,
  searchProductIds: (string | undefined)[]
) => {
  const customWindow = window as any
  if (typeof window === 'object' && typeof customWindow.as_beacon_search_default === 'function') {
    customWindow.as_beacon_search_default(name, searchProductIds)
  }
}
