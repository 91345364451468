import { ReactNode } from 'react'
import { SWRConfig } from 'swr'
import jsonp from 'jsonp'
import * as Sentry from '@sentry/nextjs'

import { isMatchedPartial, isString } from '~/common/utils/string'
import { booleanToNumberFilter, definedFilter } from '~/common/utils/array'
import { RequestError } from '~/common/app/requestError'
import { ProductSearchListResponse } from '~/common/api/product'
import SuggestItemsResponse from '~/model/suggest'

const API_ENDPOINT = process.env.WEB_URL as string

// For production
export const ISR_INTERVAL_SECONDS = 1800
export const ISR_FAIL_INTERVAL_SECONDS = 10
// For staging
// export const ISR_INTERVAL_SECONDS = 300

export interface Params {
  [index: string]: any
}

export const ApiPath = {
  LOGIN: '/api/login',
  USER: '/api/user',
  USER_NEW_ORDER: '/api/user/new_status_order',
  SHOP: '/api/shop/info/[key]',
  SHOP_LIST: '/api/shop/list',
  ACCEPTABLE_NEXT_TIME: '/api/acceptable/next/time',
  AREA: '/api/area/[key]',
  AREA_LIST: '/api/area/list',
  AREA_SWITCH: '/api/session/area/switch',
  SHOP_SWITCH: '/api/session/shop/switch',
  MODE_SWITCH: '/api/session/mode/switch',
  ADDRESS_LIST: '/api/user/address/list',
  COUPON_LIST: '/api/user/coupon/list',
  CATEGORY_LIST: '/api/category/list',
  NEWS_LIST: '/api/news/list',
  CAROUSEL: '/api/top_carousel',
  CP_POINT: '/api/cp/customer/point',
  CP_POINT_FAVORITE: '/api/cp/customer/favorite',
  PRODUCT: '/api/product/[id]',
  PRODUCT_LIST: '/api/product/list',
  PRODUCT_FAVORITE_LIST: '/api/product/favorite/list',
  PRODUCT_RANKING_LIST: '/api/product/ranking/list',
  PRODUCT_RECENT_PURCHASED_LIST: '/api/product/recent_purchased/list',
  PRODUCT_COLLECTION_LIST: '/api/product/collection/list',
  PRODUCT_COLLECTION: '/api/product/collection/[code]',
  PRODUCT_SALE_LIST: '/api/product/sales/list',
  PRODUCT_TOKUBAI_LIST: '/api/product/tokubai/list',
  PRODUCT_PICKUP_LIST: '/api/product/pickup/list',
  PRODUCT_RELATED_PV_LIST: '/api/product/related_pv/list',
  PRODUCT_POINT_LIST: '/api/product/point/list',
  OPINION_CREATE: '/api/opinion/create',
  REVIEW_LIST: '/api/product/review/[id]/list',
  REVIEW_CREATE: '/api/product/review/[id]/create',
  CART: '/api/cart',
  CART_OPERATION: '/api/cart/[operation]/[productClassId]',
  CART_BULK_ADD: '/api/cart/bulk_add',
  FAVORITE_OPERATION: '/api/favorite/[operation]/[id]',
  FAVORITE_SORT_OPERATION: '/api/favorite/sort'
} as const

export type ApiPath = (typeof ApiPath)[keyof typeof ApiPath]

export const ApiSearchPath = {
  SEARCH: '/search.json',
  SUGGEST: '/auto_complete.json'
} as const

export type ApiSearchPath = (typeof ApiSearchPath)[keyof typeof ApiSearchPath]

export const makeParamsQueryStr = (params: Params) => {

  const filteredParam = booleanToNumberFilter(definedFilter(params))
  const searchParams = new URLSearchParams()
  for (const key in filteredParam) {
    if (Array.isArray(filteredParam[key])) {
      filteredParam[key].forEach((value: []) => {
        searchParams.append(`${key}[]`, String(value))
      })
    } else {
      searchParams.set(key, String(filteredParam[key]))
    }
  }
  const query = searchParams.toString()

  return query ? '?' + query : ''
}

const replacePath = (path: string, params: Params) => {
  if (!params) {
    return { path, params }
  }

  let replacedPath: string = path
  let filteredParams: Params = {}
  for (const key in params) {
    const replaceKey = '[' + key + ']'
    if (isMatchedPartial(replacedPath, replaceKey)) {
      replacedPath = replacedPath.replace(replaceKey, params[key])
    } else {
      filteredParams[key] = params[key]
    }
  }

  return { path: replacedPath, params: filteredParams }
}

const paramsToStr = (path: ApiPath, params: Params): string => {
  const { path: replacedPath, params: filteredParams } = replacePath(path, params)

  if (Object.keys(filteredParams).length == 0) {
    return replacedPath
  }

  return replacedPath + makeParamsQueryStr(filteredParams)
}

const apiGetFetcher = (endpoint: string, path: ApiPath | any[]): Promise<any> => {
  const pathInput = isString(path) ? path : paramsToStr(path[0], path[1])
  return fetch(endpoint + pathInput).then((res) => res.json())
}

const apiDefaultFetcher = (path: ApiPath | any[]): Promise<any> => {
  return apiGetFetcher(API_ENDPOINT, path)
}

/* -- search api -- */
export const apiSearchFetcher = (
  path: ApiPath | any[]
): Promise<ProductSearchListResponse | SuggestItemsResponse> => {
  return apiGetFetcher(process.env.PRODUCT_SEARCH_API_ENDPOINT as string, path)
}

export const apiJsonpSearchFetcher = (path: ApiPath | any[]) => {
  const pathInput = isString(path) ? path : paramsToStr(path[0], path[1])
  return new Promise<any>((resolve, reject) => {
    jsonp(
      (process.env.PRODUCT_SEARCH_API_ENDPOINT as string) + pathInput,
      { param: 'callback' },
      (error, data) => {
        if (error) {
          reject(error)
        } else {
          resolve(data)
        }
      }
    )
  })
}
/* -- search api -- */

const apiMutationFetcher = async (
  path: string,
  { arg }: { arg: Params },
  method: 'PUT' | 'POST'
): Promise<any> => {
  const response = await fetch(API_ENDPOINT + path, {
    method: method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(arg)
  })
  if (!response.ok) {
    throw response
  }

  return response.json()
}

export const apiPostFetcher = async (path: ApiPath, { arg }: { arg: Params }): Promise<any> => {
  const { path: filteredPath, params: filteredArg } = replacePath(path, arg)
  return await apiMutationFetcher(filteredPath, { arg: filteredArg }, 'POST')
}

export const apiPutFetcher = async (path: ApiPath, { arg }: { arg: Params }): Promise<any> => {
  return await apiMutationFetcher(paramsToStr(path, arg), { arg: {} }, 'PUT')
}

export const fetchApi = async <T extends any>(
  path: ApiPath,
  params: Params = {},
  options: RequestInit = {}
): Promise<{ data: T | null; error: Error | null }> => {
  try {
    const response = await fetch(process.env.SSG_API_URL + paramsToStr(path, params), {
      ...options,
      headers: {
        Authorization: process.env.BASIC_AUTH_PASS as string,
        ...options.headers
      }
    })
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    const json = await response.json()
    return { data: json as T, error: null }
  } catch (err) {
    const error = err as Error
    Sentry.captureException(error)
    return { data: null, error }
  }
}

export const fetchApiList = async <T extends any>(orders: Promise<any>[]): Promise<T[]> => {
  return await Promise.all(orders)
}

export const postApi = async <T extends any>(
  path: ApiPath,
  params: Params = {}
): Promise<{ data: T | null; error: Error | null }> => {
  try {
    const response = await fetch(process.env.SSG_API_URL + path, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    })
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    const json = await response.json()
    return { data: json as T, error: null }
  } catch (err) {
    const error = err as Error
    Sentry.captureException(error)
    return { data: null, error }
  }
}

export const DefaultSWRConfig = ({
  children,
  fallback
}: {
  children: ReactNode
  fallback: any
}) => {
  return (
    <SWRConfig
      value={{
        fetcher: apiDefaultFetcher,
        fallback,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        onError: (error, key) => {
          if (
            ![
              RequestError.UNAUTHORIZED,
              RequestError.NOT_FOUND,
              RequestError.HAS_DIFFERENT_AREA_CART,
              RequestError.HAS_ANOTHER_SHOP_PICKUP_CART,
              RequestError.HAS_ANOTHER_USER_AREA
            ].includes(error.status)
          ) {
            Sentry.captureException(error)
          }
        }
      }}
    >
      {children}
    </SWRConfig>
  )
}
