import useSWR from 'swr'

import Coupon from '~/model/coupon'

import { ApiPath } from '~/common/api/base'
import { usePathKey } from '~/common/app/path'
import { useShowModal } from '~/common/app/store/modal'
import { definedFilter } from '~/common/utils/array'
import { Modal } from '~/components/app/modal/AppModal'
import { CouponModalProps } from '~/components/app/modal/CouponModal'

export interface CouponListResponse {
  coupons: Coupon[]
  purchase_product_ids?: number[]
  has_other_shop_coupon?: boolean
}

export const useCouponListApi = (withUsedInvalid?: boolean, orderByPointRate?: boolean, areaKey?: string) => {
  const pathKey = usePathKey()
  const key = areaKey || pathKey

  return useSWR<CouponListResponse>([
    ApiPath.COUPON_LIST,
    definedFilter({
      key,
      with_used_invalid: withUsedInvalid,
      order_by_point_rate: orderByPointRate
    })
  ])
}

// エリアの有効なクーポンの所持枚数を取得
export const useEnabledAreaCouponsOwnedCount = () => {
  const { data } = useCouponListApi()

  return data && data.coupons ? data.coupons.length : 0
}

export const useCouponApi = (id?: number) => {
  const { data: couponsData } = useCouponListApi()

  const coupon = id
    ? couponsData?.coupons.find((coupon) => coupon.id === id) || null
    : null

  return coupon
}

export const useShowCouponModal = () => {
  const showModal = useShowModal()

  const showCouponModal = (coupon: Coupon) => {
    showModal({
      type: Modal.COUPON,
      childProps: {
        coupon: coupon
      } as CouponModalProps
    })
  }

  return showCouponModal
}