import React, { AnchorHTMLAttributes, MutableRefObject } from 'react'
import { ClassProps } from '~/common/utils/props'
import Link from 'next/link'

interface Props extends ClassProps, AnchorHTMLAttributes<HTMLAnchorElement> {
  link: string
  anchorRef?: MutableRefObject<HTMLAnchorElement>
  // TODO: temporally for markt_web page
  noInternal?: boolean
}

const InternalLink = ({ children, link, className, anchorRef, noInternal, ...props }: Props) => {
  const Tag = noInternal ? `a` : Link
  // TODO: Show Page Loading
  return (
    <Tag {...props} className={className} href={link} ref={anchorRef}>
      {children}
    </Tag>
  )
}

export default InternalLink
