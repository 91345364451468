import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import { myPageCouponPath } from '~/common/app/path'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import InternalLink from '~/components/utils/link/InternalLink'

interface Props {
  quantity: number
  inversion?: boolean
}

const CouponIcon = ({ quantity, inversion = false }: Props) => {
  const CartIconColor = inversion ? Color.INVERSION : Color.ASSERTION
  return (
    <InternalLink link={myPageCouponPath()} className={'p-icon-cart'}>
      <Icon color={CartIconColor} size={24} type={IconType.COUPON} />
      <span
        className={
          'p-icon-cart__quantity' + addClass('p-icon-cart__quantity--inversion', inversion)
        }
      >
        {quantity}
      </span>
    </InternalLink>
  )
}
export default CouponIcon