import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import css from 'styled-jsx/css'

import { useLoggedIn, useUserApi, useUserArea } from '~/common/api/user'
import { useArea } from '~/common/api/area'
import { useShop } from '~/common/api/shop'
import { useEnabledAreaCouponsOwnedCount } from '~/common/api/coupon'

import { useShowNavi } from '~/common/app/store/navi'
import { useShowModal } from '~/common/app/store/modal'
import { useOffsetTop } from '~/common/utils/scroll'
import { useCartTotalQuantity } from '~/common/app/cart'
import { useRouteChanged } from '~/common/utils/route'
import { Color } from '~/common/utils/color'
import {
  areaTopPath,
  cartPath,
  entryPath,
  guidePath,
  helpAboutPath,
  logoutPath,
  myPageCouponPath,
  myPageFavoritePath,
  myPageHistoryPath,
  myPageLoginPath,
  myPagePath,
  myPagePurchasedProductPath,
  productListPath,
  topPath,
  usePathKey
} from '~/common/app/path'
import { Layout } from '~/components/app/layout/AppLayout'
import HiddenPc from '~/components/utils/hidden/HiddenPc'
import HeaderWideText from '~/components/atoms/header/HeaderWideText'
import HeaderWrap from '~/components/layouts/header/HeaderWrap'
import HeaderFrame from '~/components/layouts/header/HeaderFrame'
import HeaderList, { HeaderLineType } from '~/components/layouts/header/HeaderList'
import LogoImage from '~/components/atoms/header/LogoImage'
import HiddenSp from '~/components/utils/hidden/HiddenSp'
import Text from '~/components/atoms/text/Text'
import LinkText from '~/components/atoms/text/LinkText'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import InternalLink from '~/components/utils/link/InternalLink'
import { useIsSpDevice } from '~/components/utils/window'
import IconTextColumn from '~/components/atoms/icon/IconTextColumn'
import CartIcon from '~/components/molecules/icon/CartIcon'
import SearchTextBox from '~/components/molecules/textbox/SearchTextBox'
import HeaderSupport from '~/components/layouts/header/HeaderSupport'
import Segments from '~/components/layouts/area/Segments'
import { Modal } from '~/components/app/modal/AppModal'
import AppSuggest from '~/components/app/layout/header/AppSuggest'
import AppCartHeader from '../../module/cart/AppCartHeader'
import CouponIcon from '~/components/molecules/icon/CouponIcon'

interface Props {
  layout: Layout
  isShop: boolean
}

const HeaderButtons = ({ isShop, loggedIn }: { isShop: boolean; loggedIn: boolean }) => {
  const role = isShop ? ButtonRole.LOW_PRIORITY : ButtonRole.INVERSION
  const iconColor = isShop ? undefined : Color.SUPPORT_SUB

  return (
    <>
      {loggedIn ? (
        <>
          <Button
            size={ButtonSize.WIDE_MEDIUM}
            px={14}
            role={role}
            link={myPageHistoryPath()}
            iconType={IconType.RECEIPT}
            iconSize={16}
            iconFill={iconColor}
          >
            購入履歴
          </Button>
          <Button
            size={ButtonSize.WIDE_MEDIUM}
            px={14}
            role={role}
            link={myPagePath()}
            iconType={IconType.USER}
            iconSize={16}
            iconFill={iconColor}
            noInternal
          >
            マイページ
          </Button>
        </>
      ) : (
        <>
          <Button
            size={ButtonSize.WIDE_MEDIUM}
            px={14}
            role={role}
            link={entryPath()}
            iconType={IconType.ENTRY}
            iconSize={16}
            iconFill={iconColor}
            noInternal
          >
            会員登録
          </Button>
          <Button
            size={ButtonSize.WIDE_MEDIUM}
            px={14}
            role={role}
            link={myPageLoginPath()}
            iconType={IconType.KEY}
            iconSize={16}
            iconFill={iconColor}
          >
            ログイン
          </Button>
        </>
      )}
    </>
  )
}

const HeaderUpper = ({ isShop, layout }: Props) => {
  const { data: user } = useUserApi()
  const area = useArea()
  const loggedIn = useLoggedIn()
  const showModal = useShowModal()
  const showNavi = useShowNavi()
  const textColor = isShop ? Color.PRIMARY : Color.INVERSION
  const couponCount = useEnabledAreaCouponsOwnedCount()
  const cartTotalQuantity = useCartTotalQuantity()

  return (
    <HeaderFrame
      color={isShop || layout == Layout.LITE ? Color.INVERSION : Color.SUPPORT_SUB}
      inversion={isShop}
      border={isShop}
      tag={'header'}
    >
      <HeaderList pcGap={16}>
        <LogoImage
          src='/assets/images/markt_logo.png'
          alt='マルクト'
          link={
            area
              ? areaTopPath(area)
              : user?.access_area_key
              ? '/' + user.access_area_key
              : topPath()
          }
          type={isShop ? 'small' : 'low'}
          tag='h1'
        ></LogoImage>
        <HiddenSp>
          <HeaderList pcGap={24} positioning={!isShop}>
            <Text px={10} color={textColor}>
              当日配達！カート合計4000円以上で送料無
              <br />
              料！まとめて注文！まとめて配送！
            </Text>
            <div>
              <Text px={12} color={textColor}>
                ご利用エリア&nbsp;
                <LinkText
                  px={12}
                  color={textColor}
                  underline
                  onClick={() => showModal({ type: Modal.AREA_SETTING })}
                >
                  〒{user?.postal_code ?? '未設定'}
                </LinkText>
              </Text>
            </div>
          </HeaderList>
        </HiddenSp>
      </HeaderList>
      {layout != Layout.LITE && (
        <>
          <HiddenSp>
            <HeaderList pcGap={16} positioning={!isShop}>
              <HeaderList
                pcGap={16}
                line={!isShop}
                lineColor={isShop ? HeaderLineType.SECONDARY : HeaderLineType.INVERSION}
              >
                <LinkText px={10} link={helpAboutPath()} color={textColor}>
                  マルクトとは
                </LinkText>
                <LinkText link={guidePath()} px={10} color={textColor} noInternal>
                  よくある質問
                </LinkText>
                {loggedIn && (
                  <LinkText link={logoutPath()} px={10} color={textColor} noInternal>
                    ログアウト
                  </LinkText>
                )}
              </HeaderList>
              <HeaderList>
                <HeaderButtons isShop={isShop} loggedIn={loggedIn} />
              </HeaderList>
            </HeaderList>
          </HiddenSp>
          <HiddenPc>
            <HeaderList spGap={4}>
              {couponCount > 0 && (
                <IconTextColumn>
                  <CouponIcon quantity={couponCount} inversion={!isShop} />
                  <Text px={10} color={textColor} bold>
                    クーポン
                  </Text>
                </IconTextColumn>
              )}
              <InternalLink link={cartPath()}>
                <IconTextColumn>
                  <CartIcon quantity={cartTotalQuantity} inversion={!isShop} />
                  <Text px={10} color={textColor} bold>
                    カート
                  </Text>
                </IconTextColumn>
              </InternalLink>
              <IconTextColumn onClick={showNavi}>
                <Icon type={IconType.MENU} size={20} color={textColor} />
                <Text px={10} color={textColor} bold>
                  メニュー
                </Text>
              </IconTextColumn>
            </HeaderList>
          </HiddenPc>
        </>
      )}
    </HeaderFrame>
  )
}

const AppSearchTextBox = () => {
  const router = useRouter()
  const { query } = router
  const key = usePathKey()
  const userArea = useUserArea()
  const shop = useShop()
  const [searchWord, setSearchWord] = useState<string>('')
  const isSp = useIsSpDevice()
  const [open, setOpen] = useState<boolean>(false)
  const checkIgnorePath = productListPath()
  const SUBMIT_ENTER_CODE = 13

  useEffect(() => {
    if (query.name !== undefined) {
      setSearchWord(query?.name as string)
      setOpen(false)
    } else {
      if (isSp) {
        setSearchWord('')
        setOpen(false)
      }
    }
  }, [query.name])

  useRouteChanged(() => {
    setSearchWord('')
  }, checkIgnorePath)

  return (
    <>
      <SearchTextBox
        value={searchWord}
        onClick={() => {
          router.push(productListPath({ key: key ?? userArea?.area_key, word: searchWord }))
          setOpen(false)
        }}
        onKeyDown={(e) => {
          if (e.keyCode === SUBMIT_ENTER_CODE) {
            router.push(productListPath({ key: key ?? userArea?.area_key, word: searchWord }))
            setOpen(false)
            e.currentTarget.blur()
          }
        }}
        onChange={(e) => {
          setSearchWord(e.target.value)
          if (!isSp) {
            setOpen(true)
          }
        }}
        onFocus={(e) => {
          if (isSp) {
            setOpen(true)
          }
        }}
        placeholder={shop ? shop.baseInfo.name + 'から検索' : '全店舗から検索'}
      />
      {(isSp ? open : searchWord && open) && (
        <AppSuggest keyword={searchWord} open={open} setOpen={setOpen} />
      )}
    </>
  )
}

const HeaderLower = ({ isFollow }: { isFollow: boolean }) => {
  const showModal = useShowModal()
  const { className: followClass, styles: followStyle } = css.resolve`
    left: 0;
    top: 0;
    width: 100vw;
    position: fixed;
    z-index: 10;
  `

  return (
    <div className={isFollow ? followClass : ''}>
      <HiddenSp>
        <HeaderSupport radius>
          <HeaderList pcGap={24}>
            <AppSearchTextBox />
            <HeaderList pcGap={16}>
              <Button
                size={ButtonSize.WIDE_MEDIUM}
                role={ButtonRole.HIGH_PRIORITY_LIGHT_GREEN}
                link={myPageFavoritePath()}
                iconType={IconType.STAR}
                px={12}
                iconSize={16}
              >
                お気に入り
              </Button>
              <Button
                size={ButtonSize.WIDE_MEDIUM}
                role={ButtonRole.HIGH_PRIORITY_LIGHT_GREEN}
                link={myPagePurchasedProductPath()}
                iconType={IconType.BAG}
                px={12}
                iconSize={16}
              >
                最近購入した商品
              </Button>
            </HeaderList>
          </HeaderList>
          <AppCartHeader />
        </HeaderSupport>
      </HiddenSp>
      <HiddenPc>
        <HeaderSupport radius>
          <Segments spGap={12} fitWidth>
            <AppSearchTextBox />
            <Button
              role={ButtonRole.HIGH_PRIORITY_LIGHT_GREEN}
              size={ButtonSize.WIDE_MEDIUM}
              px={10}
              link={myPageFavoritePath()}
            >
              お気に入り
            </Button>
            <Button
              role={ButtonRole.HIGH_PRIORITY_LIGHT_GREEN}
              size={ButtonSize.WIDE_MEDIUM}
              px={10}
              link={myPagePurchasedProductPath()}
            >
              購入した商品
            </Button>
          </Segments>
        </HeaderSupport>
      </HiddenPc>
      {followStyle}
    </div>
  )
}

const AppHeader = ({ layout, isShop }: Props) => {
  const FOLLOW_VIEWPORT_TOP = -80
  const FOLLOW_DURATION = 10

  const ref = useRef(null)
  const [isFollow, setIsFollow] = useState<boolean>(false)
  const { viewportTop } = useOffsetTop(ref)

  useEffect(() => {
    if (viewportTop == undefined) {
      return
    }
    if (isFollow) {
      viewportTop > FOLLOW_VIEWPORT_TOP + FOLLOW_DURATION && setIsFollow(false)
    } else {
      viewportTop < FOLLOW_VIEWPORT_TOP && setIsFollow(true)
    }
  }, [viewportTop])

  return (
    <div ref={ref}>
      <HiddenPc>
        <HeaderWideText inversion={isShop}>
          当日配達！カート合計4000円以上で送料無料！まとめて注文！まとめて配送！
        </HeaderWideText>
      </HiddenPc>
      {isShop ? (
        <>
          <HeaderUpper layout={layout} isShop={isShop} />
          <HeaderLower isFollow={isFollow} />
        </>
      ) : (
        <HeaderWrap>
          <HeaderUpper layout={layout} isShop={isShop} />
          <HeaderLower isFollow={isFollow} />
        </HeaderWrap>
      )}
    </div>
  )
}

export default AppHeader
