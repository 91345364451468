import React from 'react'

import { CouponTagContents } from '~/common/app/coupon'
import BaseProductCard, { ProductCardType } from './BaseProductCard'

interface Props {
  image: string
  name: string
  price: number
  tax: number
  tokubai?: boolean
  saleText?: string
  seasonal?: boolean
  point?: string
  shopName: string
  disableAm?: boolean
  disablePm?: boolean
  deliveryText?: string
  link?: string
  favorite: boolean
  soldout?: boolean
  outOfSale?: boolean
  cartQuantity?: number
  selected?: boolean
  noSelectableText?: string
  couponTagContents? : CouponTagContents
  onClick?(): void
  onClickChangeQuantity(quantity: number): void
  onClickCartIn(): void
  onClickFavorite(): void
  onClickSelect?(): void
}

const LargeProductCard = ({
  image,
  name,
  price,
  tax,
  tokubai = false,
  saleText = undefined,
  seasonal = false,
  point,
  shopName,
  disableAm = false,
  disablePm = false,
  deliveryText,
  link,
  favorite = false,
  soldout = false,
  outOfSale = false,
  cartQuantity = 0,
  selected = false,
  noSelectableText,
  couponTagContents,
  onClick,
  onClickChangeQuantity,
  onClickCartIn,
  onClickFavorite,
  onClickSelect = undefined
}: Props) => {
  return (
    <BaseProductCard
      type={ProductCardType.LARGE}
      image={image}
      name={name}
      price={price}
      tax={tax}
      tokubai={tokubai}
      soldout={soldout}
      outOfSale={outOfSale}
      saleText={saleText}
      seasonal={seasonal}
      point={point}
      shopName={shopName}
      disableAm={disableAm}
      disablePm={disablePm}
      deliveryText={deliveryText}
      link={link}
      favorite={favorite}
      cartQuantity={cartQuantity}
      selected={selected}
      couponTagContents={couponTagContents}
      noSelectableText={noSelectableText}
      onClick={onClick}
      onClickChangeQuantity={onClickChangeQuantity}
      onClickCartIn={onClickCartIn}
      onClickFavorite={onClickFavorite}
      onClickSelect={onClickSelect}
    />
  )
}

export default LargeProductCard

