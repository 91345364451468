import { useEffect } from 'react'
import { useSwitchShop, useUserShop } from '~/common/api/user'
import { ShopDetail } from '~/model/shop'

export const SHOP_INFO_TAG_ID = 'shop-info'
export const SHOP_HEADER_TAG_ID = 'shop-header'

export const convertMedicineRiskIdsToStr = (risk: string) => {
  const riskIds = risk.split(',')

  return riskIds.map((riskId) => {
    switch (riskId) {
      case '1':
        return '第1類医薬品'
      case '21':
        return '指定第2類医薬品'
      case '2':
        return '第2類医薬品'
      case '3':
        return '第3類医薬品'
    }
  }).join(' / ')
}

export const useSwitchShopWithShop = (shop?: ShopDetail) => {
  const userShop = useUserShop()
  const switchShop = useSwitchShop()

  useEffect(() => {
    if (userShop !== undefined &&
      (
        (shop && !userShop) || (!shop && userShop) ||
        (shop && userShop && userShop?.shop_key != shop?.baseInfo.shop_key)
      )
    ) {
      switchShop(shop?.baseInfo.shop_key)
    }
  }, [userShop, shop])
}
