import { useGetLoading } from '~/common/app/store/loading'
import Loading from '~/components/atoms/loading/Loading'
import Background, { BackgroundType } from '~/components/layouts/background/Background'

const AppLoading = () => {
  const loading = useGetLoading()
  return (
    <Background type={BackgroundType.INVERSION} show={loading}>
      <Loading />
    </Background>
  )
}

export default AppLoading
