import React, { ReactNode, useState } from 'react'

import { convertToPrice } from '~/common/utils/price'
import { Color } from '~/common/utils/color'
import {
  CartProductCardProps,
  SpCartProductCard
} from '~/components/molecules/card/CartProductCard'
import Text from '~/components/atoms/text/Text'
import CartIcon from '~/components/molecules/icon/CartIcon'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import CardList from '../card/CardList'
import OpacityToggle from '~/components/utils/animation/OpacityToggle'
import FloatCover from '~/components/layouts/float/FloatCover'
import Background from '~/components/layouts/background/Background'
import SpFooter from '~/components/layouts/footer/SpFooter'

interface Props {
  total?: number
  message?: ReactNode
  cartTotalQuantity: number
  cartLink: string
  items: CartProductCardProps[]
  deliveryText: string
  modeLabel: string
  onDelete(productClassId: number): void
  onOpen(): void
  onClose(): void
}

const CartFooter = ({
  total,
  message,
  cartTotalQuantity,
  cartLink,
  items,
  deliveryText,
  modeLabel,
  onDelete,
  onOpen,
  onClose
}: Props) => {
  const [showCartInfo, setShowCartInfo] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [deletableProductClassId, setDeletableProductClassId] = useState<number>()

  const openCartInfo = () => {
    setShowCartInfo(true)
    onOpen && onOpen()
  }
  const closeCartInfo = () => {
    setShowCartInfo(false)
    onClose && onClose()
  }
  const clickDeleteProduct = (productClassId: number) => {
    setDeletableProductClassId(productClassId)
    setShowDelete(true)
  }
  const clickDeleteProductMain = () => {
    if (deletableProductClassId) {
      onDelete(deletableProductClassId)
    }
    clickCloseDelete()
  }
  const clickCloseDelete = () => {
    setShowDelete(false)
  }

  const CartFooterMain = () => {
    return (
      <>
        <div className='p-cart-sp__main__left'>
          <div>
            <span className='p-cart-sp__main__left__quantity'>
              <CartIcon quantity={cartTotalQuantity} />
            </span>
            {total != undefined &&
              <span className='p-cart-sp__main__left__price'>
                <Text px={10} bold vivid en>
                  ¥
                </Text>
                <Text px={20} color={Color.EMPHASIS} bold vivid en>
                  {convertToPrice(total)}
                </Text>
                <Text px={10}>(税込)</Text>
              </span>
            }
          </div>
          {message != undefined &&
            <div className='p-cart-pc__right__text'>
              {message}
            </div>
          }
        </div>
        <div className='p-cart-sp__main__right'>
          {deliveryText && <p className='p-cart-sp__main__period'>{deliveryText}</p>}

          <Button
            size={ButtonSize.LARGE}
            role={ButtonRole.HIGH_PRIORITY_YELLOW}
            iconType={IconType.REGISTER}
            link={cartLink}
          >
            レジに進む
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <SpFooter onClick={openCartInfo} frame horizon>
        <CartFooterMain />
      </SpFooter>
      <Background onClick={closeCartInfo} show={showCartInfo} noCenter>
        <SpFooter>
          <button className='p-cart-sp__modal-close' onClick={closeCartInfo}>
            <Icon color={'inherit'} size={40} type={IconType.CLOSE_MODAL} />
          </button>
          <div className='p-cart-sp__main--back'>
            <p
              className='p-cart-sp__main__heading'>{`カートの商品（${modeLabel}）`}</p>
            <div className={'p-cart-sp__main'}>
              <CartFooterMain />
            </div>
          </div>
          <div className='p-cart-sp__item'>
            <CardList>
              {items.map((item: CartProductCardProps, index: number) => (
                <SpCartProductCard key={index} {...item} onClickDelete={clickDeleteProduct} />
              ))}
            </CardList>
          </div>
          <OpacityToggle show={showDelete}>
            <FloatCover>
              <div className='p-cart-product-delete'>
                <Text px={16} tag={'p'} bold>
                  この商品を削除しますか？
                </Text>
                <div className='p-cart-product-delete__wrap'>
                  <div className='p-cart-product-delete__button'>
                    <Button
                      size={ButtonSize.LARGE}
                      role={ButtonRole.HIGH_PRIORITY_YELLOW}
                      onClick={clickDeleteProductMain}
                      expanded
                    >
                      削除する
                    </Button>
                  </div>
                  <div className='p-cart-product-delete__button'>
                    <Button
                      size={ButtonSize.LARGE}
                      role={ButtonRole.LOW_PRIORITY}
                      onClick={clickCloseDelete}
                      expanded
                    >
                      削除しない
                    </Button>
                  </div>
                </div>
              </div>
            </FloatCover>
          </OpacityToggle>
        </SpFooter>
      </Background>
    </>
  )
}
export default CartFooter
