import { ReactNode } from 'react'
import { Color } from '~/common/utils/color'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import { IconType } from '~/components/atoms/icon/Icon'
import LinkText from '~/components/atoms/text/LinkText'
import Text from '~/components/atoms/text/Text'
import Segments from '~/components/layouts/area/Segments'

interface Props {
  title?: string
  textLink?: string
  items?: ReactNode[]
  buttonLink?: string
  buttonText?: string
  iconType?: IconType
}

const FirstTimeInform = ({ title, textLink, items, buttonLink, buttonText, iconType }: Props) => {
  return (
    <div className='p-first-inform'>
      <Segments spBetween pcBetween>
        {title && (
          <Text px={12} tag='p' bold>
            {title}
          </Text>
        )}
        {textLink && (
          <LinkText link={textLink} arrow={Color.LINK} external>
            <Text px={10} color={Color.LINK}>
              もっと見る
            </Text>
          </LinkText>
        )}
      </Segments>
      {items && (
        <ul className='p-first-inform__list'>
          {items.map((item, index) => {
            return <li key={index}>{item}</li>
          })}
        </ul>
      )}
      {buttonLink && (
        <div className='p-first-inform__button'>
          <Button
            iconSize={16}
            iconFill={Color.SUPPORT_SUB}
            px={14}
            role={ButtonRole.INVERSION}
            size={ButtonSize.LARGE}
            iconType={iconType}
            link={buttonLink}
            expanded
            noInternal
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  )
}
export default FirstTimeInform
