import React from 'react'
import { useLoggedIn } from '~/common/api/user'

import ModalFrame from '~/components/layouts/modal/ModalFrame'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import Section from '~/components/layouts/section/Section'
import PostalCodeForm from '~/components/app/form/postalCode/PostalCodeForm'
import AreaSettingForm from '~/components/app/form/area/AreaSettingForm'
import LoginForm from '~/components/app/form/login/LoginForm'

interface Props {
  onClickClose(): void
}

const AreaSettingModal = ({ onClickClose }: Props) => {
  const isLogin = useLoggedIn()
  return (
    <ModalFrame onClickClose={onClickClose} scrollable>
      <BlockTitle verticalBar tag='h2'>
        ご利用エリアを設定
      </BlockTitle>
      <Segments spGap={24} pcGap={32} spColumn={SegmentsType.COLUMN}>
        <Section>
          <PostalCodeForm complete={onClickClose} />
        </Section>
        <Section>
          {isLogin ?
            <AreaSettingForm /> :
            <LoginForm complete={onClickClose} />
          }
        </Section>
      </Segments>
    </ModalFrame>
  )
}

export default AreaSettingModal
