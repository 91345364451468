import React from 'react'

import { FormWidgetError, formWidgetErrorMessages } from '~/common/form/error'
import ErrorText from '~/components/atoms/text/ErrorText'

interface Props {
  label: string
  errors: FormWidgetError[]
}

const FormWidgetErrorMessages = ({ label, errors }: Props) => {
  const messages: string[] = errors ? formWidgetErrorMessages(label, errors) : []
  return (
    <>
      {messages.map((message: string, index) =>
        <ErrorText key={index}>{message}</ErrorText>
      )}
    </>
  )
}

export default FormWidgetErrorMessages
