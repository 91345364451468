import { ChildProps } from '~/common/utils/props'
import { addClass } from '~/common/utils/html'
import { STYLE_MEDIA_PC } from '~/common/app/style'

interface Props extends ChildProps {
  tag?: 'dl' | 'div'
  center?: boolean
  verticalCenter?: boolean
  spMargin?: 8 | 10 | 12 | 16 | 24
  pcMargin?: 8 | 10 | 12 | 16 | 24
}

const FormRow = ({
  children,
  tag = 'div',
  center = false,
  verticalCenter = false,
  spMargin = 10,
  pcMargin
}: Props) => {
  const Tag = tag
  return (
    <>
      <Tag className={'l-form-row' + addClass('l-form-row--center', center)
        + addClass('l-form-row--vertical-center', verticalCenter)}>{children}</Tag>
      <style jsx>{`
        .l-form-row:not(:last-child) {
          margin-bottom: ${spMargin}px;
        }

        @media ${STYLE_MEDIA_PC} {
          .l-form-row:not(:last-child) {
            margin-bottom: ${pcMargin ?? spMargin}px;
          }
        }
      `}</style>
    </>
  )
}

export default FormRow
