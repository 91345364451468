export const getCookieValue = (key: string) => {
  if (typeof window === 'object') {
    return document.cookie.split("; ")
      .find((row) => row.startsWith(key + "="))
      ?.split("=")[1]
  }
  return undefined
}

type SameSiteOption = 'None' | 'Lax' | 'Strict'

export const setCookieValue = (key: string, value: string, days: number, sameSite: SameSiteOption = 'None'): void => {
  if (typeof window === 'object') {
    const ONE_DAY_IN_MS = 86400000
    const expires: string = new Date(Date.now() + days * ONE_DAY_IN_MS).toUTCString()
    document.cookie = `${key}=${value}; expires=${expires}; sameSite=${sameSite}`
  }
}