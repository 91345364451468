import css from 'styled-jsx/css'
import Product from '~/model/product'
import { productDetailPath } from '~/common/app/path'
import { useProductApi } from '~/common/api/product'

import ModalFrame, { ModalFrameSize } from '~/components/layouts/modal/ModalFrame'
import LinkText from '~/components/atoms/text/LinkText'
import AppProductDetail from '~/components/app/module/product/AppProductDetail'
import ProductRelatedPvList from '~/components/app/module/product/ProductRelatedPvList'
import Loading from '~/components/atoms/loading/Loading'

export interface ModalProductProps {
  product: Product
}

interface Props extends ModalProductProps {
  onClickClose(): void
}

const Content = ({ product }: { product: Product }) => {
  const { className: linkClass, styles: linkStyles } = css.resolve`
    text-align: right;
  `

  return (
    <>
      <div className={linkClass}>
        <LinkText link={productDetailPath(product)} underline external>
          レビューを書く/読む（商品詳細ページ）
        </LinkText>
      </div>
      {linkStyles}
      <ProductRelatedPvList product={product} inModal />
    </>
  )
}

const ProductModal = ({ product, onClickClose }: Props) => {
  const { data: fetchedProduct } = useProductApi(product.id)

  return (
    <ModalFrame onClickClose={onClickClose} scrollable size={ModalFrameSize.LOW}>
      {fetchedProduct ? (
        <AppProductDetail
          product={fetchedProduct}
          content={<Content product={fetchedProduct} />}
          isModal
        />
      ) : (
        <Loading />
      )}
    </ModalFrame>
  )
}

export default ProductModal
