import React from 'react'
import Icon, { IconProps } from '~/components/atoms/icon/Icon'
import { addClass } from '~/common/utils/html'

interface Props extends IconProps {
  onClick?(e: React.MouseEvent<HTMLElement>): void
  deactive?: boolean
}

const IconButton = ({ onClick, deactive, ...props }: Props) => {
  return (
    <button className={'c-icon-button' + addClass('c-icon-button--deactive', deactive)}
            onClick={onClick}>
      <Icon {...props} />
    </button>
  )
}
export default IconButton
