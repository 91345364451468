import { ParsedUrlQuery } from 'querystring';

export const sliceByNumber = <T extends any>(array: T[], number: number) => {
  const length = Math.ceil(array.length / number)
  return new Array(length).fill('').map((_, i) => array.slice(i * number, (i + 1) * number))
}

export const definedFilter = (obj: { [index: string]: any }): { [index: string]: any } => {
  let filtered: { [index: string]: any } = {}
  Object.keys(obj).filter((key: string) => {
    if (obj[key] != undefined) {
      filtered[key] = obj[key]
    }
  })
  return filtered
}

export const booleanToNumberFilter = (obj: { [index: string]: any }): { [index: string]: any } => {
  let filtered: { [index: string]: any } = {}
  Object.keys(obj).filter((key: string) => {
    if (typeof obj[key] === 'boolean') {
      filtered[key] = obj[key] ? 1 : 0
    } else {
      filtered[key] = obj[key]
    }
  })
  return filtered
}

export const isEmptyAssociatedArray = (obj: { [index: string]: any }) => {
  return !obj || !Object.keys(obj).length
}

export const groupBy = <K extends PropertyKey, V>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => K
) =>
  array.reduce((obj, cur, idx, src) => {
    const key = getKey(cur, idx, src)
    ;(obj[key] || (obj[key] = []))!.push(cur)
    return obj
  }, {} as Partial<Record<K, V[]>>)

export const extractMultiSearchParams = (query: ParsedUrlQuery, key: string) => {
  const mulchValue = query[`${key}[][]`]
  if (mulchValue) {
    return Array.isArray(mulchValue) ? mulchValue : [mulchValue]
  }

  const singleValue = query[`${key}[]`]
  if (singleValue) {
    return Array.isArray(singleValue) ? singleValue : [singleValue]
  }

  return []
}