import { addClass } from '~/common/utils/html'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  inversion?: boolean
}

const HeaderWideText = ({ children, inversion = false }: Props) => {
  return (
    <p className={'c-header-wide-text' + addClass('c-header-wide-text--inversion', inversion)}>
      {children}
    </p>
  )
}

export default HeaderWideText
