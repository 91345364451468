import { ChildProps } from '~/common/utils/props'

export const TagType = {
  NORMAL: 'normal',
  REQUIRE: 'require',
  DISABLE: 'disable',
  WIDE: 'wide',
  RIBBON: 'ribbon',
  FLOAT: 'float'
} as const
export type TagType = (typeof TagType)[keyof typeof TagType]

export const tagTypeClass = (type: TagType): string => {
  switch (type) {
    case TagType.NORMAL:
      return 'c-tag--type-normal'
    case TagType.REQUIRE:
      return 'c-tag--type-require'
    case TagType.DISABLE:
      return 'c-tag--type-disable'
    case TagType.WIDE:
      return 'c-tag--type-wide'
    case TagType.RIBBON:
      return 'c-tag--type-ribbon'
    case TagType.FLOAT:
      return 'c-tag--type-float'
  }
}

export const tagByType = (type: TagType) => {
  switch (type) {
    case TagType.WIDE:
    case TagType.FLOAT:
      return 'p'
    default:
      return 'span'
  }
}

interface Props extends ChildProps {
  tagType: TagType
  color?: string
}

const Tag = ({ children, tagType, color }: Props) => {
  const Tag = tagByType(tagType)
  return (
    <>
      <Tag className={tagTypeClass(tagType)}>{children}</Tag>
      <style jsx>{`
        .c-tag--type-normal {
          background-color: ${color};
        }
      `}</style>
    </>
  )
}

export default Tag
