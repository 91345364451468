import { ReactNode, useState } from 'react'
import { addClass } from '~/common/utils/html'
import ImageFitContain from '~/components/utils/image/ImageFitContain'
import InternalLink from '~/components/utils/link/InternalLink'
import SlideToggle from '~/components/utils/animation/SlideToggle'

interface Props {
  children?: ReactNode
  src: string
  alt: string
  link: string
  text: string
}

const NavRow = ({ children, src, alt, link, text }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <li className='p-nav-row'>
      <div className={'p-nav-row__in'}>
        <ImageFitContain src={src} alt={alt} />
        <InternalLink link={link} className='p-nav-row__link'>
          {text}
        </InternalLink>
        {children &&
          <span className={'p-nav-row__toggle' + addClass('is-open', open)}
                onClick={() => setOpen(open => !open)} />
        }
      </div>
      {children &&
        <SlideToggle show={open}>
          {children}
        </SlideToggle>
      }
    </li>
  )
}

export default NavRow
